import {
    createContext,
    Dispatch,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react'
import localforage from 'localforage'
import {
    cartReducer,
    CartReducerAction,
    CartState,
    initialCartState,
} from 'reducers/cartReducer'

import { PriceType } from '@interfaces/order'
import { Product } from '@interfaces/restaurant'

export type CartProps = {
    cartState: CartState
    dispatch: Dispatch<CartReducerAction>
    open: boolean
    loading: boolean
    setOpen: (isOpen: boolean) => void
    add: (product: Product) => void
    remove: (product: Product) => void
    clear: () => void
    changeQty: (product: Product, quantity: number) => void
    getQty: (product: Product) => number
    getPrice: (product: Product) => number
    getTotalPrice: (product: Product, type?: PriceType) => number
    getItemCount: () => number
}

const CartContext = createContext<CartProps>({
    cartState: {
        products: [],
        total: 0,
    },
    dispatch: null,
    open: false,
    setOpen: () => {},
    loading: true,
    add: () => {},
    remove: () => {},
    clear: () => {},
    changeQty: () => {},

    getQty: () => 0,
    getPrice: () => 0,
    getTotalPrice: () => 0,
    getItemCount: () => 0,
})

const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialCartState)
    const [cartOpen, setCartOpen] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true)

                const getCart = await localforage.getItem('cart')
                const localState: CartState = JSON.parse(JSON.stringify(getCart))

                if (!localState) return
                addAll(localState.products)
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        }

        fetchProducts()
    }, [])

    const getItemCount = (): number => {
        return state.products.reduce(
            (total, product) =>
                total +
                product.product_variations.reduce(
                    (x, variation) => x + variation.quantity,
                    0,
                ),
            0,
        )
    }

    const getQty = (product: Product): number => {
        return product.product_variations.reduce(
            (prev, curr) => prev + curr.quantity,
            0,
        )
    }

    const getPrice = (product: Product, type: PriceType = 'totalPrice'): number => {
        return product.product_variations.reduce(
            (prev, variation) => prev + variation[type],
            0,
        )
    }

    const getTotalPrice = (
        product: Product,
        type: PriceType = 'totalPrice',
    ): number => {
        return getQty(product) * getPrice(product, type)
    }

    const addAll = (products: Product[]) => {
        dispatch({
            type: 'ADD_ALL',
            payload: { products },
        })
    }

    const add = (product: Product) => {
        dispatch({
            type: 'ADD',
            payload: { product },
        })
    }

    const remove = (product: Product) => {
        dispatch({
            type: 'REMOVE',
            payload: { product },
        })
    }

    const clear = () => {
        dispatch({
            type: 'CLEAR',
        })
    }

    const changeQty = (product: Product, quantity: number) => {
        dispatch({
            type: 'CHANGE_QTY',
            payload: { product, quantity },
        })
    }

    const setOpen = (isOpen: boolean) => {
        setCartOpen(isOpen)
    }

    return (
        <CartContext.Provider
            value={{
                cartState: state,
                dispatch,
                loading,
                open: cartOpen,
                setOpen,
                getTotalPrice,
                add,
                // addAll,
                remove,
                clear,

                getQty,
                getPrice,
                changeQty,
                getItemCount,
            }}
        >
            {children}
        </CartContext.Provider>
    )
}

export default CartProvider

export const useCart = () => useContext(CartContext)
