import {
    collection,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    QueryDocumentSnapshot,
    startAfter,
    updateDoc,
    where,
} from 'firebase/firestore'

import { SaleItem } from '@interfaces/admin'
import { inactiveOrderStatus, Order, OrderStatus } from '@interfaces/order'
import { Role, Stats, User } from '@interfaces/user'
import { clientDb } from '@utils/clientFirebase'
import { CONFIG } from '@utils/config'

const ORDERS_PATH = `restaurants/${CONFIG.RESTAURANT_ID}/orders`

export const fetchSalesMonth = async (
    month: number,
    year: number,
): Promise<SaleItem> => {
    try {
        const snapshot = await getDoc(
            doc(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/sales/${year}`),
        )

        const item = snapshot.data()[month] as SaleItem
        return item
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchUsers = async (docLimit: number): Promise<User[]> => {
    try {
        const snapshot = await getDocs(
            query(
                collection(clientDb, 'users'),
                where('restaurant_ids', 'array-contains', CONFIG.RESTAURANT_ID),
                orderBy('createdAt', 'desc'),
                limit(docLimit),
            ),
        )
        return snapshot.docs.map((doc) => doc.data() as User)
    } catch (error) {
        throw error
    }
}

export const fetchUserStats = async (user: User): Promise<Stats> => {
    try {
        const statsRef = doc(
            clientDb,
            `users/${user.uid}/stats`,
            CONFIG.RESTAURANT_ID,
        )
        const snapshot = await getDoc(statsRef)
        if (snapshot.exists()) {
            return snapshot.data() as Stats
        } else {
            return {
                count: 0,
                total: 0,
            }
        }
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchEmployees = async (): Promise<User[]> => {
    try {
        const snapshot = await getDocs(
            query(
                collection(clientDb, 'users'),
                where('claims.role', 'in', [Role.Employee, Role.Owner]),
                where(
                    'claims.restaurant_ids',
                    'array-contains',
                    CONFIG.RESTAURANT_ID,
                ),
            ),
        )
        return snapshot.docs.map((doc) => doc.data() as User)
    } catch (error) {
        throw error
    }
}

export const fetchAdminOrderHistorySnapshot = async (
    lastDoc: QueryDocumentSnapshot,
    docLimit = 10,
): Promise<[Order[], QueryDocumentSnapshot]> => {
    try {
        const snap = await getDocs(
            query(
                collection(clientDb, ORDERS_PATH),
                where('status', 'in', inactiveOrderStatus),
                orderBy('createdAt', 'desc'),
                startAfter(lastDoc ?? 'null'),
                limit(docLimit),
            ),
        )

        const lastDocument = snap.docs[snap.docs.length - 1]
        const neworder = snap.docs.map((doc) => doc.data() as Order)
        return [neworder, lastDocument]
    } catch (error) {
        console.error(error)
        throw error
    }
}

const updateRestaurantOrderStatus = async (
    order: Order,
    nextStatus: OrderStatus,
    updatedByUid: string,
): Promise<void> => {
    try {
        updateDoc(
            doc(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/orders`, order.id),
            {
                status: nextStatus,
                updatedAt: new Date().toISOString(),
                updatedBy: updatedByUid,
            },
        )
    } catch (error) {
        console.error('updateRestaurantOrderStatus', error)
        throw error
    }
}

const updateUserOrderStatus = async (
    order: Order,
    nextStatus: OrderStatus,
    updatedByUid: string,
): Promise<void> => {
    try {
        updateDoc(doc(clientDb, `users/${order.uid}/orders/${order.id}`), {
            status: nextStatus,
            updatedAt: new Date().toISOString(),
            updatedBy: updatedByUid,
        })
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const handleUpdateAdminOrder = async (
    order: Order,
    nextStatus: OrderStatus,
    updatedByUid: string,
): Promise<void> => {
    try {
        const updateOrdersRef = updateRestaurantOrderStatus(
            order,
            nextStatus,
            updatedByUid,
        )
        const updateUsersRef = updateUserOrderStatus(order, nextStatus, updatedByUid)
        await Promise.all([updateOrdersRef, updateUsersRef])
    } catch (error) {
        console.error(error)
        throw error
    }
}

// orders
export const fetchUserOrderHistory = async (
    uid: string,
    lastDoc: QueryDocumentSnapshot,
    docLimit = 10,
): Promise<[Order[], QueryDocumentSnapshot]> => {
    try {
        const snapshot = await getDocs(
            query(
                collection(clientDb, ORDERS_PATH),
                where('uid', '==', uid),
                where('status', 'in', inactiveOrderStatus),
                orderBy('createdAt', 'desc'),
                startAfter(lastDoc ?? 'null'),
                limit(docLimit),
            ),
        )
        const lastDocument = snapshot.docs[snapshot.docs.length - 1]
        const newOrders = snapshot.docs.map((doc) => doc.data() as Order)
        return [newOrders, lastDocument]
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const getCusine = async (): Promise<string[]> => {
    try {
        const snapshot = await getDoc(doc(clientDb, 'config', 'info'))
        const cusine = snapshot.data().cusine as string[]
        return cusine.sort((c1, c2) => (c1 > c2 ? 1 : -1))
    } catch (error) {
        console.error(error)
        throw error
    }
}
