import { AuthCredential, AuthError as FirebaseAuthError } from 'firebase/auth'

export type SocialMediaLoginType = 'popup' | 'redirect'
export type LoginMode = 'login' | 'merge'
export type AuthProviderId = 'facebook.com' | 'google.com' | 'password' | 'phone'

export enum AuthException {
    WrongPassword = 'auth/wrong-password',
    EmailInUse = 'auth/email-already-in-use',
    InvalidActionCode = 'auth/invalid-action-code',
    TooManyRequests = 'auth/too-many-requests',
    UserNotFound = 'auth/user-not-found',
    InvalidPhoneNumber = 'auth/invalid-phone-number',
    Merge = 'auth/account-exists-with-different-credential',
    PopupClosedByUser = 'auth/popup-closed-by-user',
}

interface AuthErrorType extends FirebaseAuthError {
    code: AuthException | string | undefined
    message: string
    loginMode: LoginMode
    providerId: AuthProviderId
}

export class AuthError implements AuthErrorType {
    name: 'FirebaseError'
    stack?: string
    code: string
    message: string
    loginMode: LoginMode
    providerId: AuthProviderId
    credential?: AuthCredential

    customData: {
        readonly appName: string
        readonly email?: string
        readonly phoneNumber?: string
        readonly tenantId?: string
    }

    constructor(error: AuthErrorType) {
        this.name = 'FirebaseError'
        this.message = error.message
        this.code = error.code
        this.loginMode = error.loginMode
        this.customData = error.customData
    }
}
