import { useEffect, useState } from 'react'
import { doc, onSnapshot } from 'firebase/firestore'

import { OrderError, OrderException } from '@interfaces/customError'
import { Order } from '@interfaces/order'
import { clientDb } from '@utils/clientFirebase'
import { CONFIG } from '@utils/config'

const useOrderListener = (orderId: string) => {
    const [order, setOrder] = useState<Order>(null)
    const [error, setError] = useState<OrderError>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!orderId) return

        const unsubscribe = onSnapshot(
            doc(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/orders/${orderId}`),
            (doc) => {
                if (!doc.exists()) {
                    setError(
                        new OrderError(
                            'Comanda nu a fost gasita',
                            OrderException.OrderNotFound,
                        ),
                    )
                }
                setOrder(doc.data() as Order)
                setLoading(false)
            },
            (error) => {
                console.error(error)
                setLoading(false)
                setError(new OrderError(error.message, OrderException.Unknown))
            },
        )

        return () => unsubscribe()
    }, [orderId])

    return { order, error, loading }
}

export default useOrderListener
