import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },

        secondary: {
            main: '#289D50',
        },

        error: {
            main: '#fa755a',
        },

        background: {
            default: '#fafafa',
        },
    },

    typography: {
        fontFamily: ['Nunito Sans', 'sans-serif'],
        h1: {
            fontSize: 25,
        },

        h2: {
            fontSize: 22,
            fontWeight: 'bold',
        },

        h4: {
            fontSize: 20,
            fontWeight: '600',
        },
        h3: {
            fontSize: 20,
        },
        h5: {
            fontSize: 20,
        },

        // h6: {
        // 	fontSize: 16,
        // 	fontWeight: 'bolder'
        // }
    },

    props: {
        MuiChip: {
            size: 'medium',
            style: {
                padding: '1em .5em',
            },
        },
        MuiCard: {
            variant: 'outlined',
        },

        MuiFormControlLabel: {
            style: {
                marginLeft: -16,
                width: '100%',
            },

            '&:hover': {
                backgroundColor: 'red',
            },
        },

        MuiRadio: {
            color: 'primary',
            size: 'medium',
            style: {
                transform: 'scale(1.15)',
            },
        },

        MuiCheckbox: {
            color: 'primary',
            size: 'medium',
            style: {
                transform: 'scale(1.15)',
            },
        },

        MuiButton: {
            fullWidth: true,
            disableElevation: true,
            style: {
                minWidth: 128,
                borderColor: '#eaeaea',
                whiteSpace: 'nowrap',
            },
        },

        MuiAppBar: {
            elevation: 0,
            color: 'inherit',
        },

        MuiTextField: {
            variant: 'outlined',
        },

        MuiSelect: {
            variant: 'outlined',
        },

        MuiAccordion: {
            variant: 'outlined',
        },
    },

    overrides: {
        MuiFormControlLabel: {
            label: {
                width: '100%',
                marginRight: -10,
                padding: '1rem',
                '&:hover': {
                    backgroundColor: '#f1f1f1',
                },
            },
        },
    },
})

export default theme
