import localforage from 'localforage'
import { isEqual, omit } from 'lodash'

import { Product } from '@interfaces/restaurant'

type ChangeQuantityAction = {
    product: Product
    quantity: number
}

export type CartState = {
    products: Product[]
    total: number
}

export const initialCartState: CartState = {
    products: [],
    total: 0,
}

export type CartReducerAction =
    | { type: 'ADD_ALL'; payload: { products: Product[] } }
    | { type: 'ADD' | 'REMOVE'; payload: { product: Product } }
    | { type: 'CHANGE_QTY'; payload: ChangeQuantityAction }
    | { type: 'CLEAR' }

const getUpdatedProductIndex = (
    newProduct: Product,
    products: Product[],
): number => {
    return products.findIndex(
        (p) =>
            p.id === newProduct.id &&
            isEqual(
                omit(p.product_variations[0], ['quantity']),
                omit(newProduct.product_variations[0], ['quantity']),
            ),
    )
}

const calculateTotal = (products: Product[]): number => {
    return products.reduce(
        (prev, curr) =>
            prev +
            curr.product_variations.reduce(
                (x, variation) => x + variation.totalPrice * variation.quantity,
                0,
            ),
        0,
    )
}

const addAll = (state: CartState, products: Product[]): CartState => {
    return {
        ...state,
        products: products,
        total: calculateTotal(products),
    }
}

const add = (state: CartState, product: Product): CartState => {
    const updatedProducts = [...state.products]
    const productIndex = getUpdatedProductIndex(product, updatedProducts)

    productIndex === -1
        ? updatedProducts.push(product)
        : (updatedProducts[productIndex].product_variations[0].quantity +=
              product.product_variations[0].quantity)

    const newState: CartState = {
        products: updatedProducts,
        total: calculateTotal(updatedProducts),
    }

    localforage.setItem('cart', newState)
    return newState
}

const remove = (state: CartState, product: Product): CartState => {
    const updatedProducts = [...state.products]

    const updatedProductIndex = getUpdatedProductIndex(product, updatedProducts)
    updatedProducts.splice(updatedProductIndex, 1)

    const newState: CartState = {
        products: updatedProducts,
        total: calculateTotal(updatedProducts),
    }

    localforage.setItem('cart', newState)
    return newState
}

const updateQuantity = (
    state: CartState,
    action: ChangeQuantityAction,
): CartState => {
    if (action.quantity === 0) {
        return remove(state, action.product)
    }

    const updatedProducts = [...state.products]
    updatedProducts.map((p) =>
        p.id === action.product.id
            ? (p.product_variations[0].quantity = action.quantity)
            : p,
    )

    const newState: CartState = {
        products: updatedProducts,
        total: calculateTotal(updatedProducts),
    }

    localforage.setItem('cart', newState)
    return newState
}

const clear = (): CartState => {
    localforage.setItem('cart', initialCartState)
    return initialCartState
}

export const cartReducer = (state: CartState, action: CartReducerAction) => {
    switch (action.type) {
        case 'ADD_ALL':
            return addAll(state, action.payload.products)
        case 'ADD':
            return add(state, action.payload.product)
        case 'REMOVE':
            return remove(state, action.payload.product)
        case 'CHANGE_QTY':
            return updateQuantity(state, action.payload)
        case 'CLEAR':
            return clear()

        default:
            return state
    }
}
