import { createContext, useContext, useEffect, useReducer } from 'react'
import { checkoutReducer, CheckoutReducerAction } from 'reducers/checkoutReducer'

import { createOrder, fetchCheckOrder } from '@api/server'
import { useCart } from '@context'
import { OrderError, OrderException } from '@interfaces/customError'
import { Order, OrderStatus } from '@interfaces/order'
import { CONFIG } from '@utils/config'
import { formatPrice } from '@utils/helpers'
import { useUser } from './userContext'

export const initialOrder: Order = {
    restaurantId: CONFIG.RESTAURANT_ID,
    id: null,
    trackingId: null,

    uid: null,
    intentId: null,
    name: null,

    delivery: {
        type: 'delivery',
        address: null,
        distanceMatrix: null,
        zone: null,
    },

    requests: {
        needs_ustensils: false,
        special_requests: null,
        to_go: true,
        meeting_place: 'door',
    },

    payment: {
        type: 'cash',
        currency: 'RON',
        subTotal: 0,
        deliveryFee: 0,
        total: 0,
    },

    status: OrderStatus.PENDING,
    createdAt: null,
    updatedAt: null,
    products: [],
}

export type CheckoutProps = {
    state: Order
    dispatch: React.Dispatch<CheckoutReducerAction>
    checkOrder: () => Promise<void>
    sendOrder: (intentId: string) => Promise<string>
}

const CheckoutContext = createContext<CheckoutProps>({
    state: initialOrder,
    dispatch: null,
    checkOrder: async () => {},
    sendOrder: async () => null,
})

export const CheckoutProvider = ({ children }) => {
    const [state, dispatch] = useReducer(checkoutReducer, initialOrder)
    const cart = useCart()

    const {
        state: { delivery: userDelivery, user },
    } = useUser()

    useEffect(() => {
        if (!userDelivery) return

        dispatch({
            type: 'DELIVERY',
            payload: {
                delivery: {
                    ...state.delivery,
                    distanceMatrix: userDelivery.distanceMatrix,
                    zone: userDelivery.zone,
                },
            },
        })
    }, [userDelivery])

    const clear = () => {
        dispatch({ type: 'CLEAR' })
        cart.clear()
    }

    const performClientSideErrorChecks = () => {
        if (!user.phoneNumber) {
            throw new OrderError('Adauga numarul de telefon', OrderException.Phone)
        }

        if (!user.email) {
            throw new OrderError('Adauga adresa de email', OrderException.Email)
        }

        if (state.delivery.type === 'delivery') {
            if (
                state.products.length > 0 &&
                state.payment.subTotal < state.delivery.zone.min_order
            ) {
                const minOrderErrorMessage = `Diferenta pana la comanda minima este de ${formatPrice(
                    state.delivery?.zone?.min_order - state.payment.subTotal,
                )}`

                throw new OrderError(minOrderErrorMessage, OrderException.MinOrder)
            }

            if (!state.delivery.address) {
                throw new OrderError(
                    'Adauga adresa de livrare',
                    OrderException.Address,
                )
            }
        }
    }

    const handlePriceChangeError = (error: OrderError): never => {
        cart.clear()
        error.products.forEach((p) => cart.add(p))

        throw new OrderError(error.message, error.error)
    }

    const checkOrder = async (): Promise<void> => {
        try {
            performClientSideErrorChecks()
            await fetchCheckOrder(state)
        } catch (error) {
            if (error.code === OrderException.PriceChange) {
                handlePriceChangeError(error)
            } else {
                throw new Error(error)
            }
        }
    }

    const sendOrder = async (intentId: string) => {
        try {
            const order: Order = { ...state, intentId }
            const orderId = await createOrder(order)
            clear()
            return orderId
        } catch (error) {
            throw error
        }
    }

    return (
        <CheckoutContext.Provider
            value={{
                state,
                dispatch,
                checkOrder,
                sendOrder,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    )
}
export default CheckoutProvider
export const useCheckout = () => useContext(CheckoutContext)
