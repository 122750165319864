import { Button, CircularProgress } from '@material-ui/core'

import { formatPrice } from '@utils/helpers'

const styles = {
    button: {
        height: 50,
    },
}

interface Props {
    itemCount: number
    title: string
    price: number
    disabled?: boolean
    loading?: boolean
    color?: 'primary' | 'default'
    onClick: () => void
}

const CheckoutButton: React.FC<Props> = ({
    itemCount,
    title,
    price,
    color = 'primary',
    disabled = false,
    loading = false,
    onClick,
}) => {
    return (
        <div className="cart__btn">
            <Button
                style={styles.button}
                variant="contained"
                size="large"
                color={color}
                fullWidth
                onClick={onClick}
                disabled={disabled || loading}
            >
                {loading ? (
                    <CircularProgress size={22} />
                ) : (
                    <>
                        {itemCount && (
                            <span className="item__count">{itemCount}</span>
                        )}
                        {title}
                        <span className="price">
                            {formatPrice(price)}
                        </span>
                    </>
                )}
            </Button>
        </div>
    )
}

export default CheckoutButton
