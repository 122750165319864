import {
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
} from '@material-ui/core'
import { CreditCard, Edit } from '@material-ui/icons'

import { useCheckout } from '@context'
import { StripePaymentMethod } from '@interfaces/order'

interface Props {
    paymentMethod: StripePaymentMethod
    disabled: boolean
    onEditClick: () => void
}

const FavoriteCardRadioButton: React.FC<Props> = ({
    paymentMethod,
    disabled,
    onEditClick,
}) => {
    const { state, dispatch } = useCheckout()

    const handleChangePaymentType = () => {
        dispatch({ type: 'PAYMENT_TYPE', payload: { paymentType: 'saved_card' } })
        dispatch({ type: 'PAYMENT_METHOD_ID', payload: { id: paymentMethod.id } })
    }

    const handleEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        onEditClick()
    }

    if (!paymentMethod) return null

    return (
        <ListItem
            divider
            button
            onClick={handleChangePaymentType}
            disabled={disabled}
        >
            <ListItemIcon style={{ marginLeft: -8 }}>
                <Radio checked={state.payment.type === 'saved_card'} />
            </ListItemIcon>

            <ListItemText
                className="capitalize"
                primary={`${paymentMethod?.card?.brand} •••• ${paymentMethod?.card?.last4}`}
            />

            <IconButton aria-label="credit-card">
                <CreditCard />
            </IconButton>

            <IconButton aria-label="edit" onClick={handleEdit}>
                <Edit />
            </IconButton>
        </ListItem>
    )
}

export default FavoriteCardRadioButton
