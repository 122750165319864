import { Chip } from '@material-ui/core'
import { DirectionsWalkOutlined, LocalShipping } from '@material-ui/icons'
import { ToggleButtonGroup } from '@material-ui/lab'

import { useCheckout } from '@context'
import { Info } from '@interfaces/info'
import { DeliveryType } from '@interfaces/order'

interface Props {
    info: Info
}

const DeliveryTypeOptions: React.FC<Props> = ({ info }) => {
    const {
        state: {
            delivery: { type },
        },
        dispatch,
    } = useCheckout()

    const handleChangeDeliveryType = (deliveryType: DeliveryType) => {
        dispatch({
            type: 'DELIVERY_TYPE',
            payload: { deliveryType },
        })
    }

    const offersDelivery = info?.delivery?.types?.includes('delivery')
    const offersPickup = info?.delivery?.types?.includes('pickup')

    if (!offersDelivery) {
        if (type === 'delivery') {
            handleChangeDeliveryType('pickup')
        }

        return null
    }

    return (
        <ToggleButtonGroup>
            {offersDelivery && (
                <Chip
                    label="Livrare"
                    icon={<LocalShipping />}
                    onClick={(e) => {
                        e.preventDefault()
                        handleChangeDeliveryType('delivery')
                    }}
                    color={type === 'delivery' ? 'primary' : 'default'}
                    variant={type === 'delivery' ? 'default' : 'outlined'}
                />
            )}
            {offersPickup && (
                <Chip
                    label="Ridicare"
                    icon={<DirectionsWalkOutlined />}
                    onClick={(e) => {
                        e.preventDefault()
                        handleChangeDeliveryType('pickup')
                    }}
                    color={type === 'pickup' ? 'primary' : 'default'}
                    variant={type === 'pickup' ? 'default' : 'outlined'}
                />
            )}
        </ToggleButtonGroup>
    )
}

export default DeliveryTypeOptions
