import React, { useState } from 'react'

import { CustomDialog, SPButton } from '@components/common'
import { RadioButtonSkeleton } from '@components/skeleton'
import { AddPaymentMethodForm, UserPaymentMethodItem } from '@components/user'
import { useUser } from '@context'

interface Props {
    open: boolean
    onClose: () => void
}

const CheckoutEditCardListDialog: React.FC<Props> = ({ open, onClose }) => {
    const [addCardFormVisible, setAddCardFormVisible] = useState(false)
    const toggleAddCardFormVisible = () => setAddCardFormVisible(!addCardFormVisible)

    const {
        state: { user, cardState },
        deleteCard,
        setFavoriteCard,
    } = useUser()

    if (cardState.loading) {
        return <RadioButtonSkeleton count={2} />
    }

    return (
        <CustomDialog
            open={open}
            onClose={addCardFormVisible ? toggleAddCardFormVisible : onClose}
            maxWidth="xs"
            title={addCardFormVisible ? 'Adauga card' : 'Cardurile mele'}
            backIcon={addCardFormVisible ? 'back' : 'close'}
        >
            {addCardFormVisible ? (
                <AddPaymentMethodForm onSuccess={toggleAddCardFormVisible} />
            ) : (
                <>
                    {cardState.savedCards?.map((c) => (
                        <UserPaymentMethodItem
                            key={c.id}
                            favoriteCardId={user.favoriteCardId}
                            paymentMethod={c}
                            handleSetFavorite={() => setFavoriteCard(c.id)}
                            handleDelete={() => deleteCard(c.id)}
                        />
                    ))}

                    <div className="p1">
                        <SPButton
                            text="Adauga"
                            variant="contained"
                            fullWidth
                            onClick={toggleAddCardFormVisible}
                        />
                    </div>
                </>
            )}
        </CustomDialog>
    )
}

export default CheckoutEditCardListDialog
