import React from 'react'
import { Container, Grid } from '@material-ui/core'

import { SEO } from '@components/common'
import SPCardSkeleton from './SPCardSkeleton'

const OrderDetailSkeleton = () => (
    <Container className="container mv1">
        <SEO title="Se incarca" />

        <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
                <SPCardSkeleton />
            </Grid>

            <Grid item xs={12} sm={5}>
                <SPCardSkeleton />
                <br />
                <SPCardSkeleton />
            </Grid>
        </Grid>
    </Container>
)

export default OrderDetailSkeleton
