import React, { createContext, useContext, useReducer } from 'react'

export type AlertServerity = 'success' | 'info' | 'warning' | 'error'

export type AlertState = {
    title: string
    subtitle?: string
    severity: AlertServerity
    open: boolean
    toggle: (open: boolean) => void
}

export const initialState: AlertState = {
    open: false,
    title: null,
    subtitle: null,
    toggle: () => null,
    severity: null,
}

export type AlertAction =
    | { type: 'TITLE'; payload: { title: string } }
    | { type: 'SUBTITLE'; payload: { subtitle: string } }
    | { type: 'SEVERITY'; payload: { severity: AlertServerity } }
    | { type: 'TOGGLE'; payload: { open: boolean } }

const alertReducer = (state: AlertState, action: AlertAction): AlertState => {
    switch (action.type) {
        case 'TITLE':
            return {
                ...state,
                title: action.payload.title,
            }
        case 'SUBTITLE':
            return {
                ...state,
                subtitle: action.payload.subtitle,
            }

        case 'SEVERITY':
            return {
                ...state,
                severity: action.payload.severity,
            }

        case 'TOGGLE':
            return {
                ...state,
                open: action.payload.open,
            }

        default:
            return state
    }
}

export const AlertContext = createContext<{
    state: AlertState
    show(severity: AlertServerity, title: string, subtitle?: string): void
    dispatch: React.Dispatch<AlertAction>
}>({
    state: initialState,
    show: () => {},
    dispatch: null,
})

const AlertProvider = ({ children }) => {
    const [state, dispatch] = useReducer(alertReducer, initialState)

    const show = (severity: AlertServerity, title: string, subtitle?: string) => {
        dispatch({ type: 'TITLE', payload: { title } })
        dispatch({ type: 'SUBTITLE', payload: { subtitle } })
        dispatch({ type: 'SEVERITY', payload: { severity } })
        dispatch({ type: 'TOGGLE', payload: { open: true } })
    }

    return (
        <AlertContext.Provider value={{ state, show, dispatch }}>
            {children}
        </AlertContext.Provider>
    )
}
export default AlertProvider
export const useAlert = () => useContext(AlertContext)
