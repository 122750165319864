import React, { forwardRef } from 'react'
import {
    AppBar,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { ArrowBack, Close as CloseIcon } from '@material-ui/icons'

import { CustomDialogProps } from '@interfaces/dialog'

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

export const CustomDialog: React.FC<CustomDialogProps> = ({
    children,
    title,
    subtitle,
    leftAction,
    rightAction,
    open,
    onClose,
    maxWidth = 'sm',
    position = 'sticky',
    color = 'inherit',
    onScrollCapture,
    isFullscreen = false,
    disableFullScreen = false,
    backIcon = 'close',
    backIconColor = 'rgba(255,255,255,0.5)',
    actionButton,
}) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

    if (!open) return null

    const handleOnScrollCapture = (e: any) => {
        try {
            onScrollCapture(+e.target.scrollTop)
        } catch (error) {}
    }
    return (
        <Dialog
            fullWidth
            maxWidth={maxWidth}
            fullScreen={isFullscreen ? true : disableFullScreen ? false : fullScreen}
            open={open}
            scroll="paper"
            onClose={onClose}
            className="dialog"
            onScrollCapture={handleOnScrollCapture}
            keepMounted
            TransitionComponent={Transition}
        >
            <AppBar
                position={position}
                color={position === 'sticky' ? 'inherit' : color}
            >
                <Toolbar>
                    <IconButton
                        aria-label="close"
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        style={{ backgroundColor: backIconColor }}
                    >
                        {backIcon === 'close' ? <CloseIcon /> : <ArrowBack />}
                    </IconButton>

                    <div>
                        {typeof title === 'string' ? <h2>{title}</h2> : title}

                        {subtitle &&
                            (typeof subtitle === 'string' ? (
                                <Typography variant="inherit" color="textSecondary">
                                    {subtitle}
                                </Typography>
                            ) : (
                                subtitle
                            ))}
                    </div>

                    {leftAction && leftAction}

                    {rightAction && (
                        <>
                            <div style={{ flexGrow: 1 }} />
                            {rightAction}
                        </>
                    )}
                </Toolbar>
            </AppBar>

            <DialogContent style={{ padding: 0 }}>{children}</DialogContent>

            {actionButton && <DialogActions>{actionButton}</DialogActions>}
        </Dialog>
    )
}

export default CustomDialog
