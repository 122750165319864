import Link from 'next/link'
import { Container, Grid, Hidden, IconButton } from '@material-ui/core'
import { Facebook, Instagram } from '@material-ui/icons'

import { useCart, useStore } from '@context'
import { isInStandaloneMode } from '@utils/helpers'
import classes from './Footer.module.scss'

const Footer = () => {
    const cart = useCart()
    const { info } = useStore()
    // Detects if device is in standalone mode

    if (isInStandaloneMode()) {
        return null
    }

    return (
        <footer className={classes.footer}>
            <Container>
                <Grid container spacing={2}>
                    {info?.social?.facebook_url || info?.social?.instagram_url ? (
                        <Grid item xs={12} sm={3}>
                            <h3>Social</h3>
                            <div style={{ display: 'flex' }}>
                                {info.social.facebook_url && (
                                    <a
                                        href={info.social.facebook_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ marginRight: 16 }}
                                    >
                                        <IconButton
                                            size="small"
                                            aria-label="facebook"
                                        >
                                            <Facebook
                                                color="action"
                                                fontSize="medium"
                                            />
                                        </IconButton>
                                    </a>
                                )}

                                {info.social.instagram_url && (
                                    <a
                                        href={info.social.instagram_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <IconButton
                                            size="small"
                                            aria-label="instagram"
                                        >
                                            <Instagram
                                                color="action"
                                                fontSize="medium"
                                            />
                                        </IconButton>
                                    </a>
                                )}
                            </div>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} sm={3} className="column">
                        <h3>Contact</h3>
                        {info?.phoneNumber && (
                            <a href={`tel:${info.phoneNumber}`} aria-label="tel">
                                {info.phoneNumber}
                            </a>
                        )}

                        {info?.address?.label && (
                            <a
                                href={`https://maps.google.com/?q=${info.address.label}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {info?.address?.label}
                            </a>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={3} className="column">
                        <h3>General</h3>
                        <Link href="/terms-and-conditions" passHref>
                            <a>Termeni si conditii</a>
                        </Link>

                        <Link href="/privacy-policy" passHref>
                            <a>Politica de confidentialitate</a>
                        </Link>

                        <a
                            href="https://anpc.ro/"
                            target="_blank"
                            rel="noreferrer"
                            aria-label="anpc"
                        >
                            ANPC
                        </a>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <a
                            href="https://foodsy.ro"
                            target="_blank"
                            aria-label="developer"
                            rel="noopener noreferrer"
                        >
                            <small>
                                &copy; {new Date().getFullYear()}{' '}
                                <span>foodsy.ro</span> | toate drepturile rezervate
                            </small>{' '}
                        </a>

                        <Hidden smUp>
                            <div
                                style={{
                                    marginBottom: isInStandaloneMode()
                                        ? 200
                                        : cart.getItemCount() === 0
                                        ? 0
                                        : 64,
                                }}
                            />
                        </Hidden>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    )
}

export default Footer
