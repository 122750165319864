import { Product } from './restaurant'

export enum OrderException {
    Phone = 'order/phone',
    Email = 'order/email',
    Address = 'order/address',
    MinOrder = 'order/min-order',
    Closed = 'order/closed',
    PriceChange = 'order/price-change',
    SoldOut = 'order/sold-out',

    VariationNotFound = 'order/variation-not-found',
    OptionNotFound = 'order/option-not-found',
    ToppingNotFound = 'order/topping-not-found',
    OrderNotFound = 'order/order-not-found',
    Unknown = 'order/unknown',
    Stripe = 'order/stripe',
    PermissionDenied = 'permission-denied',
    NotFound = 'not-found',
}

export class OrderError extends Error {
    products: Product[]
    error: OrderException

    constructor(message: string, error: OrderException, products?: Product[]) {
        super(message)
        this.error = error
        this.products = products
    }
}
