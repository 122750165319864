import { GeocodeResult, Suggestion } from 'use-places-autocomplete'

import {
    Address,
    AddressComponentType,
    Coords,
    DistanceMatrix,
    DistanceMatrixResponse,
    DistanceParam,
} from '@interfaces/address'
import { DeliveryZone } from '@interfaces/address'
import { AddressError, AddressException } from '@interfaces/address-error'
import { Info } from '@interfaces/info'
import { UserDelivery } from '@interfaces/user'
import { CONFIG } from '@utils/config'

export async function fetchDistanceMatrix(
    origin: DistanceParam,
    destination: DistanceParam,
): Promise<DistanceMatrixResponse> {
    const service = new google.maps.DistanceMatrixService()

    return new Promise((resolve, reject) => {
        service.getDistanceMatrix(
            {
                origins: [origin],
                destinations: [destination],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: true,
                avoidTolls: true,
                drivingOptions: {
                    trafficModel: google.maps.TrafficModel.BEST_GUESS,
                    departureTime: new Date(),
                },
            },
            (res, status) => {
                if (status !== 'OK') {
                    reject(status)
                }

                const element = res.rows[0].elements[0]
                resolve(element)
            },
        )
    })
}

export function fetchDeviceCoords(): Promise<Coords> {
    return new Promise<Coords>((resolve, reject) => {
        if (!navigator || !navigator.geolocation) {
            reject('navigator.geolocation')
        }

        const onSuccess = (positon: GeolocationPosition): void => {
            const coords: Coords = {
                lat: positon.coords.latitude,
                lng: positon.coords.longitude,
            }
            resolve(coords)
        }

        const onError = (error: GeolocationPositionError): void => {
            if (error.code === 1) {
                reject(
                    'Nu te-am putut localiza. Verifica permisiunile de localizare.',
                )
            } else {
                reject('Ne pare rau, nu te-am putut localiza, a aparut o eroare')
            }
        }

        navigator.geolocation.getCurrentPosition(onSuccess, onError, {
            enableHighAccuracy: false,
            timeout: Infinity,
            maximumAge: 0,
        })
    })
}

export const geocodeSuggestion = (
    suggestion: Suggestion,
): Promise<GeocodeResult> => {
    const geocoder = new google.maps.Geocoder()

    return new Promise<GeocodeResult>((resolve, reject) => {
        geocoder.geocode(
            suggestion.place_id
                ? { placeId: suggestion.place_id }
                : { address: suggestion.description },
            (results, status) => {
                if (status !== 'OK') {
                    reject(status)
                }
                results.length === 0
                    ? reject('No results found')
                    : resolve(results[0])
            },
        )
    })
}

export const geocodeCoords = (coords: Coords): Promise<GeocodeResult> => {
    const geocoder = new google.maps.Geocoder()

    return new Promise<GeocodeResult>((resolve, reject) => {
        geocoder.geocode({ location: coords }, (results, status) => {
            if (status !== 'OK') {
                reject(status)
            }
            results.length === 0 ? reject('No results found') : resolve(results[0])
        })
    })
}

function getComponent(
    result: GeocodeResult,
    component: AddressComponentType,
): string {
    const getComponent = result.address_components.find((c) =>
        c.types.includes(component),
    )
    return getComponent?.long_name ?? null
}

export function makeAddress(
    result: GeocodeResult,
    matrix?: DistanceMatrixResponse,
    deliveryZoneId?: string,
): Address {
    const country = getComponent(result, 'country')
    const city = getComponent(result, 'administrative_area_level_1')
    const county = getComponent(result, 'administrative_area_level_2')
    const locality = getComponent(result, 'locality')
    const postalCode = getComponent(result, 'postal_code')
    const street = getComponent(result, 'route')
    const streetNumber = getComponent(result, 'street_number')

    const distanceMatrix: DistanceMatrix = {
        zoneId: deliveryZoneId,
        distance: matrix?.distance,
        duration: matrix?.duration,
    }

    return {
        label: result.formatted_address,
        placeId: result.place_id,
        coords: result.geometry.location.toJSON(),
        mainText: `${street} ${streetNumber}` ?? result.formatted_address,
        secondaryText: `${locality}, ${country}` ?? '',
        distanceMatrix: { [CONFIG.RESTAURANT_ID]: distanceMatrix },
        country,
        city,
        county,
        locality,
        street,
        streetNumber,
        postalCode,
    }
}

export const findUserDelivery = (
    info: Info,
    primaryAddress?: Address | Address[],
): UserDelivery => {
    const address = Array.isArray(primaryAddress)
        ? primaryAddress.find((a) => a.primary)
        : primaryAddress

    if (!address || !address.primary)
        throw new AddressError(
            'Adauga o adresa pentru a putea alege o zona de livrare',
            AddressException.NoAddress,
        )

    const deliveryZones = info?.delivery?.zones

    if (!deliveryZones)
        throw new AddressError(
            'Restaurantul nu a setat zone de livrare.',
            AddressException.RestaurantDeliveryZones,
        )

    const deliveryInfo = address?.distanceMatrix?.[CONFIG.RESTAURANT_ID]

    if (!deliveryInfo)
        throw new AddressError(
            'Nu putem livra la aceasta adresa.',
            AddressException.DeliveryInfo,
        )

    const deliveryZone = deliveryZones.find((z) => z.id === deliveryInfo.zoneId)

    if (!deliveryZone)
        throw new AddressError(
            'Nu putem livra la aceasta adresa.',
            AddressException.DeliveryZone,
        )

    return {
        zone: deliveryZone,
        distanceMatrix: deliveryInfo,
    }
}

export const findGoogleDeliveryZone = (
    deliveryZones: DeliveryZone[],
    coords: Coords,
): DeliveryZone | null => {
    if (!deliveryZones) {
        throw new AddressError(
            'Restaurantul nu a setat zone de livrare.',
            AddressException.RestaurantDeliveryZones,
        )
    }

    const zone = deliveryZones
        .filter((z) => z.active === true)
        .find((z) => {
            const polygon = new google.maps.Polygon({ paths: z.coords })
            const location = new google.maps.LatLng(coords.lat, coords.lng)
            return google.maps.geometry.poly.containsLocation(location, polygon)
        })

    if (!zone) {
        throw new AddressError(
            'Nu putem livra la aceasta adresa.',
            AddressException.DeliveryZone,
        )
    }

    return zone
}
