import React from 'react'
import {
    Chip,
    ClickAwayListener,
    Hidden,
    IconButton,
    makeStyles,
    Toolbar,
    Tooltip,
} from '@material-ui/core'
import {
    ArrowDropDown,
    ArrowDropUp,
    Close,
    ShoppingBasket as CartIcon,
} from '@material-ui/icons'

import { analyticsViewCart } from '@api/analytics'
import { Cart } from '@components/cart'
import { CartProps } from '@context'

const useStyles = makeStyles((theme) => ({
    arrow: {
        '&:before': {
            border: '1px solid #E6E8ED',
        },
        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #E6E8ED',
        color: '#4A4A4A',
        minWidth: 550,
    },
}))

interface Props {
    cart: CartProps
}
const HeaderCartIcon: React.FC<Props> = ({ cart }) => {
    const classes = useStyles()

    const { open, setOpen, cartState } = cart
    const toggleCart = () => {
        setOpen(!open)
        if (open) {
            analyticsViewCart(cartState)
        }
    }

    return (
        <Hidden xsDown>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div className="flex">
                    <Tooltip
                        classes={{
                            arrow: classes.arrow,
                            tooltip: classes.tooltip,
                        }}
                        interactive
                        arrow
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={open}
                        onClose={toggleCart}
                        title={
                            <>
                                <Toolbar disableGutters>
                                    <IconButton
                                        aria-label="close"
                                        color="primary"
                                        onClick={toggleCart}
                                    >
                                        <Close />
                                    </IconButton>
                                    {cartState.products.length > 0 && (
                                        <h2 className="primary">Cosul meu</h2>
                                    )}
                                </Toolbar>
                                <br />
                                <Cart />
                            </>
                        }
                    >
                        <Chip
                            onClick={toggleCart}
                            color="primary"
                            icon={<CartIcon fontSize="small" />}
                            label={`Cos • ${cart.getItemCount()}`}
                            deleteIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
                            onDelete={toggleCart}
                        />
                    </Tooltip>
                </div>
            </ClickAwayListener>
        </Hidden>
    )
}

export default HeaderCartIcon
