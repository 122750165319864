import React from 'react'
import { Container } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { SEO } from '@components/common'

const ActiveOrderSkeeton = () => {
    return (
        <Container maxWidth="sm" className="container p1">
            <SEO title="Se incarca" />
            <Skeleton variant="text" width={200} height={50} />
            {Array.from(Array(8), (_, x) => x).map((_, index) => (
                <div key={index} className="mt05">
                    <Skeleton variant="rect" width="auto" height={100} />
                </div>
            ))}
        </Container>
    )
}

export default ActiveOrderSkeeton
