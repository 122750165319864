export const isDev = process.env.NODE_ENV === 'development'

export const CONFIG = {
    RESTAURANT_ID: isDev ? 'just-pizza' : process.env.NEXT_PUBLIC_RESTAURANT_ID,
    RESTAURANT_NAME: isDev
        ? 'Dev Restaurant'
        : process.env.NEXT_PUBLIC_RESTAURANT_NAME,
    API_KEY: 'AIzaSyAl8TpmuyffPDlNBcfUANp3EjkC-8ntlng',
    STORAGE_BUCKET: 'foodsy-ro.appspot.com',
    STRIPE_PK_TEST: process.env.NEXT_PUBLIC_STRIPE_PK_TEST,
    STRIPE_SK_TEST: process.env.STRIPE_SK_TEST,
    FUNCTIONS_URL: isDev
        ? 'http://localhost:5001/foodsy-ro/europe-west3'
        : 'https://europe-west3-foodsy-ro.cloudfunctions.net',
    CATEGORY_MAX_CHARS: 25,
    NAME_MAX_CHARS: 50,
    DESCRIPTION_MAX_CHARS: 300,

    VAPID_KEY: 'BKbO363JSqixWVK35_bbhN-ObJmJtJhdKTg_P9bmuQ5wgRqu7ADEHWjcrLrbH8x2BOf6H6gDPX9iaI5PBt7qnCk'
}

export const API_BASE_URL = isDev
    ? 'http://localhost:3001'
    : 'https://api.foodsy.ro/'
export const MANIFEST_URL = `${API_BASE_URL}/info/${CONFIG.RESTAURANT_ID}/manifest`

export const LogoImages = {
    '16': `https://firebasestorage.googleapis.com/v0/b/foodsy-ro.appspot.com/o/${CONFIG.RESTAURANT_ID}%2Finfo%2Flogo%2Flogo-16?alt=media&`,
    '32': `https://firebasestorage.googleapis.com/v0/b/foodsy-ro.appspot.com/o/${CONFIG.RESTAURANT_ID}%2Finfo%2Flogo%2Flogo-32?alt=media&`,
    '64': `https://firebasestorage.googleapis.com/v0/b/foodsy-ro.appspot.com/o/${CONFIG.RESTAURANT_ID}%2Finfo%2Flogo%2Flogo-64?alt=media&`,
    '128': `https://firebasestorage.googleapis.com/v0/b/foodsy-ro.appspot.com/o/${CONFIG.RESTAURANT_ID}%2Finfo%2Flogo%2Flogo-128?alt=media&`,
    '180': `https://firebasestorage.googleapis.com/v0/b/foodsy-ro.appspot.com/o/${CONFIG.RESTAURANT_ID}%2Finfo%2Flogo%2Flogo-180?alt=media&`,
    '192': `https://firebasestorage.googleapis.com/v0/b/foodsy-ro.appspot.com/o/${CONFIG.RESTAURANT_ID}%2Finfo%2Flogo%2Flogo-192?alt=media&`,
    '256': `https://firebasestorage.googleapis.com/v0/b/foodsy-ro.appspot.com/o/${CONFIG.RESTAURANT_ID}%2Finfo%2Flogo%2Flogo-256?alt=media&`,
    '384': `https://firebasestorage.googleapis.com/v0/b/foodsy-ro.appspot.com/o/${CONFIG.RESTAURANT_ID}%2Finfo%2Flogo%2Flogo-384?alt=media&`,
    '512': `https://firebasestorage.googleapis.com/v0/b/foodsy-ro.appspot.com/o/${CONFIG.RESTAURANT_ID}%2Finfo%2Flogo%2Flogo-512?alt=media&`,
}

type Libraries = (
    | 'drawing'
    | 'geometry'
    | 'localContext'
    | 'places'
    | 'visualization'
)[]

export const GOOGLE_MAPS_LIBRARIES: Libraries = ['drawing', 'places', 'geometry']
