import { FC, useState } from 'react'
import { Link } from 'react-scroll'
import { Tab, Tabs } from '@material-ui/core'

import { classes, TabLayoutPlaceholder } from '@components/tabLayout'
import { Menu } from '@interfaces/restaurant'

interface Props {
    menus: Menu[]
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

const TabLayout: FC<Props> = ({ menus }) => {
    const [active, setActive] = useState(0)
    const onSetActive = (index: number) => () => setActive(index)

    if (process.env.NODE_ENV !== 'production') {
        return <TabLayoutPlaceholder />
    }

    return (
        <div className={classes.tab__container}>
            <div className="container-lg">
                <Tabs
                    aria-label="menu-category"
                    role="tablist"
                    className={classes.tabs}
                    value={active}
                    variant="scrollable"
                    scrollButtons="auto"
                    selectionFollowsFocus={true}
                    indicatorColor="primary"
                >
                    {menus?.map((menu, index) => (
                        <Link
                            key={menu.id}
                            role="tab"
                            spy
                            href={`#${menu.name}`}
                            to={menu.name}
                            offset={-115}
                            onSetActive={onSetActive(index)}
                            as="a"
                        >
                            <Tab
                                value={index}
                                aria-label={menu.name}
                                label={menu.name}
                                fullWidth
                                role="tab"
                                {...a11yProps(index)}
                            />
                        </Link>
                    ))}
                </Tabs>
            </div>
        </div>
    )
}

export default TabLayout
