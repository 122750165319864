import { Container } from '@material-ui/core'

import { SEO } from '@components/common'

interface Props {
    title: string
    description: string
}

const TextPage: React.FC<Props> = ({ title, description }) => {
    return (
        <div className="container white">
            <SEO title={title} />
            <Container>
                <h1 className="pt1">{title}</h1>
                <p>{description}</p>
            </Container>
        </div>
    )
}

export default TextPage
