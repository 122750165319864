import React from 'react'
import { Field } from 'formik'
import { Checkbox } from '@material-ui/core'
import { AddBox } from '@material-ui/icons'
import { CardElement } from '@stripe/react-stripe-js'

import { classes } from '@components/checkout'
import { FormikTextField, SPRadioButton } from '@components/common'
import { useCheckout } from '@context'

interface Props {
    isSubmitting: boolean
}

const AddCardRadioButton: React.FC<Props> = ({ isSubmitting }) => {
    const { state, dispatch } = useCheckout()

    const handleChangePaymentType = () => {
        dispatch({
            type: 'PAYMENT_TYPE',
            payload: { paymentType: 'new_card' },
        })
    }

    return (
        <div
            className={
                state.payment.type === 'new_card'
                    ? classes.add__card__selected
                    : null
            }
        >
            <SPRadioButton
                title="+ Card nou"
                icon={<AddBox />}
                checked={state.payment.type === 'new_card'}
                onClick={handleChangePaymentType}
                disabled={isSubmitting}
                divider={false}
            />

            {state.payment.type === 'new_card' && (
                <div className="p1">
                    <FormikTextField
                        name="card.name"
                        label="Numele titularului"
                        autoComplete="name"
                    />
                    <div className="p05" />
                    <div
                        style={{
                            border: '1px solid #BCBCBC',
                            borderRadius: '4px',
                            padding: '1.5em 1em',
                        }}
                    >
                        <CardElement options={{ hidePostalCode: true }} />
                    </div>

                    <label className="hover textSecondary ">
                        <Field
                            style={{ marginLeft: -12 }}
                            name="card.save"
                            type="checkbox"
                            as={Checkbox}
                        />
                        Salveaza cardul
                    </label>
                </div>
            )}
        </div>
    )
}

export default AddCardRadioButton
