import { initialOrder } from '@context'
import { Address } from '@interfaces/address'
import {
    DeliveryType,
    MeetingPlace,
    Order,
    OrderDelivery,
    OrderStatus,
    PaymentType,
} from '@interfaces/order'
import { UserState } from '@interfaces/user'
import { CartState } from '@reducers'
import { CONFIG } from '@utils/config'

type UpdateOrderPayload = { userState: UserState; cart: CartState }

export type CheckoutReducerAction =
    | { type: 'CLEAR' }
    | { type: 'UPDATE_ORDER'; payload: UpdateOrderPayload }
    | { type: 'UPDATE_ADDRESS'; payload: { address: Address } }
    | { type: 'DELIVERY'; payload: { delivery: OrderDelivery } }
    | { type: 'DELIVERY_TYPE'; payload: { deliveryType: DeliveryType } }
    | { type: 'MEETING_PLACE'; payload: { meetingPlace: MeetingPlace } }
    | { type: 'PAYMENT_TYPE'; payload: { paymentType: PaymentType } }
    | { type: 'INTENT_ID'; payload: { id: string } }
    | { type: 'PAYMENT_METHOD_ID'; payload: { id: string } }
    | { type: 'NEEDS_USTENSILS'; payload: { needsUstensils: boolean } }
    | { type: 'TO_GO'; payload: { toGo: boolean } }
    | { type: 'SPECIAL_REQUESTS'; payload: { text: string } }

export const checkoutReducer = (
    state: Order,
    action: CheckoutReducerAction,
): Order => {
    switch (action.type) {
        case 'UPDATE_ORDER':
            return handleUpdateOrder(state, action.payload)
        case 'DELIVERY':
            return { ...state, delivery: action.payload.delivery }
        case 'UPDATE_ADDRESS':
            return handleUpdateAddress(state, action.payload.address)

        case 'DELIVERY_TYPE':
            return changeDeliveryType(state, action.payload.deliveryType)
        case 'PAYMENT_TYPE':
            return changePaymentType(state, action.payload.paymentType)
        case 'MEETING_PLACE':
            return changeMeetingPlace(state, action.payload.meetingPlace)
        case 'NEEDS_USTENSILS':
            return changeNeedsUstensils(state, action.payload.needsUstensils)
        case 'TO_GO':
            return handleChangeWrapToGo(state, action.payload.toGo)
        case 'SPECIAL_REQUESTS':
            return handleChangeSpecialRequests(state, action.payload.text)
        case 'PAYMENT_METHOD_ID':
            return {
                ...state,
                payment: { ...state.payment, paymentMethodId: action.payload.id },
            }
        case 'INTENT_ID':
            return {
                ...state,
                intentId: action.payload.id,
            }
        case 'CLEAR':
            return initialOrder
        default:
            return state
    }
}

const changeDeliveryType = (state: Order, deliveryType: DeliveryType): Order => {
    return {
        ...state,
        delivery: {
            ...state.delivery,
            type: deliveryType,
        },
    }
}

const changePaymentType = (state: Order, paymentType: PaymentType): Order => {
    return {
        ...state,
        payment: {
            ...state.payment,
            type: paymentType,
        },
    }
}

const changeMeetingPlace = (state: Order, meetingPlace: MeetingPlace): Order => {
    return {
        ...state,
        requests: {
            ...state.requests,
            meeting_place: meetingPlace,
        },
    }
}

const changeNeedsUstensils = (state: Order, needsUstensils: boolean): Order => {
    return {
        ...state,
        requests: {
            ...state.requests,
            needs_ustensils: needsUstensils,
        },
    }
}

const handleChangeSpecialRequests = (state: Order, text: string): Order => {
    return {
        ...state,
        requests: {
            ...state.requests,
            special_requests: text,
        },
    }
}

const handleChangeWrapToGo = (state: Order, toGo: boolean): Order => {
    return {
        ...state,
        requests: {
            ...state.requests,
            to_go: toGo,
        },
    }
}

const handleUpdateAddress = (state: Order, address: Address): Order => {
    return {
        ...state,
        delivery: {
            ...state.delivery,
            address,
        },
    }
}

const calculateDeliveryFee = (
    deliveryType: DeliveryType,
    payload: UpdateOrderPayload,
): number => {
    if (deliveryType === 'pickup') return 0

    const { cart, userState } = payload
    const { delivery_fee, free_delivery_treshold } = userState.delivery?.zone

    return cart.total > free_delivery_treshold ? 0 : delivery_fee
}

const handleUpdateOrder = (state: Order, payload: UpdateOrderPayload): Order => {
    const {
        cart,
        userState: { user },
    } = payload

    const deliveryFee = calculateDeliveryFee(state.delivery.type, payload)

    const order: Order = {
        restaurantId: CONFIG.RESTAURANT_ID,
        uid: user.uid,
        intentId: state.intentId ?? null,
        name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
        delivery: {
            ...state.delivery,
            type: state.delivery.type,
            address: user.addresses?.find((a) => a.primary),
        },
        requests: state.requests,
        products: cart.products,
        createdAt: null,
        updatedAt: null,
        payment: {
            type: state.payment.type,
            paymentMethodId: state.payment?.paymentMethodId ?? '',
            currency: state.payment.currency,
            deliveryFee: deliveryFee,
            subTotal: cart.total,
            total: cart.total + deliveryFee,
        },
        status: OrderStatus.PENDING,
    }

    return order
}
