import { CircularProgress } from '@material-ui/core'

import SEO from './SEO'

interface ProgressBarProps {
    size?: 'small' | 'medium'
    color?: 'primary' | 'secondary' | 'inherit'
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    size = 'medium',
    color = 'secondary',
}) => (
    <>
        <SEO title="Se incarca... " />

        {size === 'small' ? (
            <CircularProgress color={color} size={20} />
        ) : (
            <div className="p2 center">
                <CircularProgress color={color} size={size === 'medium' ? 32 : 16} />
            </div>
        )}
    </>
)

export default ProgressBar
