import React, { useState } from 'react'

import { ConfirmationDialog } from '@components/common'
import { useAlert, useUser } from '@context'

interface Props {
    open: boolean
    onClose: () => void
}
const LogoutDialog: React.FC<Props> = ({ open, onClose }) => {
    const alert = useAlert()
    const userContext = useUser()
    const [loading, setLoading] = useState(false)

    if (!open) return null

    const handleLogout = async () => {
        try {
            setLoading(true)
            await userContext.logout()
            onClose()
        } catch (error) {
            alert.show('error', `A aparut o eroare: ${error.message}`)
        } finally {
            setLoading(false)
        }
    }

    return (
        <ConfirmationDialog
            visible={open}
            title="Te deconectezi ?"
            onPositiveButtonClick={handleLogout}
            onNegativeButtonClick={onClose}
            loading={loading}
        />
    )
}

export default LogoutDialog
