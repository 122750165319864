import { useEffect, useState } from 'react'

import { fetchUser } from '@api/client'
import { User } from '@interfaces/user'

const useFetchUser = (uid: string) => {
    const [user, setUser] = useState<User>(null)
    const [error, setError] = useState<string>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const handleFetch = async () => {
            try {
                const user = await fetchUser(uid)
                setUser(user)
            } catch (error) {
                console.error(error)
                setError(error.message)
            } finally {
                setLoading(false)
            }
        }
        handleFetch()
    }, [uid])

    return {
        user,
        userError: error,
        loadingUser: loading,
    }
}

export default useFetchUser
