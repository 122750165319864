import React from 'react'

import { CustomDialog } from '@components/common'
import { UserProfileForm } from '@components/user'

interface Props {
    open?: boolean
    onClose?: () => void
}

const UserProfileDialog: React.FC<Props> = ({ open, onClose }) => {
    return (
        <CustomDialog open={open} onClose={onClose} title="Profilul meu">
            <div className="p1">
                <UserProfileForm
                    bottomSaveButton
                    onComplete={onClose}
                    cardStyle={{ borderColor: 'white' }}
                />
            </div>
        </CustomDialog>
    )
}

export default UserProfileDialog
