import { ListItemText } from '@material-ui/core'

import { CartProduct } from '@components/cart'
import { classes, SpendMoreForFreeDelivery } from '@components/checkout'
import { SPCard } from '@components/common'
import { useCheckout, useUser } from '@context'
import { formatPrice } from '@utils/helpers'

const CheckoutSummaryContainer = () => {
    const { state } = useCheckout()
    const { payment, delivery, products } = state
    const {
        state: { addressError },
    } = useUser()

    return (
        <SPCard title="Sumar comanda">
            <div className="p1">
                {products?.map((p, index) => (
                    <CartProduct
                        key={p.id + index}
                        product={p}
                        showSubitems
                        showOptionsPrice
                        showPrice
                        priceType="price"
                    />
                ))}

                <ListItemText
                    className="between pt2"
                    primary="Subtotal"
                    secondary={formatPrice(payment.subTotal)}
                />

                {delivery.type === 'delivery' && (
                    <ListItemText
                        className="between"
                        primary="Livrare"
                        secondary={
                            addressError
                                ? ' - '
                                : payment.deliveryFee === 0
                                ? 'GRATUIT'
                                : formatPrice(payment.deliveryFee)
                        }
                    />
                )}

                <ListItemText
                    className={classes.total__text}
                    primary="Total"
                    secondary={formatPrice(payment.total)}
                />

                <SpendMoreForFreeDelivery
                    order={state}
                    freeDeliverySum={delivery?.zone?.free_delivery_treshold}
                />
            </div>
        </SPCard>
    )
}

export default CheckoutSummaryContainer
