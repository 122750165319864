import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { getApp, getApps, initializeApp } from '@firebase/app'

import { CONFIG } from './config'

export const firebaseConfig = {
    apiKey: CONFIG.API_KEY,
    storageBucket: CONFIG.STORAGE_BUCKET,
    authDomain: 'foodsy-ro.firebaseapp.com',
    projectId: 'foodsy-ro',
    messagingSenderId: '284655486157',
    appId: '1:284655486157:web:305d773c0308ff863f9584',
    measurementId: 'G-0VYX2P9C9Z',
}

const app = getApps() ? initializeApp(firebaseConfig) : getApp()
const messaging = typeof window !== 'undefined' && getMessaging(app)
const clientDb = getFirestore(app)
const auth = getAuth(app)
const analytics = typeof window !== 'undefined' && getAnalytics()

export { app, auth, clientDb, analytics, messaging, getToken, onMessage }
