export enum AddressException {
    RestaurantDeliveryZones = 'address/restaurant-no-delivery-zone',
    NoAddress = 'address/no-user-address',
    DeliveryInfo = 'address/no-delivery-info',
    DeliveryZone = 'address/no-delivery-zone',
    StreetNumber = 'address/street-number',
}

export class AddressError extends Error {
    message: string
    code: AddressException

    constructor(message: string, code: AddressException) {
        super(message)
        this.message = message
        this.code = code
    }
}
