import axios from 'axios'
import { format } from 'date-fns'
import { ro } from 'date-fns/locale'

import { Address } from '@interfaces/address'
import { AuthException } from '@interfaces/auth-error'
import { Info, Schedule } from '@interfaces/info'
import {
    Menu,
    Option,
    Product,
    ProductVariation,
    Topping,
} from '@interfaces/restaurant'

type SPDateFormat = 'dd MMM. yyyy' | 'dd MMMM yyyy, HH:mm' | 'HH:mm'

export const formattedProductLink = (product: Product) => {
    const url = `p/${product.id}?name=${product.name
        .replace(/ /g, '-')
        .replace(/'/g, '')
        .toLowerCase()}`
    return url
}

export const formatDate = (date: string, dateFormat?: SPDateFormat): string => {
    try {
        if (typeof date === 'string') {
            return format(new Date(date), dateFormat ?? 'dd MMMM yyyy, HH:mm', {
                locale: ro,
            })
        }

        return format(date, dateFormat ?? 'dd MMMM yyyy, HH:mm', {
            locale: ro,
        })
    } catch (error) {
        console.error('formatDate error:', error)
        return date
    }
}

export const showIosPwaPrompt = (): boolean => {
    if (typeof window === 'undefined') {
        return false
    }

    if (window.matchMedia('(display-mode: standalone)').matches) {
        return false
    }

    if (window.navigator['standalone']) {
        return false
    }

    const isMobile = navigator.userAgent.match(/iPhone|iPad|iPod/i)
    const userAgent = window.navigator.userAgent.toLowerCase()
    const isSafari =
        userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1

    if (isSafari && isMobile) {
        return true
    }

    return false
}

export const isIos = (): boolean => {
    if (typeof window === 'undefined') {
        return false
    }

    const userAgent = window.navigator.userAgent.toLowerCase()

    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') !== -1
    const isSafariMobile =
        isSafari &&
        (userAgent.indexOf('Mobile/') !== -1 || 'standalone' in navigator)

    return isSafariMobile
}

export const isInStandaloneMode = (): boolean => {
    if (typeof window === 'undefined') return false

    const ios = window.navigator && window.navigator['standalone']
    const android = window.matchMedia('(display-mode: standalone)').matches

    return ios || android
}

export const sortedSchedule = (schedule: Schedule[]): Schedule[] =>
    schedule
        ?.sort((s1, s2) => s1.day - s2.day)
        ?.map((item) => ({ ...item, sortId: item.day === 0 ? 7 : item.day }))
        ?.sort((s1, s2) => s1.sortId - s2.sortId)

export function calculatePercentage(amount1: number, amount2: number): string {
    const percent = ((amount1 - amount2) / amount1) * 100
    return `${percent.toFixed(0)}%`
}

export function formatPrice(amount: number): string {
    const numberFormat = new Intl.NumberFormat(['ro-RO'], {
        style: 'currency',
        currency: 'lei',
        currencyDisplay: 'symbol',
    })

    return numberFormat.format(amount).replace('LEI', 'Lei')
}

export const toppingCategoryText = (topping: Topping): string => {
    const required = isRequired(topping)
    const multiple = isMultiple(topping)

    const min = topping.quantity_minimum
    const max = topping.quantity_maximum

    if (required) {
        if (multiple) {
            return `Obligatoriu, alege minim ${min}`
        } else {
            return 'Obligatoriu, alege 1'
        }
    } else {
        if (multiple) {
            if (max === 0) {
                return 'Selecteaza oricate'
            }
            return `Selecteaza pana la ${max} (optional)`
        } else {
            return 'Selecteaza 1 (optional)'
        }
    }
}

export const isMultipleOptionsListDisabled = (
    productVariation: ProductVariation,
    topping: Topping,
    option: Option,
): boolean => {
    const optionsNames: string[] | undefined = productVariation[topping.name]

    return (
        isMultiple(topping) &&
        optionsNames &&
        optionsNames.length !== 0 &&
        optionsNames.length === topping.quantity_maximum &&
        !optionsNames.includes(option.name)
    )
}

export const isMultiple = (topping: Topping): boolean => {
    // return topping.quantity_maximum > 1
    return topping.quantity_maximum !== 1
}

export const isRequired = (topping: Topping): boolean => {
    return topping.quantity_minimum >= 1
}

// error.code
export const authErrorsToRomanian = (errorCode: string | AuthException): string => {
    switch (errorCode) {
        case AuthException.Merge:
            return 'Există deja un cont cu aceeași adresă de e-mail, dar acreditări de conectare diferite.'
        case 'auth/invalid-action-code':
            return 'Codul nu este valid. Acest lucru se poate întâmpla dacă codul este expirat sau a fost deja utilizat.'
        case 'auth/too-many-requests':
            return 'Prea multe incercari. Asteapta cateva minute si incearca din nou.'
        case AuthException.UserNotFound:
            return 'Nu am gasit acest utilizator in baza de date.'
        case 'auth/wrong-password':
            return 'Parola gresita.'
        case 'auth/invalid-phone-number':
            return 'Numarul de telefon nu este valid.'
        default:
            return errorCode
    }
}

export const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const Translations = {
    Yup: {
        Necesar: 'Necesar',
        Minim_Zero: 'Pretul minim > 0',
        Cantitate_Minim_Zero: 'Selecteaza o cantitate > 0',
    },
    Cart: {
        title: 'Cosul meu',
    },
}

// Stripe

export const dayIndexToRomanian = (index: number): string => {
    switch (index) {
        case 0:
            return 'Duminica'
        case 1:
            return 'Luni'
        case 2:
            return 'Marti'
        case 3:
            return 'Miercuri'
        case 4:
            return 'Joi'
        case 5:
            return 'Vineri'
        case 6:
            return 'Sambata'
    }
}

export const handleNavigateQuery = (query: string) => {
    const url = `https://maps.google.com/?q=${query}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const handleNavigate = (lat: number, lng: number) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const swapPositions = (array: [], a: any, b: any) => {
    return ([array[a], array[b]] = [array[b], array[a]])
}

export const setBearerToken = (token: string) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
}

export const hexColors = [
    '#e6194b',
    '#3cb44b',
    '#ffe119',
    '#4363d8',
    '#f58231',
    '#911eb4',
    '#46f0f0',
    '#f032e6',
    '#bcf60c',
    '#008080',
    '#e6beff',
    '#9a6324',
    '#fffac8',
    '#800000',
    '#aaffc3',
    '#808000',
    '#ffd8b1',
    '#000075',
]

export const generateRandomColor = (): string => {
    return hexColors[Math.floor(Math.random() * hexColors.length)]
}

export const formatAddressLabel = (address: Address): string => {
    return address.label
        .replace(address.country, '')
        .replace(address.postalCode, '')
        .replace(' ,', '')
}

export const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i)
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i)
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i)
    },
    Windows: function () {
        return (
            navigator.userAgent.match(/IEMobile/i) ||
            navigator.userAgent.match(/WPDesktop/i)
        )
    },
    any: function () {
        return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
    },
}

export const getSEODesc = (info: Info, menus: Menu[]): string => {
    const cusineKeywords = info?.cusine?.join(', ')?.slice(0, 160)

    const categoryKeywords = menus
        ?.map((m) => m.name)
        ?.join(', ')
        ?.slice(0, 160)

    return `Comanda mancare online ${cusineKeywords} | ${categoryKeywords} | ${
        info?.address?.locality ?? ''
    }`
}
