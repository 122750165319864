import React from 'react'
import { useLoadScript } from '@react-google-maps/api'

import { ProgressBar } from '@components/common'
import { CONFIG, GOOGLE_MAPS_LIBRARIES } from '@utils/config'

const MapsHoc = ({ children, loadingComponent = null }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: CONFIG.API_KEY,
        libraries: GOOGLE_MAPS_LIBRARIES,
    })

    if (loadError) return <div>Error loading maps</div>
    return isLoaded ? children : loadingComponent ?? <ProgressBar />
}

// export default React.memo(MapsHoc)
export default MapsHoc
