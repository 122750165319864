import { User as FirebaseUser, UserCredential } from 'firebase/auth'

import { CONFIG } from '@utils/config'
import { Address, DeliveryZone, DistanceMatrix } from './address'
import { AddressError } from './address-error'
import { AuthError, AuthProviderId } from './auth-error'
import { StripePaymentMethod } from './order'

export interface User {
    uid: string
    customerId?: string | null
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
    photoURL?: string
    password?: string
    addresses?: Address[]
    providerId: AuthProviderId
    createdAt: string
    updatedAt: string
    restaurant_ids: string[]
    claims?: Claims | null
    favoriteCardId: string | null
}

export interface Stats {
    count: number
    total: number
}
export type Claims = {
    role: Role
    restaurant_ids: string[]
}

export enum Role {
    Admin = 'admin',
    Owner = 'owner',
    Employee = 'employee',
    User = 'user',
}

type CardState = {
    savedCards: StripePaymentMethod[]
    favoriteCard: StripePaymentMethod | null
    loading: boolean
}

export type UserState = {
    user?: User
    delivery?: UserDelivery
    loading: boolean
    isLoggingIn: boolean
    authError: AuthError
    addressError: AddressError
    addressLabel: string
    providerId: AuthProviderId
    userCredential: UserCredential
    cardState: CardState
}

export interface UserDelivery {
    distanceMatrix: DistanceMatrix
    zone: DeliveryZone
}

export enum UserRoutes {
    User = '/user',
    UserRedirectCheckout = '/user?mode=checkout',
    Email = '/user?mode=email',
    PhoneAuth = '/user?mode=phone',
    EmailMerge = '/user?mode=email&merge=true',
    EmailRedirectCheckout = '/user?mode=email&redirect=checkout',
    LoginError = '/user?mode=login-error',
    Register = '/user?mode=register',
    ForgotPassword = '/user?mode=forgot-password',
    ResetPassword = '/user?mode=reset-password',
    EmailVerification = '/user?mode=email-verification',
}

// login helpers
export const createUserFromFirebaseUser = (firebaseUser: FirebaseUser): User => {
    const user = {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        firstName: firebaseUser.displayName?.split(' ')[0] ?? '',
        lastName: firebaseUser.displayName?.split(' ')[1] ?? '',
        phoneNumber: firebaseUser.phoneNumber ?? '',
        photoURL: firebaseUser.photoURL ?? '',
        providerId: firebaseUser.providerData[0].providerId as AuthProviderId,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        restaurant_ids: [CONFIG.RESTAURANT_ID],
        favoriteCardId: null,
    }
    return user
}
