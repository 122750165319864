import React from 'react'
import { Grid } from '@material-ui/core'

import { FormikTextField } from '@components/common'

export const UserProfileTextFields: React.FC<{ email: string }> = ({ email }) => (
    <Grid container spacing={2}>
        <FormikTextField
            label="Prenume"
            name="firstName"
            xs={12}
            md={6}
            required
            autoComplete="given-name"
        />
        <FormikTextField
            label="Nume"
            name="lastName"
            xs={12}
            md={6}
            required
            autoComplete="family-name"
        />
        <FormikTextField
            label="Email"
            name="email"
            disabled={!!email}
            required
            autoComplete="email"
        />
        <FormikTextField
            label="Telefon"
            name="phoneNumber"
            autoComplete="tel"
            required
        />
    </Grid>
)

export default UserProfileTextFields
