import React from 'react'
import { ListItemText } from '@material-ui/core'

import { ProductVariation } from '@interfaces/restaurant'
import { formatPrice as formatPrice } from '@utils/helpers'

interface CartProductChoicesProps {
    variation: ProductVariation
    visible?: boolean
    showToppingName?: boolean
    showOptionsPrice?: boolean
}

const CartProductChoices: React.FC<CartProductChoicesProps> = ({
    variation,
    visible = true,
    showToppingName = false,
    showOptionsPrice = false,
}) => {
    if (!visible) return null

    return (
        <>
            {variation.choices.map((choice) => (
                <div key={choice.id} style={{ marginTop: 8 }}>
                    {showToppingName && <ListItemText primary={choice.name} />}
                    {choice.options.map((option) => (
                        <div key={option.id}>
                            {showOptionsPrice ? (
                                <ListItemText
                                    className="between"
                                    primaryTypographyProps={{ variant: 'body2' }}
                                    primary={
                                        showToppingName ? choice.name : option.name
                                    }
                                    secondary={formatPrice(
                                        option.price * variation.quantity,
                                    )}
                                />
                            ) : (
                                <ListItemText
                                    className="between"
                                    secondary={`+${option.name}`}
                                />
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </>
    )
}

export default CartProductChoices
