import React from 'react'
import { Container } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { SEO } from '@components/common'

const TableSkeleton = () => {
    return (
        <Container className="container p1">
            <SEO title="Se incarca" />

            <Skeleton variant="rect" width="auto" height={55} />
            {Array.from(Array(15), (_, x) => x).map((_, index) => (
                <div
                    key={index}
                    style={{
                        marginTop: 1,
                    }}
                >
                    <Skeleton variant="rect" width="auto" height={45} />
                </div>
            ))}
        </Container>
    )
}

export default TableSkeleton
