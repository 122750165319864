import React, { useState } from 'react'

import {
    CheckoutAddressItem,
    MeetingPlaceOptions,
    NeedsUstensils,
} from '@components/checkout'
import { AddressFormDialog } from '@components/user'
import { Info } from '@interfaces/info'
import { User } from '@interfaces/user'

interface Props {
    user: User
    info: Info
}

const DeliveryOptions: React.FC<Props> = ({ user, info }) => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const toggleDialog = () => setDialogOpen(!dialogOpen)

    const offersCardPayment = info.payment_methods?.includes('card') ?? false

    return (
        <>
            <CheckoutAddressItem user={user} onClick={toggleDialog} />
            <NeedsUstensils divider={offersCardPayment} />
            <MeetingPlaceOptions />

            {dialogOpen && (
                <AddressFormDialog
                    showAddressList={true}
                    open={dialogOpen}
                    onClose={toggleDialog}
                />
            )}
        </>
    )
}

export default DeliveryOptions
