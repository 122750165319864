import NextHead from 'next/head'

import { CONFIG } from '@utils/config'

interface Props {
    title?: string
    description?: string
    url?: string
    imageUrl?: string
}

export const SEO: React.FC<Props> = ({ title, description, imageUrl, url }) => (
    <NextHead>
        <title>{title ?? CONFIG.RESTAURANT_NAME}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        {imageUrl && (
            <>
                <meta property="og:image" content={imageUrl} />
                <meta property="twitter:image" content={imageUrl} />
                <meta property="twitter:summary_large_image" content={imageUrl} />
            </>
        )}
        <meta property="og:title" content={title} />
        {url && <meta property="og:url" content={url} />}
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={CONFIG.RESTAURANT_NAME} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
    </NextHead>
)

export default SEO
