import React from 'react'
import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { LocalMall } from '@material-ui/icons'

import { useCheckout } from '@context'

const PickupPackaging = () => {
    const {
        state: {
            requests: { to_go },
        },
        dispatch,
    } = useCheckout()

    const handleChangeWrapType = () => {
        dispatch({
            type: 'TO_GO',
            payload: {
                toGo: !to_go,
            },
        })
    }

    return (
        <ListItem button onClick={handleChangeWrapType}>
            <ListItemIcon>
                <LocalMall color="primary" />
            </ListItemIcon>

            <ListItemText primary="La pachet" />
            <Checkbox checked={to_go} />
        </ListItem>
    )
}

export default PickupPackaging
