import {
    createStyles,
    TableCell,
    TableRow,
    Theme,
    withStyles,
} from '@material-ui/core'

export const StyledTableCell = withStyles(() =>
    createStyles({
        head: {
            // backgroundColor: 'lightgray',
            // color: theme.palette.common.white,
            fontSize: 15,
            fontWeight: 'bolder',
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow)
