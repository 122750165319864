import React from 'react'
import { IconButton } from '@material-ui/core'
import { DeleteForever, Save } from '@material-ui/icons'

import { CustomDialog, ProgressBar } from '@components/common'
import { CustomDialogProps } from '@interfaces/dialog'

interface Props extends CustomDialogProps {
    isSubmitting: boolean
    deleteButton: boolean
    toggleDeleteDialog: () => void
    submitForm: (() => Promise<void>) & (() => Promise<any>)
}

const SaveableDialog: React.FC<Props> = (props) => {
    return (
        <CustomDialog
            {...props}
            rightAction={
                <>
                    {props.isSubmitting ? (
                        <ProgressBar size="small" />
                    ) : (
                        <IconButton
                            aria-label="submit"
                            edge="start"
                            onClick={props.submitForm}
                            color="secondary"
                            disabled={props.isSubmitting}
                        >
                            <Save />
                        </IconButton>
                    )}
                    {props.deleteButton && (
                        <IconButton
                            aria-label="show-delete"
                            edge="end"
                            onClick={props.toggleDeleteDialog}
                            disabled={props.isSubmitting}
                        >
                            <DeleteForever color="error" />
                        </IconButton>
                    )}
                </>
            }
        >
            {props.children}
        </CustomDialog>
    )
}

export default SaveableDialog
