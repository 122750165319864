import { Button, CircularProgress } from '@material-ui/core'

interface SPButtonProps {
    text: string
    type?: 'submit' | 'button'
    size?: 'large' | 'medium' | 'small'
    square?: boolean
    variant?: 'contained' | 'outlined' | 'text'

    form?: string
    disabled?: boolean
    loading?: boolean
    fullWidth?: boolean

    color?: 'primary' | 'secondary' | 'default' | 'inherit'
    onClick?: (args?: any) => void
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    minWidth?: number
    style?: React.CSSProperties
    component?: React.ElementType
}

export const SPButton: React.FC<SPButtonProps> = (props) => {
    const {
        variant = 'outlined',
        type = 'button',
        size = 'large',
        color = 'primary',
        square = false,
        fullWidth = false,
        minWidth = 85,
        text,
        loading,
        disabled,
        style,
        component = 'button',
    } = props

    return (
        <Button
            component={component}
            aria-label={text}
            type={type}
            variant={variant}
            form={props.form}
            size={size}
            color={disabled ? 'inherit' : color}
            disabled={disabled || loading}
            fullWidth={fullWidth}
            onClick={props.onClick}
            startIcon={loading ? null : props.startIcon}
            endIcon={loading ? null : props.endIcon}
            style={{
                borderRadius: square ? 0 : 4,
                minWidth,
                ...style,
            }}
        >
            {props.loading ? <CircularProgress color="inherit" size={25} /> : text}
        </Button>
    )
}

export default SPButton
