import { createContext, Dispatch, useContext, useEffect, useReducer } from 'react'
import {
    browserLocalPersistence,
    browserPopupRedirectResolver,
    EmailAuthProvider,
    FacebookAuthProvider,
    fetchSignInMethodsForEmail,
    getRedirectResult,
    GoogleAuthProvider,
    linkWithCredential,
    OAuthProvider,
    sendPasswordResetEmail,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signInWithPopup,
    signInWithRedirect,
    User as FirebaseUser,
    UserCredential,
} from 'firebase/auth'
import { LoginPayload, userReducer, UserReducerAction } from 'reducers/userReducer'

import {
    fetchSaveUsersFavoriteCardId,
    fetchUser,
    updateUserRestaurantIds,
} from '@api/client'
import {
    fetchCreatePaymentMethod,
    fetchCreateUser,
    fetchDeleteAddress,
    fetchDeletePaymentMethod,
    fetchDeleteUser,
    fetchPaymentMethods,
    fetchUpdateAddress,
    fetchUpdateUser,
} from '@api/server'
import { Address } from '@interfaces/address'
import {
    AuthError,
    AuthException,
    AuthProviderId,
    LoginMode,
} from '@interfaces/auth-error'
import { StripePaymentMethod } from '@interfaces/order'
import {
    createUserFromFirebaseUser,
    User,
    UserDelivery,
    UserState,
} from '@interfaces/user'
import { auth } from '@utils/clientFirebase'
import { CONFIG } from '@utils/config'
import { setBearerToken } from '@utils/helpers'

const initialUserDeliveryState: UserDelivery = {
    distanceMatrix: {
        distance: { text: '', value: 0 },
        duration: { text: '', value: 0 },
        zoneId: '',
    },
    zone: {
        id: '',
        active: false,
        color: '#000',
        coords: [{ lat: 0, lng: 0 }],
        delivery_fee: 0,
        free_delivery_treshold: 0,
        name: '',
        min_order: 0,
    },
}

const initialUserState: UserState = {
    user: null,
    delivery: initialUserDeliveryState,
    loading: true,
    isLoggingIn: false,
    authError: null,
    addressError: null,
    addressLabel: null,
    providerId: null,
    userCredential: null,
    cardState: {
        savedCards: [],
        favoriteCard: null,
        loading: true,
    },
}

export type UserProps = {
    state: UserState
    dispatch: Dispatch<UserReducerAction>
    register: (user: User) => Promise<void>
    update: (user: User) => Promise<void>
    login: (
        mode: LoginMode,
        providerId: AuthProviderId,
        emailCredentials?: LoginPayload,
    ) => Promise<void>
    logout: () => Promise<void>
    deleteUser: () => Promise<void>
    resetPassword: (email: string) => Promise<void>
    updateAddress: (address: Address) => Promise<void>
    deleteAddress: (addressId: string) => Promise<void>

    getSavedCards: () => Promise<void>
    createCard: (card: StripePaymentMethod) => Promise<void>
    setFavoriteCard: (id: string) => Promise<void>
    deleteCard: (id: string) => Promise<void>
}

const UserContext = createContext<UserProps>({
    state: initialUserState,
    dispatch: null,
    register: async () => {},
    update: async () => {},
    login: async () => {},
    logout: async () => {},
    deleteUser: async () => {},
    resetPassword: async () => {},
    updateAddress: async () => {},
    deleteAddress: async () => {},

    getSavedCards: async () => {},
    createCard: async () => {},
    setFavoriteCard: async () => {},
    deleteCard: async () => {},
})

export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, initialUserState)

    useEffect(() => {
        const tokenListener = auth.onIdTokenChanged(async (firUser) => {
            if (firUser) {
                const token = await firUser.getIdToken()
                setBearerToken(token)
            } else {
                setBearerToken(null)
            }
        })

        const authListener = auth.onAuthStateChanged(async (firebaseUser) => {
            try {
                firebaseUser ? await onLoginSuccess(firebaseUser) : setUser(null)
            } catch (error) {
                setAuthError(error)
            } finally {
                setLoading(false)
                setIsLoggingIn(false)
            }
        })

        return () => {
            tokenListener()
            authListener()
        }
    }, [])

    const setAuthProviderId = (providerId: AuthProviderId) => {
        dispatch({
            type: 'AUTH_PROVIDER_ID',
            payload: { providerId },
        })
    }

    const setUser = (user?: User) => {
        dispatch({
            type: 'USER',
            payload: { user },
        })
    }

    const setLoading = (loading: boolean) => {
        dispatch({
            type: 'LOADING',
            payload: { loading },
        })
    }

    const setIsLoggingIn = (isLoggingIn: boolean) => {
        dispatch({
            type: 'IS_LOGGING_IN',
            payload: { isLoggingIn },
        })

        if (!isLoggingIn) {
            setAuthProviderId(null)
        }
    }

    const setAuthError = (authError?: AuthError) => {
        dispatch({
            type: 'AUTH_ERROR',
            payload: { authError },
        })
    }

    const onLoginSuccess = async (firebaseUser: FirebaseUser) => {
        try {
            setLoading(true)
            const user = await fetchUser(firebaseUser.uid)

            if (user) {
                setUser(user)
                if (!user.restaurant_ids.includes(CONFIG.RESTAURANT_ID)) {
                    await updateUserRestaurantIds(user)
                }
            } else {
                const newUser = createUserFromFirebaseUser(firebaseUser)
                await update(newUser)
            }
            setAuthError(null)
        } catch (error) {
            setAuthError(error)
        } finally {
            setLoading(false)
            setIsLoggingIn(false)
        }
    }

    const login = async (
        mode: LoginMode,
        providerId: AuthProviderId,
        emailPayload?: LoginPayload,
    ): Promise<void> => {
        setAuthProviderId(providerId)
        setIsLoggingIn(true)
        try {
            switch (providerId) {
                case 'password':
                    await handlePasswordLogin(mode, emailPayload)
                    break
                case 'facebook.com':
                    await handleFacebookLogin(mode)
                    break
                case 'google.com':
                    await handleGoogleLogin(mode)
                    break
                default:
                    break
            }
        } catch (error) {
            throw error
        } finally {
            setIsLoggingIn(false)
        }
    }

    const logout = async (): Promise<void> => {
        try {
            await auth.signOut()
            setUser(null)
        } catch (error) {
            throw error
        }
    }

    const register = async (user: User): Promise<void> => {
        try {
            setIsLoggingIn(true)

            const [prevProviders, providerId] = await getPreviousProviderId(
                user.email,
            )

            if (prevProviders.length > 0) {
                switch (providerId) {
                    case 'password':
                        await login('login', 'password', {
                            email: user.email,
                            password: user.password,
                        })
                        break

                    default:
                        createEmailMergeError(providerId, {
                            email: user.email,
                            password: user.password,
                        })
                        break
                }
                return
            }

            const response = await fetchCreateUser(user)
            await signInWithCustomToken(auth, response.token)
        } catch (error) {
            throw error
        }
    }

    const resetPassword = async (email: string): Promise<void> => {
        try {
            await sendPasswordResetEmail(auth, email)
        } catch (error) {
            throw error
        }
    }

    const getPreviousProviderId = async (
        email: string,
    ): Promise<[AuthProviderId[], AuthProviderId]> => {
        const previousSignInMethods = (await fetchSignInMethodsForEmail(
            auth,
            email,
        )) as AuthProviderId[]
        return [previousSignInMethods, previousSignInMethods[0]]
    }

    const handlePasswordLogin = async (mode: LoginMode, payload: LoginPayload) => {
        const { email, password } = payload
        const [ids, id] = await getPreviousProviderId(email)

        try {
            const shouldMerge = ids.length > 0 && !ids.includes('password')
            if (shouldMerge) {
                createEmailMergeError(id, payload)
            } else {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    email,
                    password,
                )
                await handleMerge(mode, userCredential)
            }
        } catch (error) {
            await handleLoginError(error)
        }
    }

    const handleGoogleLogin = async (mode: LoginMode) => {
        try {
            const googleProvider = new GoogleAuthProvider()
            let userCredential: UserCredential
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
            if (isMobile) {
                await signInWithRedirect(
                    auth,
                    googleProvider,
                    browserPopupRedirectResolver,
                )
                userCredential = await getRedirectResult(
                    auth,
                    browserLocalPersistence,
                )
            } else {
                userCredential = await signInWithPopup(
                    auth,
                    googleProvider,
                    browserPopupRedirectResolver,
                )
            }

            dispatch({
                type: 'USER_CREDENTIAL',
                payload: { userCredential },
            })

            await handleMerge(mode, userCredential)
        } catch (error) {
            await handleLoginError(error)
        }
    }

    const handleFacebookLogin = async (mode: LoginMode) => {
        try {
            const facebookProvider = new FacebookAuthProvider()
            facebookProvider.addScope('public_profile')
            let userCredential: UserCredential
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
            if (isMobile) {
                await signInWithRedirect(
                    auth,
                    facebookProvider,
                    browserPopupRedirectResolver,
                )
                userCredential = await getRedirectResult(
                    auth,
                    browserLocalPersistence,
                )
            } else {
                userCredential = await signInWithPopup(
                    auth,
                    facebookProvider,
                    browserPopupRedirectResolver,
                )
            }

            dispatch({
                type: 'USER_CREDENTIAL',
                payload: { userCredential },
            })

            await handleMerge(mode, userCredential)
        } catch (error) {
            setAuthError(error)
            await handleLoginError(error)
        } finally {
            setIsLoggingIn(false)
        }
    }

    const handleMerge = async (
        mode: LoginMode,
        pendingCredential: UserCredential,
    ) => {
        if (mode === 'merge') {
            const credential = OAuthProvider.credentialFromError(state.authError)
            await linkWithCredential(
                pendingCredential.user,
                credential ?? state.authError.credential,
            )
        }
    }

    const createEmailMergeError = async (
        providerId: AuthProviderId,
        payload: LoginPayload,
    ) => {
        const { email, password } = payload
        const credential = EmailAuthProvider.credential(email, password)

        const error: AuthError = {
            ...state.authError,
            code: AuthException.Merge,
            providerId,
            credential,
            customData: {
                ...state.authError?.customData,
                ...credential,
                email,
            },
        }
        setAuthError(error)
        setIsLoggingIn(false)
    }

    const handleLoginError = async (error: AuthError): Promise<void> => {
        setIsLoggingIn(false)

        if (error.code === AuthException.Merge) {
            const [, providerId] = await getPreviousProviderId(
                error.customData.email,
            )

            setAuthProviderId(providerId)
            setAuthError({
                ...error,
                providerId,
            })
        } else if (error.code === AuthException.PopupClosedByUser) {
            setAuthError(null)
            setIsLoggingIn(false)
        } else {
            console.error('handleLoginError, unhandled error', error)
            throw error
        }
    }

    const update = async (user: User): Promise<void> => {
        try {
            await fetchUpdateUser(user)
            setUser(user)
        } catch (error) {
            throw error
        }
    }

    const deleteUser = async () => {
        try {
            await fetchDeleteUser(state.user?.uid)
            setUser(null)
        } catch (error) {
            throw error
        }
    }

    const updateAddress = async (address: Address) => {
        try {
            if (!state.user) throw Error('updateAddress, user cant be null')

            const updatedAddresses = await fetchUpdateAddress(state.user, address)
            setUser({
                ...state.user,
                addresses: updatedAddresses,
            })
        } catch (error) {
            throw error
        }
    }

    const deleteAddress = async (addressId: string) => {
        try {
            if (!state.user) throw Error('delete address, user undefined')
            if (!addressId) throw Error('deleteAddress, addressId is undefined')

            const updatedAddresses = await fetchDeleteAddress(
                state.user.uid,
                addressId,
            )

            setUser({
                ...state.user,
                addresses: updatedAddresses,
            })
        } catch (error) {
            throw error
        }
    }

    //cards
    const getSavedCards = async () => {
        if (!state.user) {
            return
        }

        try {
            dispatch({ type: 'LOADING_CARDS', payload: { loading: true } })
            const cards = await fetchPaymentMethods(state.user.customerId)

            dispatch({
                type: 'SET_CARDS',
                payload: { cards },
            })
        } catch (error) {
            console.error(error)
            throw error
        } finally {
            dispatch({ type: 'LOADING_CARDS', payload: { loading: false } })
        }
    }

    const createCard = async (card: StripePaymentMethod) => {
        try {
            if (!state.user) throw Error('createCard, user undefined')

            await Promise.all([
                fetchCreatePaymentMethod(state.user, card.id),
                fetchSaveUsersFavoriteCardId(state.user.uid, card.id),
            ])

            dispatch({
                type: 'ADD_NEW_CARD',
                payload: { card },
            })
        } catch (error) {
            throw error
        }
    }

    const setFavoriteCard = async (id: string) => {
        if (!state.user) throw Error('setFavoriteCard, user undefined')

        if (state.user.favoriteCardId === id) return

        try {
            await fetchSaveUsersFavoriteCardId(state.user.uid, id)
            dispatch({
                type: 'SET_FAVORITE_CARD_ID',
                payload: { id },
            })
        } catch (error) {
            throw error
        }
    }

    const deleteCard = async (id: string) => {
        try {
            if (!state.user) throw Error('deleteCard, user undefined')
            await fetchDeletePaymentMethod(id)

            dispatch({
                type: 'DELETE_CARD',
                payload: { id },
            })
        } catch (error) {
            throw error
        }
    }

    return (
        <UserContext.Provider
            value={{
                state,
                dispatch,
                login,
                logout,
                register,
                deleteUser,
                resetPassword,
                update,
                updateAddress,
                deleteAddress,
                createCard,
                getSavedCards,
                setFavoriteCard,
                deleteCard,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}
export default UserProvider
export const useUser = () => useContext(UserContext)
