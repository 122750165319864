import { FC } from 'react'
import { Skeleton } from '@material-ui/lab'

import { SPCard } from '@components/common'

interface Props {
    count: number
}

const ProductsCardSkeleton: FC<Props> = ({ count = 5 }) => (
    <SPCard
        title={<Skeleton variant="text" width={200} height={50} />}
        style={{ marginTop: -8 }}
    >
        {Array.from(Array(count), (_, x) => x).map((_, index) => (
            <SPCard key={index} style={{ marginTop: 8, padding: 16 }}>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={300} style={{ marginTop: 8 }} />
                <Skeleton variant="text" width={300} />
                <Skeleton variant="text" width={50} style={{ marginTop: 8 }} />
            </SPCard>
        ))}
    </SPCard>
)

export default ProductsCardSkeleton
