import { AppBar, Container, Toolbar } from '@material-ui/core'

import {
    classes,
    HeaderCartIcon,
    HeaderLoading,
    HeaderNavigation,
    HeaderUserIcon,
} from '@components/header'
import { useCart } from '@context'

const Header = () => {
    const cartContext = useCart()

    return (
        <AppBar position="sticky" className={classes.appbar}>
            <HeaderLoading />

            <Container>
                <Toolbar disableGutters className="between">
                    <div className={classes.title}>
                        <HeaderNavigation />
                    </div>

                    <div className="center">
                        <HeaderUserIcon />
                        <div className="p05-smUp" />
                        <HeaderCartIcon cart={cartContext} />
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header
