import { FC, useState } from 'react'
import { Grid } from '@material-ui/core'

import { SPButton, SPCard } from '@components/common'
import { AddressFormDialog, AddressItem } from '@components/user'
import { UserProps } from '@context'
import { Address } from '@interfaces/address'

interface Props {
    userProps: UserProps
}

const UserAddressCard: FC<Props> = ({ userProps }) => {
    const { state } = userProps

    const [open, setOpen] = useState(false)
    const toggleDialog = () => setOpen(!open)
    const [selectedAddress, setSelectedAddress] = useState<Address>(null)

    return (
        <SPCard
            style={{ padding: 0, paddingBottom: 8 }}
            title={<h2 style={{ marginLeft: 16 }}>Adresa livrare</h2>}
            action={
                <div className="p1">
                    <SPButton
                        text="Adauga"
                        variant="contained"
                        size="small"
                        color="default"
                        onClick={() => {
                            setSelectedAddress(null)
                            toggleDialog()
                        }}
                    />
                </div>
            }
        >
            <Grid container direction="row" spacing={1}>
                {state.user.addresses?.map((a) => (
                    <AddressItem
                        key={a.id}
                        address={a}
                        onEditAddressClick={(selectedAddress) => {
                            setSelectedAddress(selectedAddress)
                            toggleDialog()
                        }}
                    />
                ))}
            </Grid>

            {open && (
                <AddressFormDialog
                    title={selectedAddress ? 'Modifica adresa' : 'Adauga adresa'}
                    open={open}
                    onClose={toggleDialog}
                    address={selectedAddress}
                    showAddressList={false}
                />
            )}
        </SPCard>
    )
}

export default UserAddressCard
