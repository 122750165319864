import { useEffect, useState } from 'react'
import Link from 'next/link'
import { Container, Grid, Hidden } from '@material-ui/core'
import { ShoppingBasket } from '@material-ui/icons'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import {
    CheckoutDeliveryContainer,
    CheckoutPaymentForm,
    CheckoutSummaryContainer,
} from '@components/checkout'
import { SPButton } from '@components/common'
import { OrderDetailSkeleton, SPCardSkeleton } from '@components/skeleton'
import { UserProfileForm } from '@components/user'
import { useCart, useCheckout, useStore, useUser } from '@context'

const stripePromise = loadStripe('pk_test_aHkt9sErqQkJicktMlM3wAel00mO2X4kqb')

const CheckoutContainer = () => {
    const { cartState } = useCart()
    const { state: userState, getSavedCards } = useUser()
    const { info } = useStore()
    const { state: checkoutState, dispatch } = useCheckout()

    const [orderSuccess, setOrderSuccess] = useState(false)

    useEffect(() => {
        dispatch({
            type: 'UPDATE_ORDER',
            payload: { cart: cartState, userState },
        })
    }, [userState, cartState, checkoutState.delivery.type])

    useEffect(() => {
        getSavedCards()
    }, [])

    if (orderSuccess === true) {
        return <OrderDetailSkeleton />
    }

    if (cartState.products.length === 0) {
        return (
            <div className="container center flex-v">
                <ShoppingBasket className="secondary" style={{ fontSize: 150 }} />

                <Link href={'/'} passHref>
                    <a>
                        <SPButton variant="contained" text="Adauga produse in cos" />
                    </a>
                </Link>
            </div>
        )
    }

    return (
        <Container className="container pv1">
            <Grid container spacing={2}>
                {/* Left Pane */}
                <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <CheckoutDeliveryContainer
                                user={userState.user}
                                info={info}
                            />
                        </Grid>

                        {!userState.user.phoneNumber ||
                            (!userState.user.email && (
                                <Grid item xs={12}>
                                    <UserProfileForm title="Contul meu" />
                                </Grid>
                            ))}

                        <Hidden mdUp>
                            <Grid item xs={12} md={5}>
                                <CheckoutSummaryContainer />
                            </Grid>
                        </Hidden>

                        {cartState.products.length > 0 &&
                            (!stripePromise || userState.cardState.loading ? (
                                <SPCardSkeleton />
                            ) : (
                                <Grid item xs={12}>
                                    <Elements stripe={stripePromise}>
                                        <CheckoutPaymentForm
                                            info={info}
                                            onOrderComplete={() =>
                                                setOrderSuccess(true)
                                            }
                                        />
                                    </Elements>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>

                {/* Right Pane */}
                <Hidden smDown>
                    <Grid item xs={12} md={5}>
                        <CheckoutSummaryContainer />
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
    )
}

export default CheckoutContainer
