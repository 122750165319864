import React from 'react'
import { Dialog, DialogContent, IconButton, Typography } from '@material-ui/core'
import { AddOutlined, Close } from '@material-ui/icons'

import { Info } from '@interfaces/info'

interface Props {
    info: Info
    open: boolean
    onClose: () => void
}

const InstallPwaDialog: React.FC<Props> = ({ info, open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} scroll="paper">
            <div className="ph1">
                <div className="between">
                    <Typography variant="h2" component="h2">
                        Instaleaza
                    </Typography>

                    <IconButton aria-label="close" edge="end" onClick={onClose}>
                        <Close color="action" />
                    </IconButton>
                </div>

                <Typography color="textSecondary" style={{ marginTop: -8 }}>
                    Instaleaza {info.name} pe ecranul principal pentru o experienta
                    mai buna
                </Typography>
            </div>

            <DialogContent style={{ padding: 16 }}>
                <>
                    <div className="flex">
                        <Typography>1. Apasa</Typography>
                        <div className="mr05" />
                        <img
                            alt="share"
                            src="./icons/ios-share.png"
                            height={24}
                            width="auto"
                            style={{ marginRight: 2 }}
                        />
                    </div>

                    <div className="mt05" />
                    <div className="flex">
                        <Typography>2. Adauga pe ecranul principal</Typography>
                        <div className="mr05" />
                        <AddOutlined
                            style={{
                                border: '1.5px solid #5AA1F5',
                                borderRadius: 4,
                                color: '#5AA1F5',
                            }}
                        />
                    </div>
                </>
            </DialogContent>
        </Dialog>
    )
}

export default InstallPwaDialog
