import type { PaymentMethod } from '@stripe/stripe-js'

import { Address, DeliveryZone, DistanceMatrix } from './address'
import { Product } from './restaurant'

export type MeetingPlace = 'door' | 'outside' | 'leave'
export type DeliveryType = 'delivery' | 'pickup'
export type PaymentType = 'cash' | 'card_on_delivery' | 'new_card' | 'saved_card'
export type PriceType = 'price' | 'totalPrice'
export type StripePaymentMethod = PaymentMethod

export interface OrderDelivery {
    type: DeliveryType
    distanceMatrix: DistanceMatrix
    zone: DeliveryZone
    address?: Address | null
}

export interface Payment {
    type: PaymentType
    currency: string
    subTotal: number
    deliveryFee: number
    total: number
    paymentMethodId?: string | null
}

export interface Requests {
    to_go: boolean
    meeting_place?: MeetingPlace | null
    needs_ustensils: boolean
    special_requests: string | null
}

export interface Order {
    restaurantId: string
    id?: string
    trackingId?: string
    uid: string
    intentId: string
    name: string
    delivery?: OrderDelivery
    requests?: Requests
    products: Product[]
    payment: Payment
    status: OrderStatus
    createdAt: string
    updatedAt: string
}

export interface UserOrder {
    id: string
    restaurantId: string
    status: OrderStatus
    updatedAt: string
}

export enum OrderStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    READY = 'READY',
    ON_THE_WAY = 'ON_THE_WAY',
    DELIVERED = 'DELIVERED',
    CANCELED = 'CANCELED',
}

export const activeOrderStatus = [
    OrderStatus.PENDING,
    OrderStatus.ACCEPTED,
    OrderStatus.READY,
    OrderStatus.ON_THE_WAY,
]

export const inactiveOrderStatus = [OrderStatus.DELIVERED, OrderStatus.CANCELED]
