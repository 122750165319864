import { CSSProperties, FC, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

const cardStyles = makeStyles<Theme>((theme) =>
    createStyles({
        card: {
            padding: '1em',
            [theme.breakpoints.down('xs')]: {
                border: 'none',
                margin: '0 -1em',
            },
        },
    }),
)

interface Props {
    title: string
    detailsStyle?: CSSProperties
    defaultExpanded?: boolean
}

export const SPAccordion: FC<Props> = ({
    children,
    title,
    detailsStyle,
    defaultExpanded = false,
}) => {
    const [expanded, setExpanded] = useState(defaultExpanded)
    const styles = cardStyles()

    return (
        <Accordion
            className={styles.card}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <h2 style={{ margin: 0 }}>{title}</h2>
            </AccordionSummary>
            <AccordionDetails
                style={{ display: 'block', padding: 0, ...detailsStyle }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default SPAccordion
