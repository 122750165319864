import { useEffect, useState } from 'react'
import {
    collection,
    limit,
    onSnapshot,
    orderBy,
    query,
    where,
} from 'firebase/firestore'

import { activeOrderStatus, Order } from '@interfaces/order'
import { clientDb } from '@utils/clientFirebase'
import { CONFIG } from '@utils/config'

const useAdminActiveOrders = (docLimit = 25) => {
    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState<Order[]>([])
    const [error, setError] = useState(null)

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(
                collection(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/orders`),
                where('status', 'in', activeOrderStatus),
                orderBy('createdAt', 'desc'),
                limit(docLimit),
            ),
            (snapshot) => {
                const orders = snapshot.docs.map((doc) => doc.data()) as Order[]

                setOrders(orders)
                setLoading(false)
            },
            (error) => {
                console.error(error)
                setError(error.message)
                setLoading(false)
            },
        )

        return () => unsubscribe()
    }, [])

    return { loading, error, orders }
}

export default useAdminActiveOrders
