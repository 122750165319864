import { useEffect, useState } from 'react'
import { QueryDocumentSnapshot } from 'firebase/firestore'

import { fetchAdminOrderHistorySnapshot } from '@api/admin'
import { Order } from '@interfaces/order'

const useAdminOrderHistory = (limit = 5) => {
    const [loading, setLoading] = useState(true)
    const [paginating, setPaginating] = useState(false)
    const [orders, setOrders] = useState<Order[]>([])
    const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot>()

    const [endReached, setEndReached] = useState(false)

    const fetchMore = async () => {
        setPaginating(true)

        try {
            const [newOrders, last] = await fetchAdminOrderHistorySnapshot(
                lastDoc,
                limit,
            )
            if (!last) {
                setEndReached(true)
                return
            }

            setLastDoc(last)
            setOrders([...orders, ...newOrders])
        } catch (error) {
            console.error('error', error)
        } finally {
            setLoading(false)
            setPaginating(false)
        }
    }

    useEffect(() => {
        fetchMore()
    }, [])

    return {
        loading,
        paginating,
        orders,
        endReached,
        fetchMore,
    }
}

export default useAdminOrderHistory
