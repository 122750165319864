import React from 'react'
import { ListItemText, Typography } from '@material-ui/core'

import { User } from '@interfaces/user'

export const UserDataUI: React.FC<{ values: Partial<User> }> = ({ values }) => (
    <>
        <ListItemText primary={`${values?.firstName} ${values?.lastName}`} />
        <Typography variant="body2" color="textSecondary" gutterBottom={false}>
            {values?.email}
        </Typography>
        <Typography variant="body2" color="textSecondary">
            {values?.phoneNumber}
        </Typography>
    </>
)

export default UserDataUI
