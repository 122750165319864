import React, { useEffect } from 'react'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import axios from 'axios'

import { analyticsLogRestaurantId } from '@api/analytics'
import { SPAlert } from '@components/common'
import Footer from '@components/footer/Footer'
import { Header } from '@components/header'
import { API_BASE_URL, CONFIG } from '@utils/config'
import { pwaTrackingListeners } from '@utils/pwaEventlisteners'
import Providers from 'context/Providers'

import 'styles/global.scss'

const CookiesConsent = dynamic(
    () => import('@components/cookies-conset/CookiesConsent'),
    { ssr: false },
)

axios.defaults.baseURL = API_BASE_URL
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (!error.response) {
            throw 'Ooops, a aparut o eroare! Serverul este offline.'
        }

        if (error.response.data.response) {
            throw error.response.data.response
        }

        throw error?.response?.data
    },
)

const App = ({ Component, pageProps }: AppProps) => {
    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side')

        if (jssStyles) {
            jssStyles.parentElement?.removeChild(jssStyles)
        }

        analyticsLogRestaurantId()
    }, [])

    return (
        <>
            <Head>
                <title>Comanda mancare online | {CONFIG.RESTAURANT_NAME}</title>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
                />
            </Head>

            <Providers pageProps={pageProps}>
                <Header />
                <main>
                    <Component {...pageProps} />
                </main>
                <SPAlert />
                <Footer />
                <CookiesConsent />
            </Providers>

            <Script
                src="https://unpkg.com/pwacompat"
                strategy="lazyOnload"
                crossOrigin="anonymous"
            />
        </>
    )
}

const isBrowser = typeof window !== 'undefined'
if (isBrowser) {
    pwaTrackingListeners()
}

if (isBrowser && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
    })
}

export default App
