import React from 'react'
import { Container, Grid } from '@material-ui/core'

import SEO from '@components/common/SEO'
import SPCardSkeleton from './SPCardSkeleton'

const CheckoutSkeleton = () => (
    <Container className="container pv1">
        <SEO title="Se incarca" />
        <Grid container spacing={2}>
            {/* Left Pane */}
            <Grid item xs={12} md={7}>
                <Grid container spacing={2}>
                    <SPCardSkeleton height={180} />
                    <SPCardSkeleton height={140} />
                </Grid>
            </Grid>

            {/* Right Pane */}
            <Grid item xs={12} md={5}>
                <Grid container spacing={2}>
                    <SPCardSkeleton height={140} />
                </Grid>
            </Grid>
        </Grid>
    </Container>
)

export default CheckoutSkeleton
