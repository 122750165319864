import { useState } from 'react'
import { useRouter } from 'next/router'

import { ConfirmationDialog, SPCard, SPListItem } from '@components/common'
import { useAlert, useUser } from '@context'
import { useIsAdmin } from 'hooks'

const UserContainerRightPane = () => {
    const router = useRouter()
    const admin = useIsAdmin()
    const alert = useAlert()
    const { logout, deleteUser } = useUser()

    const [loading, setLoading] = useState(false)
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const toggleConfirmDialog = () => setConfirmDialogOpen(!confirmDialogOpen)

    const handleDeleteAccount = async () => {
        setLoading(true)
        try {
            await deleteUser()
            alert.show('success', 'Contul a fost sters')
        } catch (error) {
            alert.show('error', 'A aparut o eroare. Incearca mai tarziu.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <SPCard style={{ padding: 0 }}>
            <SPListItem
                title="Comenzile mele"
                onClick={() => router.push('/orders')}
                arrowRightIcon
            />

            <SPListItem title="Deconectare" onClick={logout} />
            <SPListItem title="Sterge contul" onClick={toggleConfirmDialog} />

            {admin.isAdmin && (
                <SPListItem
                    title="Administrare"
                    onClick={() => router.push('/admin')}
                    arrowRightIcon
                />
            )}

            <ConfirmationDialog
                title="Sterge contul"
                subtitle="Toate datele tale vor fi sterse si vor mai putea fi recuperate."
                visible={confirmDialogOpen}
                onNegativeButtonClick={toggleConfirmDialog}
                onPositiveButtonClick={handleDeleteAccount}
                loading={loading}
            />
        </SPCard>
    )
}

export default UserContainerRightPane
