import React from 'react'
import Link from 'next/link'
import { IconButton, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import { CONFIG } from '@utils/config'
import { useHeaderNav } from 'hooks'

const HeaderNavigation = () => {
    const { isHomePage, onBackPressed, routeName } = useHeaderNav()

    return (
        <Link href="/">
            <a className="center">
                {!isHomePage && (
                    <IconButton
                        onClick={onBackPressed}
                        edge="start"
                        color="primary"
                        aria-label="back"
                    >
                        <ArrowBack />
                    </IconButton>
                )}
                <Typography
                    variant="h5"
                    align="center"
                    className="one-line"
                    style={{
                        paddingLeft: 8,
                        textAlign: 'left',
                        width: '100%',
                        fontWeight: 900,
                    }}
                >
                    {isHomePage ? CONFIG.RESTAURANT_NAME : routeName()}
                </Typography>
            </a>
        </Link>
    )
}

export default HeaderNavigation
