import { useEffect, useState } from 'react'
import {
    collection,
    limit,
    onSnapshot,
    orderBy,
    query,
    where,
} from 'firebase/firestore'

import { activeOrderStatus, Order } from '@interfaces/order'
import { clientDb } from '@utils/clientFirebase'
import { CONFIG } from '@utils/config'

const useActiveOrders = (uid?: string, docLimit = 10) => {
    const [orders, setOrders] = useState<Order[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!uid) {
            setLoading(true)
            return
        }

        const unsubscribe = onSnapshot(
            query(
                collection(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/orders`),
                where('uid', '==', uid),
                where('status', 'in', activeOrderStatus),
                orderBy('createdAt', 'desc'),
                limit(docLimit),
            ),
            (snapshot) => {
                const orders = snapshot.docs.map((doc) => doc.data()) as Order[]
                setOrders(orders)
                setLoading(false)
            },
            (error) => {
                console.error(error)
            },
        )

        return () => unsubscribe()
    }, [uid])

    return {
        loading,
        orders,
    }
}
export default useActiveOrders
