import { FC, useState } from 'react'
import { Skeleton } from '@material-ui/lab'

import { Coords } from '@interfaces/address'
import { CONFIG } from '@utils/config'

interface Props {
    query?: string | null
    coords?: Coords
    zoom?: number
}

const GoogleMaps: FC<Props> = ({ query, coords, zoom = 15 }) => {
    const [loading, setLoading] = useState(true)

    const onLoad = async () => {
        setLoading(false)
    }

    const src = query
        ? `https://www.google.com/maps/embed/v1/place?key=${CONFIG.API_KEY}&q=${query}`
        : `https://maps.google.com/maps?q=${coords.lat},${coords.lng}&t=&z=${zoom}&ie=UTF8&iwloc=&output=embed`

    return (
        <div className="maps">
            {loading && <Skeleton variant="rect" width="100%" height={300} />}

            <iframe
                width="100%"
                height="600"
                src={src}
                frameBorder="0"
                loading="lazy"
                onLoad={onLoad}
            />
        </div>
    )
}

export default GoogleMaps
