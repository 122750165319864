import React from 'react'
import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { AccessibilityNew } from '@material-ui/icons'

import { useAlert, useCheckout, useStore } from '@context'

const MeetingPlaceOptions = () => {
    const {
        state: { delivery, payment, requests },
        dispatch,
    } = useCheckout()
    const { info } = useStore()
    const alert = useAlert()

    if (delivery.type === 'pickup' || !info.payment_methods.includes('card')) {
        return null
    }

    const handleChangeMeetingPlace = () => {
        if (payment.type === 'cash') {
            alert.show('error', 'Este necesara plata cu cardul')
        }

        dispatch({
            type: 'MEETING_PLACE',
            payload: {
                meetingPlace: requests.meeting_place === 'door' ? 'leave' : 'door',
            },
        })
    }

    return (
        <ListItem onClick={handleChangeMeetingPlace}>
            <ListItemIcon>
                <AccessibilityNew color="primary" />
            </ListItemIcon>

            <ListItemText
                primary="Livrare fara contact"
                secondary="Lasa comanda la usa"
            />

            <Checkbox
                checked={
                    requests.meeting_place !== 'door' && payment.type !== 'cash'
                }
                disabled={payment.type === 'cash'}
            />
        </ListItem>
    )
}

export default MeetingPlaceOptions
