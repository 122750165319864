import React from 'react'
import { ListItem, ListItemIcon, TextField } from '@material-ui/core'
import { Create } from '@material-ui/icons'

import { useCheckout } from '@context'

const DeliverySpecialInstructions = () => {
    const { dispatch, state } = useCheckout()

    const handleChangeSpecialRequests = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: 'SPECIAL_REQUESTS',
            payload: { text: e.target.value },
        })
    }

    return (
        <ListItem>
            <ListItemIcon>
                <Create color="primary" />
            </ListItemIcon>

            <TextField
                style={{ marginRight: -16 }}
                fullWidth
                variant="standard"
                placeholder="Adauga o nota pentru restaurant"
                value={state.requests.special_requests ?? ''}
                onChange={handleChangeSpecialRequests}
            />
        </ListItem>
    )
}

export default DeliverySpecialInstructions
