import React from 'react'
import { ShoppingBasket } from '@material-ui/icons'

const EmptyCart = () => {
    return (
        <div className="center pb1">
            <ShoppingBasket className="secondary" style={{ fontSize: 50 }} />
            <div className="mr1" />
            <h2>Cosul este gol</h2>
        </div>
    )
}

export default EmptyCart
