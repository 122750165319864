import React, { useState } from 'react'
import {
    CircularProgress,
    IconButton,
    ListItem,
    ListItemText,
} from '@material-ui/core'
import { Edit, Favorite, FavoriteBorder, LocationOn } from '@material-ui/icons'

import { useAlert, useUser } from '@context'
import { Address } from '@interfaces/address'

interface Props {
    address: Address
    onEditAddressClick: (selectedAddress: Address) => void
}

const AddressItem: React.FC<Props> = ({ address, onEditAddressClick }) => {
    const [loading, setLoading] = useState(false)
    const { state, updateAddress } = useUser()
    const alert = useAlert()

    const handleEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        onEditAddressClick(address)
    }

    const handleSetFavorite = async () => {
        try {
            setLoading(true)
            const currentAddress = state.user.addresses.find((a) => a.primary)
            if (currentAddress === address) return

            await updateAddress(address)
        } catch (error) {
            alert.show('error', error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="separator full-width">
            <ListItem button onClick={handleSetFavorite}>
                <div className="p05">
                    <LocationOn fontSize="small" color="inherit" />
                </div>

                <ListItemText
                    primary={address.mainText}
                    secondary={address.secondaryText}
                />

                <IconButton aria-label="edit" onClick={handleEdit}>
                    <Edit />
                </IconButton>

                <IconButton aria-label="set-favorite" onClick={handleSetFavorite}>
                    {address.primary ? (
                        <Favorite color="error" />
                    ) : loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <FavoriteBorder />
                    )}
                </IconButton>
            </ListItem>
        </div>
    )
}

export default AddressItem
