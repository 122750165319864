import { Snackbar } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { useAlert } from '@context'

export const SPAlert = () => {
    const {
        state: { open, severity, title, subtitle },
        dispatch,
    } = useAlert()

    const toggle = () => {
        dispatch({
            type: 'TOGGLE',
            payload: { open: false },
        })
    }

    if (typeof title !== 'string') {
        return null
    }

    return (
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClick={toggle}
            onClose={toggle}
        >
            <Alert variant="filled" onClose={toggle} severity={severity}>
                {subtitle ? (
                    <>
                        <AlertTitle>{title}</AlertTitle>
                        {subtitle}
                    </>
                ) : (
                    title
                )}
            </Alert>
        </Snackbar>
    )
}

export default SPAlert
