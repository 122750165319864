import React from 'react'
import { Grid, GridSize } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { SEO, SPCard } from '@components/common'

interface Props {
    height?: number
    md?: GridSize
}

const SPCardSkeleton: React.FC<Props> = ({ height = 200, md = 12 }) => {
    return (
        <Grid item xs={12} md={md}>
            <SEO title="Se incarca" />
            <SPCard title={<Skeleton variant="text" width={height} height={50} />}>
                <div className="mt1" />
                <Skeleton variant="rect" width="auto" height={height} />
            </SPCard>
        </Grid>
    )
}

export default SPCardSkeleton
