import type { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js'
import axios from 'axios'

import { Address, DeliveryZone } from '@interfaces/address'
import { Info } from '@interfaces/info'
import { Order } from '@interfaces/order'
import { GetPaymentIntent } from '@interfaces/payment'
import {
    HeroImageData,
    ImageUploadResult,
    Menu,
    Product,
    Topping,
} from '@interfaces/restaurant'
import { User, UserDelivery } from '@interfaces/user'
import { CONFIG } from '@utils/config'

// INFO
// export const fetchInfo = async (): Promise<Info> => {
//     try {
//         const res = await axios.get<Info>(`/info/${CONFIG.RESTAURANT_ID}`)
//         return res.data
//     } catch (error) {
//         console.error(error)
//         throw error
//     }
// }
export const fetchUpdateInfo = async (info: Info): Promise<void> => {
    try {
        await axios.patch(`/info/${CONFIG.RESTAURANT_ID}`, info)
    } catch (error) {
        console.error(error)
        throw error
    }
}
export const fetchUpdateDeliverzyZones = async (
    deliveryZones: DeliveryZone[],
): Promise<void> => {
    try {
        await axios.patch(
            `/info/${CONFIG.RESTAURANT_ID}/deliveryZones`,
            deliveryZones,
        )
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchUploadInfoImage = async (
    base64Img: string,
    type: 'uploadHero' | 'uploadLogo',
): Promise<ImageUploadResult> => {
    try {
        const response = await axios.post<ImageUploadResult>(
            `/info/${CONFIG.RESTAURANT_ID}/${type}`,
            { base64Img },
        )
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const getHeroImageData = async (): Promise<HeroImageData | null> => {
    try {
        const response = await axios.get<HeroImageData>(
            `/info/${CONFIG.RESTAURANT_ID}/heroImageData`,
        )
        return response.data
    } catch (error) {
        console.error(error)
        return null
    }
}

export const fetchUpdateAddress = async (
    user: User,
    address: Address,
): Promise<Address[]> => {
    try {
        const res = await axios.put<Address[]>(`/users/${user.uid}/address`, {
            user,
            address,
        })
        return res.data
    } catch (error) {
        throw error
    }
}

export const fetchUpdateUser = async (user: User): Promise<void> => {
    try {
        await axios.put(`/users/${user.uid}`, { user })
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchDeleteAddress = async (
    uid: string,
    addressId: string,
): Promise<Address[]> => {
    try {
        const response = await axios.delete<Address[]>(
            `/users/${uid}/address/${addressId}`,
        )
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

type CreateUserResponse = {
    token: string
    user: User
}
export const fetchCreateUser = async (
    user: User | Partial<User>,
): Promise<CreateUserResponse> => {
    try {
        const res = await axios.post('/users', user)
        return res.data
    } catch (error) {
        console.error(error)
    }
}

export const fetchGrantRole = async (user: Partial<User>): Promise<User> => {
    try {
        const res = await axios.post<User>('/users/claims', user)
        return res.data
    } catch (error) {
        throw error
    }
}

export const fetchUpdateRole = async (user: Partial<User>): Promise<User> => {
    try {
        const res = await axios.patch<User>(`/users/claims/${user.uid}`, user)
        return res.data
    } catch (error) {
        throw error
    }
}

export const fetchDeleteRole = async (uid: string): Promise<void> => {
    try {
        const res = await axios.delete(`/users/claims/${uid}`)
        return res.data
    } catch (error) {
        throw error
    }
}

export const fetchDeleteUser = async (uid: string): Promise<void> => {
    try {
        await axios.delete(`/users/${uid}`)
    } catch (error) {
        console.error(error)
        throw error
    }
}

//admin menu
export const addMenuCategory = async (name: string): Promise<Menu[]> => {
    try {
        const response = await axios.post<Menu[]>(`/menu/${CONFIG.RESTAURANT_ID}`, {
            name,
        })
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const updateCategoryName = async (
    categoryId: string,
    categoryName: string,
): Promise<void> => {
    try {
        await axios.patch<void>(`/menu/${CONFIG.RESTAURANT_ID}`, {
            categoryId: categoryId,
            name: categoryName,
        })
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchDeleteCategory = async (categoryId: string): Promise<void> => {
    try {
        await axios.delete(`/menu/${CONFIG.RESTAURANT_ID}?categoryId=${categoryId}`)
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchProduct = async (id: string): Promise<Product> => {
    const response = await axios.get<Product>(
        `/menu/${CONFIG.RESTAURANT_ID}/products/${id}`,
    )
    return response.data
}

export const fetchUpdateProduct = async (
    menuId: string,
    product: Product,
): Promise<Product> => {
    try {
        const response = await axios.patch<Product>(
            `/menu/${CONFIG.RESTAURANT_ID}/products`,
            {
                menuId: menuId,
                product: product,
            },
        )
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchDeleteProduct = async (
    menuId: string,
    productId: string,
): Promise<void> => {
    try {
        await axios.delete(
            `menu/${CONFIG.RESTAURANT_ID}/products?menuId=${menuId}&productId=${productId}`,
        )
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchCreateProduct = async (
    menuId: string,
    product: Product,
): Promise<Product> => {
    try {
        const response = await axios.post<Product>(
            `/menu/${CONFIG.RESTAURANT_ID}/products`,
            {
                menuId: menuId,
                product: product,
            },
        )
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchCreateTopping = async (topping: Topping): Promise<Topping> => {
    try {
        const res = await axios.post<Topping>(`/toppings/${CONFIG.RESTAURANT_ID}/`, {
            topping,
        })
        return res.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchUpdateTopping = async (topping: Topping): Promise<Topping> => {
    try {
        const res = await axios.patch<Topping>(`/toppings/${CONFIG.RESTAURANT_ID}`, {
            topping,
        })
        return res.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchDeleteTopping = async (id: string): Promise<void> => {
    try {
        await axios.delete(`/toppings/${CONFIG.RESTAURANT_ID}/${id}`)
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchUpdateToppingCategorySortId = async (
    toppings: Topping[],
): Promise<void> => {
    try {
        await axios.patch(`/toppings/${CONFIG.RESTAURANT_ID}/categorySortId`, {
            toppings,
        })
    } catch (error) {
        console.error('fetchUpdateToppingCategorySortId error:', error)
        throw error
    }
}

export const fetchUpdateOptionSortId = async (topping: Topping): Promise<void> => {
    try {
        await axios.patch(`/toppings/${CONFIG.RESTAURANT_ID}/optionsSortId`, {
            topping,
        })
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchUpdateCategorySortId = async (menus: Menu[]): Promise<void> => {
    try {
        await axios.patch(`/menu/${CONFIG.RESTAURANT_ID}/sortId`, { menus: menus })
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchUpdateProductSortId = async (menu: Menu): Promise<void> => {
    try {
        await axios.patch(`/menu/${CONFIG.RESTAURANT_ID}/products/sortId`, { menu })
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchCheckOrder = async (order: Order): Promise<Product[]> => {
    try {
        order.products.map((product) => {
            delete product.product_variations[0].toppings
        })

        const res = await axios.post<Product[]>(
            `/orders/${CONFIG.RESTAURANT_ID}/check`,
            {
                order,
            },
        )

        return res.data
    } catch (error) {
        throw error
    }
}

export const fetchCreatePaymentIntent = async (
    order: Order,
): Promise<GetPaymentIntent> => {
    try {
        const res = await axios.post<GetPaymentIntent>(
            `/payments/${CONFIG.RESTAURANT_ID}/payment_intent`,
            { order },
        )
        return res.data
    } catch (error) {
        console.error('fetchCreatePaymentIntent:', error)
        throw error
    }
}

export const fetchCreateCashPaymentIntent = async (
    order: Order,
): Promise<GetPaymentIntent> => {
    try {
        const res = await axios.post<GetPaymentIntent>(
            `/payments/${CONFIG.RESTAURANT_ID}/cash_payment_intent`,
            { order },
        )
        return res.data
    } catch (error) {
        console.error('fetchCreateCashPaymentIntent:', error)
        throw error
    }
}

export const fetchUpdatePaymentIntent = async (
    intentId: string,
    order: Order,
): Promise<GetPaymentIntent> => {
    try {
        const res = await axios.patch<GetPaymentIntent>(
            `/payments/${CONFIG.RESTAURANT_ID}/payment_intent/${intentId}`,
            { order },
        )

        return res.data
    } catch (error) {
        console.error('fetchUpdatePaymentIntent:', error)
        throw error
    }
}

export const fetchDeletePaymentIntent = async (intentId: string): Promise<void> => {
    try {
        await axios.delete(
            `/payments/${CONFIG.RESTAURANT_ID}/payment_intent/${intentId}`,
        )
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const createOrder = async (order: Order): Promise<string> => {
    try {
        order.products.forEach((product) => {
            delete product.product_variations[0].toppings
        })

        const res = await axios.post<string>(
            `/orders/${CONFIG.RESTAURANT_ID}/create`,
            {
                order,
            },
        )
        const orderId = res.data
        return orderId
    } catch (error) {
        throw error
    }
}

export const fetchUserDelivery = async (user: User): Promise<UserDelivery> => {
    try {
        const res = await axios.post<UserDelivery>(
            `/users/delivery?restaurantId=${CONFIG.RESTAURANT_ID}`,
            user,
        )

        return res.data
    } catch (error) {
        throw error
    }
}

export const fetchPaymentMethods = async (
    customerId: string,
): Promise<StripePaymentMethod[]> => {
    try {
        const res = await axios.get<StripePaymentMethod[]>(
            `/payments/${CONFIG.RESTAURANT_ID}/payment_methods/${customerId}`,
        )
        return res.data
    } catch (error) {
        throw error
    }
}

export const fetchCreatePaymentMethod = async (
    user: User,
    cardId: string,
): Promise<void> => {
    try {
        await axios.post<void>(`/payments/${CONFIG.RESTAURANT_ID}/payment_methods`, {
            user,
            cardId,
        })
    } catch (error) {
        console.error('create card failed', error)
        throw error
    }
}

export const fetchDeletePaymentMethod = async (id: string) => {
    try {
        const res = await axios.delete(
            `/payments/${CONFIG.RESTAURANT_ID}/payment_methods/${id}`,
        )
        return res.data
    } catch (error) {
        throw error
    }
}
