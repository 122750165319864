import { UserCredential } from 'firebase/auth'

import { AddressError } from '@interfaces/address-error'
import { AuthError, AuthProviderId } from '@interfaces/auth-error'
import { StripePaymentMethod } from '@interfaces/order'
import { User, UserDelivery, UserState } from '@interfaces/user'

export type LoginPayload = {
    email: string
    password: string
}

export type RegisterUserPayload = {
    firstName: string
    lastName: string
    email: string
    password: string
    phoneNumber: string
}

export type UserReducerAction =
    | { type: 'USER'; payload: { user: User } }
    | { type: 'LOADING'; payload: { loading: boolean } }
    | { type: 'IS_LOGGING_IN'; payload: { isLoggingIn: boolean } }
    | { type: 'ADDRESS_LABEL'; payload: { label: string } }
    | { type: 'USER_DELIVERY'; payload: { delivery: UserDelivery } }
    | { type: 'ADDRESS_ERROR'; payload: { addressError: AddressError } }
    | { type: 'AUTH_ERROR'; payload: { authError: AuthError } }
    | { type: 'AUTH_PROVIDER_ID'; payload: { providerId: AuthProviderId } }
    | { type: 'USER_CREDENTIAL'; payload: { userCredential: UserCredential } }
    | { type: 'SET_CARDS'; payload: { cards: StripePaymentMethod[] } }
    | { type: 'LOADING_CARDS'; payload: { loading: boolean } }
    | { type: 'ADD_NEW_CARD'; payload: { card: StripePaymentMethod } }
    | { type: 'SET_FAVORITE_CARD_ID'; payload: { id: string } }
    | { type: 'DELETE_CARD'; payload: { id: string } }

const handleSetFavoriteCard = (state: UserState, id: string): UserState => {
    const { savedCards } = state.cardState

    const favoriteCard = savedCards.find((c) => c.id === id)

    return {
        ...state,
        user: {
            ...state.user,
            favoriteCardId: id,
        },
        cardState: {
            ...state.cardState,
            favoriteCard: favoriteCard,
        },
    }
}

export const userReducer = (
    state: UserState,
    action: UserReducerAction,
): UserState => {
    switch (action.type) {
        case 'USER':
            return {
                ...state,
                user: action.payload.user,
            }

        case 'LOADING':
            return {
                ...state,
                loading: action.payload.loading,
            }

        case 'IS_LOGGING_IN':
            return {
                ...state,
                isLoggingIn: action.payload.isLoggingIn,
            }

        case 'ADDRESS_LABEL':
            return {
                ...state,
                addressLabel: action.payload.label,
            }

        case 'USER_DELIVERY':
            return {
                ...state,
                delivery: action.payload.delivery,
            }

        case 'ADDRESS_ERROR':
            return {
                ...state,
                addressError: action.payload.addressError,
            }

        case 'AUTH_ERROR':
            return {
                ...state,
                authError: action.payload.authError,
            }

        case 'AUTH_PROVIDER_ID':
            return {
                ...state,
                providerId: action.payload.providerId,
            }

        case 'USER_CREDENTIAL':
            return {
                ...state,
                userCredential: action.payload.userCredential,
            }

        case 'SET_FAVORITE_CARD_ID':
            return handleSetFavoriteCard(state, action.payload.id)

        case 'LOADING_CARDS':
            return {
                ...state,
                cardState: {
                    ...state.cardState,
                    loading: action.payload.loading,
                },
            }

        case 'SET_CARDS': {
            const { savedCards } = state.cardState

            const favoriteCard = savedCards.find(
                (c) => c.id === state.user?.favoriteCardId,
            )

            return {
                ...state,
                user: {
                    ...state.user,
                    favoriteCardId:
                        favoriteCard ?? action.payload.cards.length > 0
                            ? action.payload.cards[0].id
                            : null,
                },
                cardState: {
                    ...state.cardState,
                    savedCards: action.payload.cards,
                    favoriteCard:
                        favoriteCard ?? action.payload.cards.length > 0
                            ? action.payload.cards[0]
                            : null,
                },
            }
        }

        case 'ADD_NEW_CARD':
            return {
                ...state,
                user: {
                    ...state.user,
                    favoriteCardId: action.payload.card.id,
                },
                cardState: {
                    ...state.cardState,
                    savedCards: [...state.cardState.savedCards, action.payload.card],
                },
            }

        case 'DELETE_CARD': {
            const { user, cardState } = state

            const nextAvailableCard = cardState.savedCards
                .filter((c) => c.id !== action.payload.id)
                .find((c) => c)

            return {
                ...state,
                user: {
                    ...user,
                    favoriteCardId:
                        user.favoriteCardId === action.payload.id
                            ? nextAvailableCard?.id ?? null
                            : user.favoriteCardId,
                },
                cardState: {
                    ...cardState,
                    savedCards: cardState.savedCards.filter(
                        (c) => c.id !== action.payload.id,
                    ),
                    favoriteCard:
                        cardState.favoriteCard?.id === action.payload.id
                            ? nextAvailableCard ?? null
                            : cardState.favoriteCard,
                },
            }
        }

        default:
            return state
    }
}
