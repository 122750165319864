import React from 'react'
import { LinearProgress } from '@material-ui/core'

import { useRouterLoading } from 'hooks'

const HeaderLoading = () => {
    const loading = useRouterLoading()

    return loading ? (
        <LinearProgress />
    ) : (
        <div style={{ backgroundColor: 'white', height: 4 }} />
    )
}

export default HeaderLoading
