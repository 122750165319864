import React from 'react'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import theme from 'styles/theme'

import {
    AlertProvider,
    CartProvider,
    CheckoutProvider,
    InfoProvider,
    UserProvider,
} from '@context'

const Providers = ({ pageProps, children }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />

        <AlertProvider>
            <InfoProvider info={pageProps.info} restaurant={pageProps.restaurant}>
                <UserProvider>
                    <CartProvider>
                        <CheckoutProvider>{children}</CheckoutProvider>
                    </CartProvider>
                </UserProvider>
            </InfoProvider>
        </AlertProvider>
    </ThemeProvider>
)

export default Providers
