import { useEffect, useState } from 'react'

import { fetchOrder } from '@api/client'
import { Order } from '@interfaces/order'

const useFetchOrder = (orderId: string) => {
    const [order, setOrder] = useState<Order>(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string>(null)

    useEffect(() => {
        if (!orderId) return

        const handleFetch = async () => {
            try {
                const order = await fetchOrder(orderId)
                setOrder(order)
            } catch (error) {
                console.error('fetchOrder', error)
                setOrder(null)
                setError(error.message)
            } finally {
                setLoading(false)
            }
        }
        handleFetch()
    }, [orderId])

    return { order, error, loading }
}

export default useFetchOrder
