import React from 'react'
import { Container, Grid } from '@material-ui/core'

import SPCardSkeleton from './SPCardSkeleton'

const DashboardSkeleton = () => {
    return (
        <Container className="container pv1">
            <Grid container spacing={2}>
                <SPCardSkeleton height={80} md={3} />
                <SPCardSkeleton height={80} md={3} />
                <SPCardSkeleton height={80} md={3} />
                <SPCardSkeleton height={80} md={3} />

                <SPCardSkeleton height={400} md={12} />
            </Grid>
        </Container>
    )
}

export default DashboardSkeleton
