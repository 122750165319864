import React, { useEffect, useState } from 'react'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { updateUserValidationSchema } from 'schemas/userProfileSchema'

import { SPButton, SPCard } from '@components/common'
import { SPCardSkeleton } from '@components/skeleton'
import { UserDataUI, UserProfileTextFields } from '@components/user'
import { useAlert, useUser } from '@context'
import { User } from '@interfaces/user'

interface Props {
    title?: string
    bottomSaveButton?: boolean
    onComplete?: () => void
    cardStyle?: React.CSSProperties
}

const UserProfileForm: React.FC<Props> = ({
    title,
    bottomSaveButton = false,
    cardStyle,
    onComplete,
}) => {
    const alert = useAlert()
    const { state, update } = useUser()

    const [isEditing, setIsEditing] = useState(false)
    const toggleEditMode = () => setIsEditing(!isEditing)

    useEffect(() => {
        if (!state.user?.phoneNumber) {
            setIsEditing(true)
        }
    }, [state])

    if (state.loading) {
        return <SPCardSkeleton height={120} />
    }

    const onSubmit = async (
        values: Partial<User>,
        actions: FormikHelpers<Partial<User>>,
    ) => {
        try {
            actions.setSubmitting(true)
            const updatedUser: User = { ...state.user, ...values }
            await update(updatedUser)

            actions.resetForm({ values: values })
            actions.setSubmitting(false)
            setIsEditing(false)

            alert.show('success', 'Profilul a fost salvat.')
            onComplete && onComplete()
        } catch (error) {
            alert.show('error', error.message)
        } finally {
            actions.setSubmitting(false)
        }
    }

    return (
        <Formik<Partial<User>>
            initialValues={{
                email: state.user?.email ?? '',
                phoneNumber: state.user?.phoneNumber ?? '',
                firstName: state.user?.firstName ?? '',
                lastName: state.user?.lastName ?? '',
            }}
            validationSchema={updateUserValidationSchema}
            validateOnMount
            onSubmit={onSubmit}
            initialTouched={{
                firstName: true,
                lastName: true,
                email: true,
                phoneNumber: true,
            }}
        >
            {({
                values,
                dirty,
                isValid,
                isSubmitting,
            }: FormikProps<Partial<User>>) => {
                const shouldSave = isEditing && (dirty || !isValid)

                const saveButton = () => (
                    <SPButton
                        text={isEditing ? 'Salveaza' : 'Modifica'}
                        color={shouldSave ? 'primary' : 'default'}
                        type={shouldSave ? 'submit' : 'button'}
                        size={bottomSaveButton ? 'large' : 'small'}
                        variant="contained"
                        onClick={shouldSave ? null : toggleEditMode}
                        loading={isSubmitting}
                        fullWidth
                    />
                )

                return (
                    <Form>
                        <SPCard
                            style={cardStyle}
                            title={title}
                            action={!bottomSaveButton && saveButton()}
                        >
                            {isEditing ? (
                                <UserProfileTextFields email={values.email} />
                            ) : (
                                <UserDataUI values={values} />
                            )}

                            <br />
                            {bottomSaveButton && saveButton()}
                        </SPCard>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default UserProfileForm
