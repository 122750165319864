import { logEvent } from 'firebase/analytics'

import { Order } from '@interfaces/order'
import { Product, ProductVariation } from '@interfaces/restaurant'
import { CartState } from '@reducers'
import { analytics } from '@utils/clientFirebase'
import { CONFIG } from '@utils/config'

export const analyticsLogRestaurantId = () => {
    logEvent(analytics, 'restaurant', {
        id: CONFIG.RESTAURANT_ID,
    })
}

export const analyticsSelectItem = (product: Product) => {
    logEvent(analytics, 'select_content', {
        item_list_id: product.id,
        item_list_name: product.name,
        item: [product],
    })
}

export const analyticsItemDetailsVariation = (
    product: Product,
    variation: ProductVariation,
) => {
    logEvent(analytics, 'view_item', {
        items: [product],
        value: variation.price,
        currency: 'RON',
    })
}

// Shopping Cart
export const analyticsViewCart = (cartState: CartState) => {
    logEvent(analytics, 'view_cart', {
        currency: 'RON',
        value: cartState.total,
        items: cartState.products,
    })
}

export const analyticsAddToCart = (product: Product) => {
    logEvent(analytics, 'add_to_cart', {
        currency: 'RON',
        value: product.product_variations[0].price,
        items: [product],
    })
}

export const analyticsRemoveFromCart = (product: Product) => {
    logEvent(analytics, 'remove_from_cart', {
        currency: 'RON',
        value: product.product_variations[0].price,
        items: [product],
    })
}

//CHECKOUT
export const analyticsBeginCheckout = (cartState: CartState) => {
    logEvent(analytics, 'begin_checkout', {
        coupon: null,
        value: cartState.total,
        items: cartState.products,
        currency: 'ron',
    })
}

export const analyticsCheckoutError = (error: string) => {
    logEvent(analytics, 'checkout_error', {
        error,
    })
}

export const analyticsLogPurchase = (order: Order) => {
    logEvent(analytics, 'purchase', {
        value: order.payment.total,
        currency: 'RON',
        transaction_id: order.id,
        shipping: order.payment.deliveryFee,
        items: order.products,
        affiliation: order.restaurantId,
    })
}
