import { useState } from 'react'
import { useRouter } from 'next/router'
import { Form, Formik, FormikHelpers } from 'formik'
import { Typography } from '@material-ui/core'
import { Contactless, Euro } from '@material-ui/icons'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { object, string } from 'yup'

import { fetchCreateCashPaymentIntent, fetchCreatePaymentIntent } from '@api/server'
import { AddCardRadioButton } from '@components/checkout'
import {
    FormikFocusError,
    SPButton,
    SPCard,
    SPRadioButton,
} from '@components/common'
import { SPCardSkeleton } from '@components/skeleton'
import { AddressFormDialog, UserProfileDialog } from '@components/user'
import { useAlert, useCheckout, useUser } from '@context'
import { OrderError, OrderException } from '@interfaces/customError'
import { Info } from '@interfaces/info'
import { Order, PaymentType } from '@interfaces/order'
import { CONFIG } from '@utils/config'
import { formatPrice } from '@utils/helpers'
import CheckoutEditCardListDialog from './CheckoutEditCardListDialog'
import FavoriteCardRadioButton from './payButtons/FavoriteCardRadioButton'

interface CheckoutPaymentFormProps {
    info: Info
    onOrderComplete: () => void
}

interface CardProps {
    name: string
    error: string | null
    save: boolean
    complete: boolean
}

export interface CheckoutState {
    order: Order
    card?: CardProps
}

const CheckoutPaymentForm: React.FC<CheckoutPaymentFormProps> = ({
    info,
    onOrderComplete,
}) => {
    const stripe = useStripe()
    const elements = useElements()

    const router = useRouter()
    const { state, dispatch, sendOrder, checkOrder } = useCheckout()
    const { payment } = state
    const {
        state: { user, cardState },
        setFavoriteCard,
    } = useUser()

    const alert = useAlert()

    const [addressDialogOpen, setAddressDialogOpen] = useState(false)
    const [userDialogOpen, setUserDialogOpen] = useState(false)
    const [savedCardsDialogOpen, setSetSavedCardsDialogOpen] = useState(false)
    const toggleSavedCardsDialog = () =>
        setSetSavedCardsDialogOpen(!savedCardsDialogOpen)

    if (!info || !user) {
        return <SPCardSkeleton height={280} />
    }

    const showMinOrderError =
        state.products.length > 0 &&
        state.delivery.type == 'delivery' &&
        payment.subTotal < state.delivery.zone.min_order

    const minOrderErrorMessage = `Diferenta pana la comanda minima este de ${formatPrice(
        state.delivery?.zone?.min_order - payment.subTotal,
    )}`

    const handleChangePaymentType = (paymentType: PaymentType) => () => {
        dispatch({
            type: 'PAYMENT_TYPE',
            payload: { paymentType },
        })

        if (paymentType !== 'saved_card') {
            dispatch({
                type: 'PAYMENT_METHOD_ID',
                payload: { id: null },
            })
        }
    }

    const handleSendOrder = async (intentId: string) => {
        try {
            const orderId = await sendOrder(intentId)
            router.push(`/orders/${orderId}`)
            onOrderComplete()
        } catch (error) {
            alert.show('error', error.message)
        }
    }

    const handleCardPayment = async (values: CheckoutState) => {
        const { card } = values
        if (!stripe || !elements) {
            alert.show('error', 'Plata cu cardul nu este disponibila')
            return
        }

        const getIntent = await fetchCreatePaymentIntent(state)

        const isPayingWithSavedCard =
            payment.type === 'saved_card' && !!payment.paymentMethodId

        const { error, paymentIntent } = await stripe.confirmCardPayment(
            getIntent.client_secret,
            {
                setup_future_usage: card.save ? 'off_session' : null,
                save_payment_method: card.save,
                payment_method: isPayingWithSavedCard
                    ? payment.paymentMethodId
                    : {
                          card: elements.getElement(CardElement),
                          billing_details: {
                              name: card.name,
                              email: user.email,
                              phone: user.phoneNumber,
                          },
                          metadata: {
                              restaurantId: CONFIG.RESTAURANT_ID,
                              uid: user.uid,
                          },
                      },
            },
        )

        if (payment.type === 'new_card' && card.save) {
            await setFavoriteCard(paymentIntent.payment_method)
        }

        if (error) {
            alert.show('error', error.message)
            throw new OrderError(error.message, OrderException.Stripe)
        }

        if (paymentIntent.status === 'succeeded') {
            await handleSendOrder(getIntent.id)
        } else {
            alert.show('error', 'Plata a esuat')
        }
    }

    const handleCashPayment = async () => {
        try {
            const getIntent = await fetchCreateCashPaymentIntent(state)
            await handleSendOrder(getIntent.id)
        } catch (error) {
            alert.show('error', error.message)
        }
    }

    const onSubmit = async (
        values: CheckoutState,
        helpers: FormikHelpers<CheckoutState>,
    ) => {
        try {
            helpers.setSubmitting(true)
            await checkOrder()

            switch (payment.type) {
                case 'new_card':
                case 'saved_card':
                    await handleCardPayment(values)
                    break

                case 'cash':
                case 'card_on_delivery':
                    await handleCashPayment()
                    break
                default:
                    throw new Error('Unknown payment type')
            }
        } catch (error) {
            console.error('onSubmit', error)
            if (error.response) {
                alert.show('error', error?.response?.data?.message ?? '')
            } else {
                alert.show('error', error.message)
            }

            switch (error.error) {
                case OrderException.Address:
                    setAddressDialogOpen(true)
                    break
                case OrderException.Phone:
                    setUserDialogOpen(true)
                    break
                default:
                    break
            }
        } finally {
            helpers.setSubmitting(false)
        }
    }

    return (
        <SPCard
            title="Metoda de plata"
            subtitle={
                showMinOrderError && (
                    <Typography color="error">{minOrderErrorMessage}</Typography>
                )
            }
        >
            <Formik<CheckoutState>
                initialValues={{
                    order: state,
                    card: {
                        name: '',
                        save: true,
                        complete: false,
                        error: null,
                    },
                }}
                onSubmit={onSubmit}
                validationSchema={
                    payment.type === 'new_card'
                        ? object().shape({
                              card: object().shape({
                                  name: string().required('Numele este obligatoriu'),
                              }),
                          })
                        : null
                }
            >
                {({ isSubmitting }) => (
                    <Form>
                        {info.payment_methods.includes('cash') && (
                            <SPRadioButton
                                title={
                                    state.delivery.type === 'delivery'
                                        ? 'Cash, la livrare'
                                        : 'Cash, la ridicare'
                                }
                                icon={<Euro />}
                                checked={payment.type === 'cash'}
                                onClick={handleChangePaymentType('cash')}
                                disabled={isSubmitting}
                            />
                        )}

                        {info.payment_methods.includes('card_on_delivery') && (
                            <SPRadioButton
                                title={
                                    state.delivery.type === 'delivery'
                                        ? 'Card, la livrare'
                                        : 'Card, la ridicare'
                                }
                                icon={<Contactless />}
                                checked={payment.type === 'card_on_delivery'}
                                onClick={handleChangePaymentType('card_on_delivery')}
                                disabled={isSubmitting}
                            />
                        )}

                        {info.payment_methods.includes('card') && (
                            <>
                                <FavoriteCardRadioButton
                                    paymentMethod={cardState.favoriteCard}
                                    onEditClick={toggleSavedCardsDialog}
                                    disabled={isSubmitting}
                                />
                                <AddCardRadioButton isSubmitting={isSubmitting} />
                            </>
                        )}

                        <br />
                        <SPButton
                            text="Trimite comanda"
                            type="submit"
                            variant="contained"
                            fullWidth
                            loading={isSubmitting}
                            color={showMinOrderError ? 'default' : 'primary'}
                        />

                        <FormikFocusError />
                    </Form>
                )}
            </Formik>

            {addressDialogOpen && (
                <AddressFormDialog
                    title="Adresa de livrare"
                    showAddressList={false}
                    open={addressDialogOpen}
                    onClose={() => setAddressDialogOpen(false)}
                />
            )}

            {userDialogOpen && (
                <UserProfileDialog
                    open={userDialogOpen}
                    onClose={() => setUserDialogOpen(false)}
                />
            )}

            {savedCardsDialogOpen && (
                <CheckoutEditCardListDialog
                    open={savedCardsDialogOpen}
                    onClose={toggleSavedCardsDialog}
                />
            )}
        </SPCard>
    )
}

export default CheckoutPaymentForm
