import { useEffect, useState } from 'react'

import { fetchMenuCategory } from '@api/client'
import { Product } from '@interfaces/restaurant'

const useSimillarProducts = (product: Product) => {
    const [simillarProducts, setSimillarProducts] = useState<Product[]>([])
    const [loadingSimillarProducts, setLoadingSimillarProducts] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            if (!product) return []

            try {
                const menu = await fetchMenuCategory(product.menuId)
                setSimillarProducts(
                    menu.products
                        ?.filter((p) => p.id !== product.id)
                        ?.sort((p1, p2) => p1.sortId - p2.sortId)
                        ?.slice(0, 6),
                )
            } catch (error) {
                console.error(error)
            } finally {
                setLoadingSimillarProducts(false)
            }
        }

        if (simillarProducts?.includes(product) === false) {
            fetchData()
        }
    }, [product])

    return { simillarProducts, loadingSimillarProducts }
}
export default useSimillarProducts
