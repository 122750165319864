import { useState } from 'react'
import { ErrorMessage, Field } from 'formik'
import {
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

interface Props {
    name: string
    label: string
    autoComplete?: 'none' | string
    type?: 'text' | 'number' | 'password'
    variant?: 'outlined' | 'standard' | 'filled'
    size?: 'medium' | 'small'
    helperText?: string | null
    focused?: boolean
    required?: boolean
    multiline?: boolean
    disabled?: boolean
    min?: number
    max?: number
    maxLength?: number
    xs?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    md?: 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    startAdornmentText?: string
    endAdornmentText?: string
}

export const FormikTextField: React.FC<Props> = ({
    name,
    label,
    autoComplete = 'none',
    type = 'text',
    variant = 'outlined',
    focused = false,
    required = false,
    multiline = false,
    disabled = false,
    size = 'medium',
    xs = 12,
    md = 12,
    min = 0,
    max,
    maxLength = 200,
    helperText,
    startAdornmentText,
    endAdornmentText,
}) => {
    const [passwordVisible, setPasswordVisible] = useState(false)
    const toggleSeePassword = () => setPasswordVisible(!passwordVisible)

    return (
        <Grid item xs={xs} md={md}>
            <Field
                size={size}
                required={required}
                autoFocus={focused}
                as={TextField}
                label={label}
                name={name}
                fullWidth
                variant={variant}
                type={
                    type === 'password'
                        ? passwordVisible
                            ? 'text'
                            : 'password'
                        : type
                }
                disabled={disabled}
                InputProps={{
                    inputProps: {
                        min,
                        max,
                        step: 'any',
                        maxLength,
                        autoComplete,
                        focused: focused ? 'true' : 'false',
                    },
                    startAdornment: startAdornmentText && (
                        <InputAdornment position="start">
                            {startAdornmentText}
                        </InputAdornment>
                    ),
                    endAdornment: endAdornmentText ? (
                        <InputAdornment position="end">
                            {endAdornmentText}
                        </InputAdornment>
                    ) : type === 'password' ? (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle-visibility"
                                onClick={toggleSeePassword}
                            >
                                {!passwordVisible ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ) : null,
                }}
                minRows={multiline ? 3 : 1}
                multiline={multiline}
                helperText={
                    <ErrorMessage
                        name={name}
                        render={(msg) => (
                            <Typography color="error" component="span">
                                {helperText || msg}
                            </Typography>
                        )}
                    />
                }
            />
        </Grid>
    )
}

export default FormikTextField
