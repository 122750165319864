import { FC, useState } from 'react'
import { CircularProgress, IconButton } from '@material-ui/core'
import { DeleteForever } from '@material-ui/icons'

import { CustomDialog, SPButton } from '@components/common'
import { AddressForm, AddressItem } from '@components/user'
import { useAlert, useUser } from '@context'
import { Address } from '@interfaces/address'

interface Props {
    title?: string
    address?: Address | null
    open: boolean
    onClose: () => void
    showAddressList: boolean
}

const AddressFormDialog: FC<Props> = ({
    title = 'Adresa livrare',
    open,
    onClose,
    address,
    showAddressList = false,
}) => {
    const { state, deleteAddress } = useUser()
    const alert = useAlert()

    const [selectedAddress, setSelectedAddress] = useState<Address | null>(address)
    const [loading, setLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const toggleEditMode = () => setEditMode(!editMode)

    if (state.loading || !state.user) {
        return null
    }

    const addresses = state?.user?.addresses ?? []

    const handleAddNew = () => {
        setSelectedAddress(null)
        setEditMode(true)
    }

    const handleDelete = async () => {
        try {
            setLoading(true)
            await deleteAddress(selectedAddress.id)

            if (editMode && addresses.length > 1) {
                toggleEditMode()
            } else {
                onClose()
            }
        } catch (error) {
            alert.show('error', 'A aparut o eroare')
        } finally {
            setLoading(false)
        }
    }

    const deleteButton = selectedAddress && (
        <IconButton aria-label="delete" onClick={handleDelete}>
            {loading ? (
                <CircularProgress size={24} />
            ) : (
                <DeleteForever color="primary" />
            )}
        </IconButton>
    )

    if (showAddressList && addresses.length > 0) {
        return (
            <CustomDialog
                maxWidth="xs"
                backIcon={editMode ? 'back' : 'close'}
                onClose={editMode ? toggleEditMode : onClose}
                title={
                    editMode
                        ? selectedAddress
                            ? 'Detalii adresa'
                            : 'Adauga adresa'
                        : title
                }
                rightAction={editMode ? deleteButton : null}
                open={open}
            >
                {editMode ? (
                    <AddressForm
                        className="p1"
                        selectedAddress={selectedAddress}
                        onSuccessfullySubmitted={toggleEditMode}
                    />
                ) : (
                    <>
                        {addresses.map((a) => (
                            <AddressItem
                                key={a.id}
                                address={a}
                                onEditAddressClick={(selected) => {
                                    setSelectedAddress(selected)
                                    setEditMode(true)
                                }}
                            />
                        ))}

                        <div className="p1">
                            <SPButton
                                fullWidth
                                text="Adauga"
                                variant="contained"
                                onClick={handleAddNew}
                            />
                        </div>
                    </>
                )}
            </CustomDialog>
        )
    }

    return (
        <CustomDialog
            title={title}
            open={open}
            onClose={onClose}
            rightAction={deleteButton}
            maxWidth="xs"
        >
            <AddressForm
                selectedAddress={selectedAddress}
                onSuccessfullySubmitted={onClose}
                className="p1"
            />
        </CustomDialog>
    )
}

export default AddressFormDialog
