import React from 'react'
import Image from 'next/image'

import { Product } from '@interfaces/restaurant'
import { useBlurData } from 'hooks'
import classes from '../Product.module.scss'

interface Props {
    product: Product
    size: 'x-small' | 'small' | 'large'
}

const ProductImage: React.FC<Props> = ({ product, size }) => {
    const blurHash = useBlurData(product?.blurhash)
    if (!product.file_path) return null

    switch (size) {
        case 'x-small':
            return (
                <div
                    style={{
                        position: 'relative',
                        height: 50,
                        width: 100,
                    }}
                >
                    <Image
                        alt={product.name}
                        src={product.file_path}
                        width={50}
                        height={50}
                        layout="fill"
                        objectFit="fill"
                        placeholder="blur"
                        blurDataURL={blurHash}
                    />
                </div>
        )
        case 'small':
            return (
                <div className={classes.img__small}>
                    <Image
                        alt={product.name}
                        src={product.file_path}
                        placeholder="blur"
                        blurDataURL={blurHash}
                        layout="fill"
                        objectFit="cover"
                    />
                </div>
            )

        case 'large':
            return (
                <Image
                    alt={product.name}
                    src={product.file_path}
                    layout="responsive"
                    objectFit="cover"
                    width={100}
                    height={60}
                    placeholder="blur"
                    blurDataURL={blurHash}
                />
            )
    }
}

export default ProductImage
