import React from 'react'

import { ConfirmButtonGroup, CustomDialog } from '@components/common'

interface Props {
    title: string
    subtitle?: string
    visible: boolean
    loading?: boolean
    positiveText?: string
    negativeText?: string
    onPositiveButtonClick: () => void
    onNegativeButtonClick: () => void
}

export const ConfirmationDialog: React.FC<Props> = ({
    title,
    subtitle,
    visible,
    loading = false,
    positiveText,
    negativeText,
    onPositiveButtonClick,
    onNegativeButtonClick,
}) => {
    if (!visible) return null

    return (
        <CustomDialog
            open={visible}
            onClose={onNegativeButtonClick}
            title={title}
            disableFullScreen={true}
            maxWidth="xs"
        >
            <div className="ph05">
                <ConfirmButtonGroup
                    title={subtitle}
                    loading={loading}
                    positiveButtonText={positiveText}
                    negativeButtonText={negativeText}
                    onPositiveButtonClick={onPositiveButtonClick}
                    onNegativeButtonClick={onNegativeButtonClick}
                    // fullWidth={false}
                    // minWidth={120}
                />
            </div>
        </CustomDialog>
    )
}

export default ConfirmationDialog
