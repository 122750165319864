import { FC } from 'react'
import { FormikProps } from 'formik'
import { Grid } from '@material-ui/core'

import { FormikTextField } from '@components/common'
import { AdressAutocompleteForm } from '@components/user'
import { StoreState } from '@context'
import { Address } from '@interfaces/address'

interface Props {
    infoProps: StoreState
    formikProps: FormikProps<Address>
}

const AddressFormTextFields: FC<Props> = ({ infoProps, formikProps }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AdressAutocompleteForm
                    infoProps={infoProps}
                    formikProps={formikProps}
                />
            </Grid>

            <FormikTextField
                label="Bloc, scara, etaj, interfon, apartament"
                name="details"
                xs={12}
            />

            <FormikTextField
                label="Instructiuni livrator"
                name="instructions"
                multiline
            />
        </Grid>
    )
}

export default AddressFormTextFields
