import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useUser } from '@context'
import { UserRoutes } from '@interfaces/user'
import { CONFIG } from '@utils/config'

const useHeaderNav = () => {
    const { state } = useUser()

    const router = useRouter()
    const [isHomePage, setIsHomePage] = useState(true)

    const routeName = () => {
        switch (router.asPath) {
            case UserRoutes.User:
                return state.user ? 'Contul meu' : 'Autentificare'

            case UserRoutes.UserRedirectCheckout:
            case UserRoutes.Email:
            case UserRoutes.EmailRedirectCheckout:
            case UserRoutes.PhoneAuth:
                return 'Autentificare'

            case UserRoutes.Register:
                return 'Inregistrare'

            case UserRoutes.ForgotPassword:
                return 'Recupereaza Parola'

            case '/checkout':
                return 'Checkout'

            case '/orders':
                return 'Comenzile mele'

            case '/terms-and-conditions':
                return 'Termeni si conditii'
            case '/privacy-policy':
                return 'Politica de confidentialitate'

            case '/admin':
            case '/admin?q=orders':
                return 'Comenzi'
            case '/admin/:orderId':
                return 'Comanda'
            case '/admin?q=order-history':
                return 'Istoric comenzi'
            case '/admin?q=info':
                return 'Informatii'
            case '/admin?q=dashboard':
                return 'Dashboard'
            case '/admin?q=products':
                return 'Produse'
            case '/admin?q=toppings':
                return 'Topping'
            case '/admin?q=users':
                return 'Utilizatori'
            case '/admin?q=delivery-zones':
                return 'Zone de livrare'

            default:
                switch (router.route) {
                    case '/orders/[id]':
                        return 'Comanda mea'
                    case '/p/[id]':
                    case '/p/[menuId]/[id]':
                        return CONFIG.RESTAURANT_NAME ?? ''
                    default:
                        return ''
                }
        }
    }

    useEffect(() => {
        setIsHomePage(
            router.pathname === '/' ||
                router.route === '/p/[id]' ||
                router.pathname === null,
        )

        return () => {
            setIsHomePage(true)
        }
    }, [router.pathname])

    const onBackPressed = (e: any) => {
        e.preventDefault()

        router.back()
    }

    return { isHomePage, onBackPressed, routeName }
}
export default useHeaderNav
