import React from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import MapsHoc from 'hoc/MapsHoc'
import {
    addressValidationSchema,
    initialAddressValues,
} from 'schemas/addressValidationSchema'

import { SPButton } from '@components/common'
import { AddressFormTextFields } from '@components/user'
import { useAlert, useStore, useUser } from '@context'
import { Address } from '@interfaces/address'

interface Props {
    selectedAddress?: Address
    onSuccessfullySubmitted: () => void
    className?: string
}

export const AddressForm: React.FC<Props> = ({
    selectedAddress,
    onSuccessfullySubmitted,
    className,
}) => {
    const infoContext = useStore()
    const userContext = useUser()
    const alert = useAlert()

    const onSubmit = async (values: Address, helpers: FormikHelpers<Address>) => {
        try {
            helpers.setSubmitting(true)

            const updatedAddress: Address = {
                ...selectedAddress,
                ...values,
                label: userContext.state.addressLabel,
            }

            await userContext.updateAddress(updatedAddress)
            alert.show('success', 'Adresa a fost salvata.')
            onSuccessfullySubmitted()
        } catch (error) {
            alert.show('error', error.message)
        } finally {
            helpers.setSubmitting(false)
        }
    }

    return (
        <MapsHoc>
            <Formik<Address>
                initialValues={initialAddressValues(selectedAddress)}
                validationSchema={addressValidationSchema}
                validateOnMount
                onSubmit={onSubmit}
                initialTouched={{
                    label: true,
                }}
            >
                {(props) => (
                    <Form id="address" className={className}>
                        <AddressFormTextFields
                            infoProps={infoContext}
                            formikProps={props}
                        />

                        <br />

                        <SPButton
                            type="submit"
                            text="Salveaza"
                            loading={props.isSubmitting}
                            disabled={props.isSubmitting || props.status?.loading}
                            color={!props.isValid ? 'inherit' : 'primary'}
                            variant="contained"
                            fullWidth
                        />
                    </Form>
                )}
            </Formik>
        </MapsHoc>
    )
}

export default AddressForm
