import { RegisterUserPayload } from 'reducers/userReducer'
import * as yup from 'yup'

export const phoneRegex =
    /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/

export const loginValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Adresa de e-mail nu este valida')
        .required('Adauga adresa de e-mail'),
    password: yup.string().required('Adauga parola').min(6),
})

const userValidationSchema = {
    firstName: yup.string().min(2).max(30).required('Necesar'),
    lastName: yup.string().min(2).max(30).required('Necesar'),
    email: yup
        .string()
        .email('Adresa de e-mail nu este valida')
        .required('Adauga adresa de e-mail'),

    phoneNumber: yup
        .string()
        .matches(phoneRegex, 'Numarul de telefon nu este valid.')
        .required('Adauga numarul de telefon'),
}

const passwordValidationSchema = {
    password: yup
        .string()
        .required('Adauga parola')
        .min(6, 'Minim 6 caractere')
        .required('Adauga parola')
        .nullable(),
}

export const registerUserInitialValues = (email?: string): RegisterUserPayload => ({
    firstName: '',
    lastName: '',
    email: email ?? '',
    password: '',
    phoneNumber: '',
})

export const registerUserValidationSchema = yup
    .object()
    .shape(userValidationSchema)
    .shape(passwordValidationSchema)

const claimsValidationSchema = {
    claims: yup.object().shape({
        role: yup.string().required('Necesar'),
        restaurant_ids: yup
            .array()
            .of(yup.string())
            .min(1, 'Alege cel putin un restaurant')
            .required('Necesar'),
    }),
}

export const updateEmployeeValidationSchema = yup
    .object()
    .shape(userValidationSchema)
    .shape(claimsValidationSchema)

export const registerEmployeeValidationSchema = yup
    .object()
    .shape(userValidationSchema)
    .shape(passwordValidationSchema)
    .shape(claimsValidationSchema)

export const updateUserValidationSchema = yup.object().shape(userValidationSchema)

export const resetPasswordInitialValues = {
    password: '',
    passwordConfirmation: '',
}

export const resetPasswordValidationSchema = yup.object().shape({
    password: yup.string().min(6, 'Minim 6 caractere').required(),
    passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Parolele nu se potrivesc'),
})

// forgot password
export const forgotPasswordInitialValues = {
    email: '',
}
export const forgotPasswordValidationSchema = yup.object().shape({
    email: yup
        .string()
        .max(200)
        .email('Adresa de e-mail nu este valida')
        .required('Adauga adresa de e-mail'),
})
