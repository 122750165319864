import { createContext, useContext, useEffect, useReducer, useState } from 'react'
import {
    adminMenuReducer,
    initialAdminMenuState,
    OptionSortIdPayload,
    ProductSortIdPayload,
} from 'reducers/adminMenuReducer'

import { fetchClientRestaurant } from '@api/client'
import {
    addMenuCategory,
    fetchCreateProduct,
    fetchCreateTopping,
    fetchDeleteCategory,
    fetchDeleteProduct,
    fetchDeleteTopping,
    fetchUpdateCategorySortId,
    fetchUpdateProduct,
    fetchUpdateProductSortId,
    fetchUpdateTopping,
    updateCategoryName,
} from '@api/server'
import { Product, Restaurant, Topping } from '@interfaces/restaurant'

export type AdminMenuProps = {
    restaurant: Restaurant
    loadingMenu: boolean
    createProduct: (menuId: string, product: Product) => Promise<void>
    updateProduct: (menuId: string, product: Product) => Promise<void>
    updateProductSortId: (payload: ProductSortIdPayload) => Promise<void>

    updateOptionSortId: (payload: OptionSortIdPayload) => Promise<void>

    deleteProduct: (menuId: string, productId: string) => Promise<void>

    addCategory: (name: string) => Promise<void>
    updateCategorySortId: (
        destinationIdx: number,
        sourceIdx: number,
    ) => Promise<void>

    updateToppingCategorySortId: (
        destinationIdx: number,
        sourceIdx: number,
    ) => Promise<void>

    editCategoryName: (id: string, name: string) => Promise<void>
    deleteCategory: (id: string) => Promise<void>

    editTopping: (topping: Topping) => Promise<void>
    handleEditTopping: (topping: Topping) => void
    addTopping: (topping: Topping) => Promise<void>
    deleteTopping: (id: string) => Promise<void>
}

const AdminMenuContext = createContext<AdminMenuProps>({
    restaurant: undefined,
    loadingMenu: true,

    createProduct: async () => {},
    updateProduct: async () => {},
    updateProductSortId: async () => {},
    updateOptionSortId: async () => {},
    deleteProduct: async () => {},

    updateCategorySortId: async () => {},
    updateToppingCategorySortId: async () => {},

    addCategory: async () => {},
    editCategoryName: async () => {},
    deleteCategory: async () => {},

    handleEditTopping: () => {},
    editTopping: async () => {},
    addTopping: async () => {},
    deleteTopping: async () => {},
})

const AdminMenuProvider = ({ children }) => {
    const [loadingMenu, setLoadingMenu] = useState(true)
    const [state, dispatch] = useReducer(adminMenuReducer, initialAdminMenuState)

    useEffect(() => {
        if (state.menus.length > 0) {
            setLoadingMenu(false)
            return
        }

        const getRestaurant = async () => {
            try {
                const restaurant = await fetchClientRestaurant()
                dispatch({
                    type: 'POPULATE',
                    payload: { restaurant },
                })
            } catch (error) {
                console.error(
                    'adminMenuContext, fetchClientRestaurant, error:',
                    error,
                )
            } finally {
                setLoadingMenu(false)
            }
        }
        getRestaurant()
    }, [])

    //catgory
    const addCategory = async (name: string) => {
        const nameAlreadyExists = state.menus.some(
            (m) => m.name.toLowerCase() === name.toLowerCase(),
        )
        if (nameAlreadyExists)
            throw Error('Exista deja o categorie cu acest nume. Alege alt nume')

        try {
            const menus = await addMenuCategory(name)

            const newRestaurant: Restaurant = {
                menus: menus,
                toppings: state.toppings,
            }

            dispatch({
                type: 'POPULATE',
                payload: { restaurant: newRestaurant },
            })
        } catch (error) {
            throw error
        }
    }

    const editCategoryName = async (id: string, name: string) => {
        try {
            await updateCategoryName(id, name)
            dispatch({
                type: 'EDIT_CATEGORY_NAME',
                payload: { id, name },
            })
        } catch (error) {
            throw error
        }
    }

    const deleteCategory = async (id: string) => {
        try {
            await fetchDeleteCategory(id)
            dispatch({
                type: 'DELETE_CATEGORY',
                payload: { id },
            })
        } catch (error) {
            throw error
        }
    }

    //product
    const updateProductSortId = async (payload: ProductSortIdPayload) => {
        dispatch({
            type: 'UPDATE_PRODUCT_SORT_ID',
            payload,
        })

        const updatedMenu = state.menus.find((m) => m.id === payload.menuId)
        await fetchUpdateProductSortId(updatedMenu)
    }

    const updateCategorySortId = async (
        destinationIdx: number,
        sourceIdx: number,
    ) => {
        dispatch({
            type: 'EDIT_MENU_SORT_ID',
            payload: { destinationIdx, sourceIdx },
        })
        const updatedMenus = state.menus
        await fetchUpdateCategorySortId(updatedMenus)
    }

    const updateToppingCategorySortId = async (
        destinationIdx: number,
        sourceIdx: number,
    ) => {
        dispatch({
            type: 'EDIT_TOPPING_CATEGORY_SORT_ID',
            payload: { destinationIdx, sourceIdx },
        })

        // await fetchUpdateToppingCategorySortId(state.toppings)
    }

    const createProduct = async (menuId: string, product: Product) => {
        try {
            const newProduct = await fetchCreateProduct(menuId, product)

            dispatch({
                type: 'UPDATE_PRODUCT',
                payload: {
                    menuId: menuId,
                    product: newProduct,
                },
            })
        } catch (error) {
            console.error('updateProduct error:', error)
            throw error
        }
    }

    const updateProduct = async (menuId: string, product: Product) => {
        try {
            const updatedProduct = await fetchUpdateProduct(menuId, product)
            dispatch({
                type: 'UPDATE_PRODUCT',
                payload: {
                    menuId: menuId,
                    product: updatedProduct,
                },
            })
        } catch (error) {
            console.error('updateProduct error:', error)
            throw error
        }
    }

    const deleteProduct = async (menuId: string, productId: string) => {
        try {
            await fetchDeleteProduct(menuId, productId)
            dispatch({
                type: 'DELETE_PRODUCT',
                payload: { menuId, productId },
            })
        } catch (error) {
            throw error
        }
    }

    const handleEditTopping = (topping: Topping) => {
        dispatch({
            type: 'EDIT_TOPPING',
            payload: { topping },
        })
    }

    const addTopping = async (topping: Topping): Promise<void> => {
        try {
            const newTopping = await fetchCreateTopping(topping)
            handleEditTopping(newTopping)
        } catch (error) {
            console.error('addTopping err:', error)
            throw error
        }
    }

    const editTopping = async (topping: Topping): Promise<void> => {
        try {
            const updatedTopping = await fetchUpdateTopping(topping)
            handleEditTopping(updatedTopping)
        } catch (error) {
            console.error('editTopping error:', error)
            throw error
        }
    }

    const deleteTopping = async (id: string): Promise<void> => {
        try {
            await fetchDeleteTopping(id)

            dispatch({
                type: 'DELETE_TOPPING',
                payload: { id },
            })
        } catch (error) {
            console.error('deleteTopping error', error)
            throw error
        }
    }

    //Topping Option[]
    const updateOptionSortId = async (payload: OptionSortIdPayload) => {
        try {
            dispatch({
                type: 'UPDATE_OPTION_SORT_ID',
                payload,
            })

            // const updatedTopping = state.toppings.find(
            //     (t) => t.id === payload.toppingId,
            // )
            // await fetchUpdateOptionSortId(updatedTopping)
        } catch (error) {
            throw error
        }
    }

    return (
        <AdminMenuContext.Provider
            value={{
                restaurant: state,
                loadingMenu,

                addCategory,
                editCategoryName,
                deleteCategory,
                updateCategorySortId,
                updateToppingCategorySortId,

                createProduct,
                updateProduct,
                updateProductSortId,
                deleteProduct,
                handleEditTopping,
                addTopping,
                editTopping,
                deleteTopping,
                updateOptionSortId,
            }}
        >
            {children}
        </AdminMenuContext.Provider>
    )
}

export default AdminMenuProvider

export const useAdminMenu = () => useContext(AdminMenuContext)
