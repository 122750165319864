import { useEffect, useState } from 'react'
import { getImgFromArr } from 'array-to-image'
import { decode } from 'blurhash'

export default function useBlurData(
    blurHash?: string,
    width = 32,
    height = 32,
    punch?: number,
) {
    const randomHash = 'LEHV6nWB2yk8pyo0adR*.7kCMdnj'
    const [hash, setHash] = useState(randomHash)

    useEffect(() => {
        const hash = blurHash ?? randomHash
        const pixels = decode(hash, width, height, punch ?? 1)
        const image = getImgFromArr(pixels, width, height) // HTMLImageElement
        setHash(image.src)

        return () => {
            setHash(null)
        }
    }, [blurHash])

    return hash
}
