import React, { ReactNode } from 'react'
import {
    Card,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'

const cardStyles = makeStyles<Theme>((theme) =>
    createStyles({
        card: {
            padding: '1em',
            [theme.breakpoints.down('xs')]: {
                border: 'none',
                margin: '0 -1em',
            },
        },
    }),
)

interface SPCardProps {
    title?: string | ReactNode
    titleAlign?: 'center' | 'left' | 'right'
    subtitle?: string | ReactNode
    subtitleColor?:
        | 'initial'
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'textPrimary'
        | 'textSecondary'
        | 'error'
    variant?: 'elevation' | 'outlined'
    action?: ReactNode
    style?: React.CSSProperties
}

export const SPCard: React.FC<SPCardProps> = ({
    title,
    titleAlign,
    subtitle,
    subtitleColor = 'textSecondary',
    variant = 'outlined',
    action,
    children,
    style,
}) => {
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const styles = cardStyles()

    return (
        <Card
            className={styles.card}
            variant={smallScreen ? 'outlined' : variant}
            elevation={6}
            style={style}
        >
            <div className="spcard">
                <div className="full-width">
                    {typeof title === 'string' ? (
                        <h2 style={{ textAlign: titleAlign, marginTop: 0 }}>
                            {title}
                        </h2>
                    ) : (
                        title
                    )}

                    {subtitle &&
                        (typeof subtitle === 'string' ? (
                            <Typography
                                style={{ marginTop: -8, whiteSpace: 'pre-wrap' }}
                                variant="body1"
                                color={subtitleColor}
                            >
                                {subtitle}
                            </Typography>
                        ) : (
                            subtitle
                        ))}
                </div>

                <div>{action}</div>
            </div>

            {children}
        </Card>
    )
}

export default SPCard
