import Image from 'next/image'

interface IconImageProps {
    src: string
    width?: number
    height?: number
    backgroundColor?: string
}

const IconImage: React.FC<IconImageProps> = ({ src, width = 24, height = 24 }) => (
    <Image
        alt={src}
        src={src}
        width={width}
        height={height}
        objectFit="contain"
        className="white"
    />
)

export default IconImage
