import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Restaurant } from '@material-ui/icons'

import { useCheckout } from '@context'

const NeedsUstensils = ({ divider = false }) => {
    const {
        state: {
            requests: { needs_ustensils },
        },
        dispatch,
    } = useCheckout()

    const handleChangeNeedsUstensils = () => {
        dispatch({
            type: 'NEEDS_USTENSILS',
            payload: {
                needsUstensils: !needs_ustensils,
            },
        })
    }

    return (
        <ListItem divider={divider} button onClick={handleChangeNeedsUstensils}>
            <ListItemIcon>
                <Restaurant color="primary" />
            </ListItemIcon>

            <ListItemText
                primary="Doresc tacamuri"
                secondary="* daca sunt disponibile"
            />
            <Checkbox checked={needs_ustensils} />
        </ListItem>
    )
}

export default NeedsUstensils
