import { Typography } from '@material-ui/core'

import { SPButton } from '@components/common'
import CustomDialog from './CustomDialog'

interface Props {
    open: boolean
    onClose: () => void
    title: string
    subtitle?: string
    loading?: boolean
    onPositiveButtonPressed: () => void
}
export const DeleteDialog: React.FC<Props> = ({
    open,
    onClose,
    title,
    subtitle,
    loading = false,
    onPositiveButtonPressed,
}) => {
    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            title={title}
            disableFullScreen={true}
        >
            {subtitle && (
                <div className="p1">
                    <Typography>{subtitle}</Typography>
                </div>
            )}

            <div className="flex p1">
                <SPButton
                    text="Nu"
                    variant="outlined"
                    onClick={onClose}
                    size="medium"
                    disabled={loading}
                    fullWidth
                />

                <SPButton
                    text="Da"
                    size="medium"
                    onClick={onPositiveButtonPressed}
                    loading={loading}
                    variant="contained"
                    fullWidth
                />
            </div>
        </CustomDialog>
    )
}

export default DeleteDialog
