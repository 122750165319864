import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

interface Props {
    count?: number
}

const RadioButtonSkeleton: React.FC<Props> = ({ count = 1 }) => (
    <div className="ph1">
        {Array.from(Array(count), (_, x) => x).map((_, index) => (
            <div key={index} style={{ marginTop: count === 1 ? 0 : 4 }}>
                <Skeleton variant="rect" width="auto" height={50} />
            </div>
        ))}
    </div>
)

export default RadioButtonSkeleton
