import React from 'react'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import * as yup from 'yup'

import { FormikTextField, SPButton } from '@components/common'
import { useAlert, useUser } from '@context'

interface Props {
    onSuccess: () => void
}

const AddPaymentMethodForm: React.FC<Props> = ({ onSuccess }) => {
    const alert = useAlert()
    const stripe = useStripe()
    const elements = useElements()
    const userContext = useUser()

    const onSubmit = async (
        values: FormikValues,
        actions: FormikHelpers<FormikValues>,
    ) => {
        try {
            actions.setSubmitting(true)

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    name: values.name,
                },
            })

            if (error) {
                console.error(error)
                alert.show('error', error.message)
                throw new Error('A aparut o eroare la salvarea cardului')
            }

            await userContext.createCard(paymentMethod)
            onSuccess()
        } catch (error) {
            console.error(error)
        } finally {
            actions.setSubmitting(false)
        }
    }

    return (
        <Formik<FormikValues>
            onSubmit={onSubmit}
            validationSchema={yup.object().shape({
                name: yup.string().required('Numele titularului este obligatoriu'),
            })}
            initialValues={{
                name: '',
            }}
        >
            {({ isSubmitting }) => (
                <Form className="p1">
                    <FormikTextField
                        name="name"
                        label="Numele titularului"
                        size="medium"
                    />

                    <div
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '1em',
                            margin: '0.5em 0 1em 0',
                        }}
                    >
                        <CardElement
                            options={{
                                hidePostalCode: true,
                            }}
                        />
                    </div>

                    <SPButton
                        type="submit"
                        text="Salveaza"
                        fullWidth
                        variant="contained"
                        loading={isSubmitting}
                    />
                </Form>
            )}
        </Formik>
    )
}

export default AddPaymentMethodForm
