import React from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'

import { DeliveryOptions, PickupOptions } from '@components/checkout'
import { ProgressBar, SPCard } from '@components/common'
import { DeliveryTypeSelector } from '@components/segmentedControl'
import { useCheckout } from '@context'
import { Info } from '@interfaces/info'
import { User } from '@interfaces/user'

interface Props {
    user: User
    info: Info
}

const CheckoutDeliveryContainer: React.FC<Props> = ({ user, info }) => {
    const {
        state: { delivery },
    } = useCheckout()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

    if (!info) {
        return <ProgressBar />
    }

    const offersDelivery = info?.delivery.types.includes('delivery')
    const offersPickup = info?.delivery.types.includes('pickup')

    return (
        <SPCard
            title={
                !isMobile ? (
                    'Metoda de livrare'
                ) : (
                    <DeliveryTypeSelector showSubtitle />
                )
            }
            action={!isMobile && <DeliveryTypeSelector showSubtitle />}
        >
            <br />
            {delivery.type === 'delivery' ? (
                offersDelivery ? (
                    <DeliveryOptions user={user} info={info} />
                ) : null
            ) : offersPickup ? (
                <PickupOptions restaurantAddress={info.address} />
            ) : null}
        </SPCard>
    )
}

export default CheckoutDeliveryContainer
