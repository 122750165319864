import { useState } from 'react'

const useAcceptedCookies = () => {
    const COOKIE_POLICIY = 'cookie_policy'
    const [accepted, setAccepted] = useState(!!localStorage.getItem(COOKIE_POLICIY))

    const handleAccept = () => {
        localStorage.setItem(COOKIE_POLICIY, 'true')
        setAccepted(true)
    }

    return {
        accepted,
        handleAccept,
    }
}
export default useAcceptedCookies
