import { FC, useState } from 'react'
import { ErrorMessage, Field, FormikProps } from 'formik'
import { IconButton, ListItemText, TextField, Typography } from '@material-ui/core'
import { MyLocation } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import usePlacesAutocomplete, { Suggestion } from 'use-places-autocomplete'

import { ProgressBar } from '@components/common'
import { StoreState, useAlert, useUser } from '@context'
import { Address } from '@interfaces/address'
import { AddressError, AddressException } from '@interfaces/address-error'
import {
    fetchDeviceCoords,
    fetchDistanceMatrix,
    findGoogleDeliveryZone,
    geocodeCoords,
    geocodeSuggestion,
    makeAddress,
} from './addressHelpers'

interface Props {
    infoProps: StoreState
    formikProps: FormikProps<Address>
}

const AdressAutocompleteForm: FC<Props> = ({ infoProps, formikProps }) => {
    const alert = useAlert()
    const [loading, setLoading] = useState(false)

    const { values, setValues } = formikProps

    const { dispatch } = useUser()

    const { info } = infoProps
    const restaurantAddress = info.address
    const hq = {
        lat: restaurantAddress.coords.lat,
        lng: restaurantAddress.coords.lng,
    }

    const defaultBounds = {
        // 0.01 === ~10km
        north: hq.lat + 0.02,
        south: hq.lat - 0.02,
        east: hq.lng + 0.02,
        west: hq.lng - 0.02,
    }

    const { ready, value, suggestions, setValue } = usePlacesAutocomplete({
        requestOptions: {
            bounds: defaultBounds,
            location: new google.maps.LatLng(info.address.coords),
            componentRestrictions: {
                country: 'ro',
            },
        },
        debounce: 500,
    })

    const handleSetLoading = (loading: boolean) => {
        formikProps.setStatus({ loading })
        setLoading(loading)
    }

    const setAddress = (address: Address) => {
        setValues(address, true)
        dispatch({
            type: 'ADDRESS_LABEL',
            payload: {
                label: address.label,
            },
        })
    }

    const validateStreetNumber = (geocode: google.maps.GeocoderResult) => {
        const isValidStreetNumber = geocode.address_components.some((c) =>
            c.types.includes('street_number'),
        )

        if (!isValidStreetNumber) {
            throw new AddressError(
                'Adresa nu contine numarul străzii.',
                AddressException.StreetNumber,
            )
        }
    }

    const onGetUserLocationClick = async () => {
        handleSetLoading(true)

        try {
            const coords = await fetchDeviceCoords()
            const deliveryZone = findGoogleDeliveryZone(info.delivery.zones, coords)

            const [geocode, matrix] = await Promise.all([
                geocodeCoords(coords),
                fetchDistanceMatrix(info.address.coords, coords),
            ])

            validateStreetNumber(geocode)

            const address = makeAddress(geocode, matrix, deliveryZone.id)
            setAddress(address)
        } catch (error) {
            if (error instanceof AddressError) {
                formikProps.setErrors({ label: error.message })
                alert.show('error', error.message)
            }
        } finally {
            handleSetLoading(false)
        }
    }

    const onPlaceClick = (suggestion: Suggestion) => async () => {
        try {
            const geocode = await geocodeSuggestion(suggestion)
            validateStreetNumber(geocode)

            const coords = geocode.geometry.location.toJSON()
            const deliveryZone = findGoogleDeliveryZone(info.delivery.zones, coords)

            const matrix = await fetchDistanceMatrix(info.address.coords, coords)
            const address = makeAddress(geocode, matrix, deliveryZone.id)

            setAddress(address)
        } catch (error) {
            if (error instanceof AddressError) {
                formikProps.setErrors({ label: error.message })
                alert.show('error', error.message)
            } else {
                formikProps.setErrors({ label: error })
                alert.show('error', error)
            }
        }
    }

    return (
        <Autocomplete
            freeSolo={true}
            value={values.label ?? value}
            onInputChange={(_, value) => setValue(value)}
            options={suggestions.data}
            loading={suggestions.loading}
            autoSelect
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            renderInput={(params) => (
                <Field
                    {...params}
                    as={TextField}
                    required
                    autoFocus={ready}
                    name="label"
                    label="Strada si nr"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: params.InputProps.endAdornment,
                        focused: ready,
                        startAdornment: (
                            <IconButton
                                aria-label="get-location"
                                onClick={onGetUserLocationClick}
                            >
                                {loading ? (
                                    <ProgressBar size="small" color="primary" />
                                ) : (
                                    <MyLocation />
                                )}
                            </IconButton>
                        ),
                    }}
                    helperText={
                        <ErrorMessage
                            name="label"
                            render={(msg) => (
                                <Typography color="error" component="span">
                                    {msg}
                                </Typography>
                            )}
                        />
                    }
                />
            )}
            renderOption={(option) => {
                const {
                    structured_formatting: {
                        main_text,
                        secondary_text,
                        main_text_matched_substrings,
                    },
                } = option

                const parts = parse(
                    main_text,
                    main_text_matched_substrings.map((match: any) => [
                        match.offset,
                        match.offset + match.length,
                    ]),
                )

                const primaryText = parts.map((part, index) => (
                    <span
                        key={index}
                        style={{
                            fontWeight: part.highlight ? 700 : 400,
                        }}
                    >
                        {part.text}
                    </span>
                ))

                return (
                    <ListItemText
                        onClick={onPlaceClick(option)}
                        primary={primaryText}
                        secondary={secondary_text}
                    />
                )
            }}
        />
    )
}

export default AdressAutocompleteForm
