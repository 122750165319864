import { createContext, useContext } from 'react'

import { Info } from '@interfaces/info'
import { Restaurant } from '@interfaces/restaurant'

export interface StoreState {
    restaurant: Restaurant
    info: Info
    offersDelivery: boolean
    offersPickup: boolean
}

const initialState: StoreState = {
    restaurant: null,
    info: null,
    offersDelivery: false,
    offersPickup: false,
}

const useInfo = (info: Info, restaurant: Restaurant): StoreState => {
    return {
        info,
        restaurant,
        offersDelivery: !info
            ? false
            : info.delivery?.zones.length > 0 &&
              info.delivery?.types.includes('delivery'),
        offersPickup: !info ? false : info.delivery?.types.includes('pickup'),
    }
}

const StoreContext = createContext<ReturnType<typeof useInfo>>(initialState)

export const InfoProvider = ({ info, restaurant, children }) => {
    return (
        <StoreContext.Provider value={useInfo(info, restaurant)}>
            {children}
        </StoreContext.Provider>
    )
}
export default InfoProvider

export const useStore = () => useContext(StoreContext)
