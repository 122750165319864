import { Typography } from '@material-ui/core'

interface Props {
    title: string
    top: string | number
}

const StickyHeader: React.FC<Props> = ({ title, top }) => {
    return (
        <div
            className="sticky-header bg"
            style={{
                margin: '0 -0.5em',
                padding: 10,
                zIndex: 9,
                top,
            }}
        >
            <Typography
                variant="button"
                style={{ fontWeight: 'bold', marginLeft: '1em' }}
            >
                {title}
            </Typography>
        </div>
    )
}

export default StickyHeader
