import { useEffect, useState } from 'react'

import { Claims, Role } from '@interfaces/user'
import { auth } from '@utils/clientFirebase'
import { CONFIG } from '@utils/config'
import { setBearerToken } from '@utils/helpers'

const useIsAdmin = () => {
    const [loading, setLoading] = useState(true)
    const [isAdmin, setIsAdmin] = useState(false)
    const [claims, setClaims] = useState<Claims>(null)

    const fetchClaims = async () => {
        try {
            const res = await auth.currentUser.getIdTokenResult()
            const claims = res.claims.claims as Claims
            setClaims(claims)

            const isAdmin = claims.role === Role.Admin
            const isOwner =
                claims.role === Role.Owner &&
                claims.restaurant_ids.includes(CONFIG.RESTAURANT_ID)

            if (isAdmin || isOwner) {
                setBearerToken(res.token)
                setIsAdmin(true)
            }
        } catch (error) {
            setIsAdmin(false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (firUser) => {
            if (firUser) {
                await fetchClaims()
            } else {
                setIsAdmin(false)
                setLoading(false)
            }
        })

        return () => unsubscribe()
    }, [])

    return { isAdmin, claims, loading }
}
export default useIsAdmin
