import * as yup from 'yup'

import { Address } from '@interfaces/address'
import { CONFIG } from '@utils/config'

export const initialAddressValues = (address?: Address): Address => ({
    label: address?.label ?? '',
    placeId: address?.placeId ?? '',
    coords: address?.coords ?? null,
    mainText: address?.mainText ?? '',
    secondaryText: address?.secondaryText ?? '',
    // delivery_info_list: address?.delivery_info_list ?? [],
    distanceMatrix: { [CONFIG.RESTAURANT_ID]: null },
    country: address?.country ?? '',
    city: address?.city ?? '',
    county: address?.county ?? '',
    locality: address?.locality ?? '',
    street: address?.street ?? '',
    streetNumber: address?.streetNumber ?? '',

    postalCode: address?.postalCode ?? '',
    details: address?.details ?? '',
    instructions: address?.instructions ?? '',
})

export const addressValidationSchema = yup.object().shape({
    label: yup.string().min(2).max(300).required('Adauga strada si numarul'),
    streetNumber: yup.string().required('Adauga numarul strazii'),
    coords: yup
        .object()
        .shape({
            lat: yup.number().min(-90).max(90).required('Adauga strada si numarul'),
            lng: yup
                .number()
                .min(-180)
                .max(180)
                .required('Adauga strada si numarul'),
        })
        .nullable()
        .required(),

    placeId: yup.string().nullable(),
    details: yup.string().nullable(),
    instructions: yup.string().nullable(),
})
