import React, { useEffect } from 'react'

import { SegmentedControlContainer } from '@components/segmentedControl'
import { useCheckout, useStore, useUser } from '@context'
import { AddressError } from '@interfaces/address-error'
import { DeliveryType } from '@interfaces/order'
import { findUserDelivery } from '../user/address/addressHelpers'

interface Props {
    showSubtitle?: boolean
    paddingTop?: boolean
}

const DeliveryTypeSelector: React.FC<Props> = ({ paddingTop = false }) => {
    const {
        state: { delivery },
        dispatch: dispatchCheckout,
    } = useCheckout()

    const { info, offersDelivery, offersPickup } = useStore()

    const {
        state: { user },
        dispatch: dispatchUser,
    } = useUser()

    const handleChangeDeliveryType = (deliveryType: DeliveryType) => {
        dispatchCheckout({ type: 'DELIVERY_TYPE', payload: { deliveryType } })
    }

    const setAddressError = (addressError: AddressError) => {
        dispatchUser({ type: 'ADDRESS_ERROR', payload: { addressError } })
    }

    useEffect(() => {
        if (!offersDelivery) {
            dispatchCheckout({
                type: 'DELIVERY_TYPE',
                payload: { deliveryType: 'pickup' },
            })
            return
        }

        if (delivery.type === 'pickup') {
            setAddressError(null)
            return
        }

        try {
            const delivery = findUserDelivery(info, user.addresses)
            dispatchUser({ type: 'USER_DELIVERY', payload: { delivery } })
            setAddressError(null)
        } catch (error) {
            setAddressError(error)
        }
    }, [info, user, delivery.type])

    return (
        <div className={paddingTop ? 'white pv1' : 'white'}>
            <div className="container-lg">
                <SegmentedControlContainer
                    color="primary"
                    value={delivery.type}
                    onChange={handleChangeDeliveryType}
                    options={[
                        {
                            title: 'Livrare',
                            disabled: !offersDelivery,
                            value: 'delivery',
                        },
                        {
                            title: 'Ridicare',
                            value: 'pickup',
                            disabled: !offersPickup,
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default DeliveryTypeSelector
