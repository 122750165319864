import React, { useEffect, useState } from 'react'
import { IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import localforage from 'localforage'

import { useStore } from '@context'
import { showIosPwaPrompt } from '@utils/helpers'
import InstallPwaDialog from './InstallPwaDialog'

// const ONE_DAY = 1000 * 60 * 60 * 24

const InstallPwaBannerIos = () => {
    const { info } = useStore()

    const [showBanner, setShowBanner] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const toggleDialog = () => setDialogOpen(!dialogOpen)

    useEffect(() => {
        checkBannerState()
    }, [])

    const checkBannerState = async () => {
        if (!showIosPwaPrompt()) return
        setShowBanner(true)
        // const today = new Date()
        // const lastPrompt = (await localforage.getItem('lastPrompt')) as Date
        // const timeSinceLastPrompt = today.getTime() - lastPrompt.getTime()

        // if (!lastPrompt || timeSinceLastPrompt > ONE_DAY * 1) {
        //     setShowBanner(true)
        // }
    }

    const handleCloseBanner = () => {
        localforage.setItem('lastPrompt', new Date())
        setShowBanner(false)
    }

    if (!showBanner) return null

    return (
        <div>
            <div
                onClick={toggleDialog}
                style={{
                    backgroundColor: '#fff',
                    cursor: 'pointer',
                    borderBottom: '1px solid #eee',
                }}
            >
                <div className="between">
                    <div className="center p05">
                        <img
                            src={info.images.logoUrl}
                            alt="share-icon"
                            height={40}
                            width={40}
                            className="rounded-corners"
                            style={{ marginRight: 10 }}
                        />
                        <div>
                            <Typography style={{ textAlign: 'left' }}>
                                <b>{info.name}</b>
                            </Typography>
                            <Typography color="textSecondary">
                                Adauga pe Ecranul Principal
                            </Typography>
                        </div>
                    </div>
                    <IconButton aria-label="close" onClick={handleCloseBanner}>
                        <Close />
                    </IconButton>
                </div>
            </div>

            <InstallPwaDialog info={info} open={dialogOpen} onClose={toggleDialog} />
        </div>
    )
}

export default InstallPwaBannerIos
