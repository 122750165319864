import React from 'react'

import { CustomDialog } from '@components/common'
import { AddPaymentMethodForm } from '@components/user'

interface Props {
    open: boolean
    onClose: () => void
}

const UserAddPaymentMethodDialog: React.FC<Props> = ({ open, onClose }) => {
    return (
        <CustomDialog
            maxWidth="xs"
            title={'Adauga card nou'}
            open={open}
            onClose={onClose}
        >
            <AddPaymentMethodForm onSuccess={onClose} />
        </CustomDialog>
    )
}

export default UserAddPaymentMethodDialog
