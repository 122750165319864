import React, { useEffect, useState } from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import { capitalize } from '@material-ui/core/utils'
import cn from 'classnames'

import { classes } from '@components/segmentedControl'

const useStyles = makeStyles(() => ({
    button: {
        '&:hover': {
            backgroundColor: 'transparent',
        },

        color: 'black',
        zIndex: 2,
        marginRight: 6,
        marginLeft: -2,
    },

    buttonText: {
        textAlign: 'center',
        whiteSpace: 'pre-line',
        lineHeight: 1,
        textTransform: 'none',
        fontWeight: 700,
    },
}))

interface Props {
    buttonProps?: {}
    color: 'primary' | 'secondary'
    options: {
        title: string
        subtitle?: string
        value: string | number
        disabled?: boolean
    }[]
    onChange: (value: string | number) => void
    value: string | number
}

const SegmentedControlContainer: React.FC<Props> = ({
    buttonProps,
    color,
    onChange,
    options,
    value,
}) => {
    const styles = useStyles()

    const [selectorStyle, setSelectorStyle] = useState({})
    const [windowWidth, setWindowWidth] = useState({})
    const buttonRefs = []
    options.map(() => buttonRefs.push(React.createRef()))

    useEffect(() => {
        const index = options.findIndex((option) => option.value === value)
        if (index !== -1) {
            const button = buttonRefs[index].current
            const width = Math.round(button.getBoundingClientRect().width) + 1
            const left = Math.round(
                button.getBoundingClientRect().x -
                    button.parentNode.getBoundingClientRect().x -
                    1,
            )

            setSelectorStyle({
                width,
                left: left,
            })
        }
    }, [value, windowWidth])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })

    const selectorColorClass = `selector${capitalize(color)}`

    return (
        <div className={classes.container}>
            {options.map((opt, index) => {
                return (
                    <Button
                        key={opt.value}
                        ref={buttonRefs[index]}
                        variant="text"
                        size="small"
                        className={styles.button}
                        fullWidth
                        disableElevation
                        disableFocusRipple
                        disableTouchRipple
                        disableRipple
                        disabled={opt.disabled}
                        onClick={() => onChange(opt.value)}
                        {...buttonProps}
                    >
                        <Typography className={styles.buttonText}>
                            {opt.title}
                            {'\n'}
                            {opt.subtitle && (
                                <span style={{ fontWeight: 500, color: 'GrayText' }}>
                                    {opt.subtitle}
                                </span>
                            )}
                        </Typography>
                    </Button>
                )
            })}
            {value && (
                <div
                    className={cn(classes.selector, classes[selectorColorClass])}
                    style={selectorStyle}
                />
            )}
        </div>
    )
}

export default SegmentedControlContainer
