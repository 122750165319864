import React from 'react'
import { Container } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const MenuItemSkeleton = () => (
    <div className="mt1">
        <Skeleton variant="rect" width="auto" height={120} />
    </div>
)

const AdminMenuSkeleton = () => (
    <Container maxWidth="md" className="container pv1">
        <Skeleton variant="rect" width={120} height={40} />
        <div style={{ marginTop: 24 }} />
        <div className="between">
            <Skeleton variant="rect" width={250} height={40} />

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Skeleton variant="circle" height={24} width={24} />
                <div className="ph05" />
                <Skeleton variant="circle" height={24} width={24} />
                <div className="ph05" />
                <Skeleton variant="circle" height={24} width={24} />
                <div className="ph05" />
                <Skeleton variant="circle" height={34} width={34} />
            </div>
        </div>

        {[...Array(5)].map((_, idx) => (
            <MenuItemSkeleton key={idx} />
        ))}
    </Container>
)

export default AdminMenuSkeleton
