import React from 'react'
import { Container, Grid } from '@material-ui/core'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { SEO } from '@components/common'
import {
    UserAddressCard,
    UserContainerRightPane,
    UserPaymentMethods,
    UserProfileForm,
} from '@components/user'
import { UserProps, useStore } from '@context'

interface Props {
    userProps: UserProps
}
const stripePromise = loadStripe('pk_test_aHkt9sErqQkJicktMlM3wAel00mO2X4kqb')

const UserProfileContainer: React.FC<Props> = ({ userProps }) => {
    const { offersDelivery, info } = useStore()

    return (
        <Container className="mt1 container">
            <SEO title="Contul meu" />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <UserProfileForm title="Date personale" />
                        </Grid>

                        {offersDelivery && (
                            <Grid item xs={12}>
                                <UserAddressCard userProps={userProps} />
                            </Grid>
                        )}

                        {info.payment_methods.includes('card') && (
                            <Grid item xs={12}>
                                <Elements stripe={stripePromise}>
                                    <UserPaymentMethods userProps={userProps} />
                                </Elements>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <UserContainerRightPane />
                </Grid>
            </Grid>
            <br />
        </Container>
    )
}

export default UserProfileContainer
