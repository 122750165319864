import React from 'react'

import { Order } from '@interfaces/order'
import { formatPrice } from '@utils/helpers'
import classes from './SpendMoreForFreeDelivery.module.scss'

interface Props {
    freeDeliverySum: number
    order: Order
}

const SpendMoreForFreeDelivery: React.FC<Props> = ({ order, freeDeliverySum }) => {
    if (!order || !order.products) return null

    const freeDeliveryTreshold = order.delivery?.zone?.free_delivery_treshold ?? 0
    const visible =
        order.delivery.type === 'delivery' &&
        order.payment.deliveryFee !== 0 &&
        order.payment.subTotal < freeDeliveryTreshold &&
        order.products.length > 0

    if (!visible) {
        return null
    }

    return (
        <p className={classes.container}>
            {`Cheltuie inca ${formatPrice(
                freeDeliverySum - order.payment.subTotal,
            )} pentru livrare gratis`}
        </p>
    )
}

export default SpendMoreForFreeDelivery
