import React from 'react'
import { Box, Hidden } from '@material-ui/core'

import { analyticsViewCart } from '@api/analytics'
import { Cart } from '@components/cart'
import { CheckoutButton } from '@components/checkout'
import { CustomDialog } from '@components/common'
import { useCart } from '@context'
import { isInStandaloneMode } from '@utils/helpers'

const HomepageCartButton = () => {
    const { cartState, open, setOpen, getItemCount } = useCart()

    if (cartState.products.length === 0) {
        return null
    }

    const onCheckoutButtonClick = () => {
        setOpen(!open)
        analyticsViewCart(cartState)
    }

    return (
        <Hidden smUp>
            {isInStandaloneMode() && <div className="pwa__gap" />}

            <CheckoutButton
                itemCount={getItemCount()}
                price={cartState.total}
                title="Cos"
                onClick={onCheckoutButtonClick}
            />

            <CustomDialog
                maxWidth="sm"
                title={!cartState.products.length ? '' : <h2>Cosul meu</h2>}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Box mt={2} />
                <div className="p05">
                    <Cart />
                </div>
            </CustomDialog>
        </Hidden>
    )
}

export default HomepageCartButton
