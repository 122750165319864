import React from 'react'

import { Address } from '@interfaces/address'
import { handleNavigate } from '@utils/helpers'
import CheckoutAddressItem from '../delivery/CheckoutAddressItem'
import NeedsUstensils from '../NeedsUstensils'
import PickupPackaging from './PickupPackaging'

interface Props {
    restaurantAddress: Address
}

const PickupOptions: React.FC<Props> = ({ restaurantAddress }) => {
    const { coords } = restaurantAddress
    return (
        <>
            <CheckoutAddressItem
                user={null}
                restaurantAddress={restaurantAddress}
                onClick={() => handleNavigate(coords.lat, coords.lng)}
            />

            <NeedsUstensils divider />
            <PickupPackaging />
            {/* <DeliverySpecialInstructions /> */}
        </>
    )
}

export default PickupOptions
