import { ButtonGroup, DialogActions, Typography } from '@material-ui/core'

import { SPButton } from '@components/common'

interface Props {
    title?: string | React.ReactNode
    loading?: boolean
    disabled?: boolean
    size?: 'small' | 'medium' | 'large'
    fullWidth?: boolean
    minWidth?: number
    positiveButtonText?: string
    negativeButtonText?: string
    onPositiveButtonClick?: () => void
    onNegativeButtonClick: () => void
}
export const ConfirmButtonGroup: React.FC<Props> = ({
    title,
    size = 'medium',
    fullWidth = true,
    minWidth = 0,
    positiveButtonText = 'Da',
    negativeButtonText = 'Anuleaza',
    loading = false,
    disabled = false,
    onPositiveButtonClick,
    onNegativeButtonClick,
}) => {
    return (
        <>
            <div className="p1">
                {title &&
                    (typeof title === 'string' ? (
                        <Typography
                            color="textSecondary"
                            style={{ margin: '-16px 0 0 0' }}
                        >
                            {title}
                        </Typography>
                    ) : (
                        title
                    ))}
            </div>

            <DialogActions>
                <ButtonGroup fullWidth={fullWidth}>
                    <SPButton
                        text={negativeButtonText}
                        onClick={onNegativeButtonClick}
                        disabled={loading}
                        fullWidth={fullWidth}
                        size={size}
                        square={fullWidth}
                        minWidth={minWidth}
                        style={{ color: 'red' }}
                    />

                    <SPButton
                        size={size}
                        text={positiveButtonText}
                        variant="contained"
                        color="primary"
                        loading={loading}
                        disabled={disabled}
                        onClick={onPositiveButtonClick && onPositiveButtonClick}
                        fullWidth={fullWidth}
                        minWidth={minWidth}
                        square={fullWidth}
                        type="submit"
                    />
                </ButtonGroup>
            </DialogActions>
        </>
    )
}

export default ConfirmButtonGroup
