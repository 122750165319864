import { FC } from 'react'
import {
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core'
import { Directions, Edit, LocationOn } from '@material-ui/icons'

import { Address } from '@interfaces/address'
import { User } from '@interfaces/user'

interface Props {
    user?: User | null
    restaurantAddress?: Address | null
    onClick?: () => void
}

const CheckoutAddressItem: FC<Props> = ({ user, restaurantAddress, onClick }) => (
    <ListItem divider button onClick={onClick}>
        <ListItemIcon>
            <LocationOn color="primary" />
        </ListItemIcon>

        {restaurantAddress ? (
            <ListItemText
                primary="Adresa restaurant"
                secondary={restaurantAddress.label}
            />
        ) : user?.addresses?.length === 0 ? (
            <Typography className="full-width" color="error">
                Unde sa livram mancarea?
            </Typography>
        ) : (
            <ListItemText
                primary="Adresa livrare"
                secondary={user?.addresses?.find((a) => a.primary)?.mainText ?? ''}
            />
        )}

        <IconButton aria-label="address">
            {restaurantAddress ? <Directions /> : <Edit />}
        </IconButton>
    </ListItem>
)

export default CheckoutAddressItem
