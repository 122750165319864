import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Container, Typography } from '@material-ui/core'

interface Props {
    title?: string
    subtitle?: string
    redirect?: boolean
}
const FullscreenError: React.FC<Props> = ({
    title = 'Ooops, a aparut o eroare.',
    subtitle = 'Vei fi redirectat catre pagina principala in cateva secunde.',
    redirect = true,
}) => {
    const router = useRouter()

    useEffect(() => {
        if (redirect) {
            setTimeout(async () => {
                await router.push('/')
            }, 5000)
        }
    }, [router])

    return (
        <Container>
            <div className="error-page">
                <h1>{title}</h1>
                <Typography>{subtitle}</Typography>
            </div>
        </Container>
    )
}

export default FullscreenError
