import { FC } from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ArrowForwardIosOutlined } from '@material-ui/icons'

interface Props {
    title: string | JSX.Element
    subtitle?: string
    onClick?: () => void
    startIcon?: React.ReactNode
    arrowRightIcon?: boolean
    endIcon?: React.ReactNode
    listItemTextStyle?: React.CSSProperties
    divider?: boolean
}
export const SPListItem: FC<Props> = ({
    title,
    subtitle,
    onClick,
    startIcon,
    endIcon,
    arrowRightIcon,
    listItemTextStyle,
    divider = false,
}) => {
    return (
        <ListItem button divider={divider} onClick={onClick} className="separator">
            {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}

            <ListItemText
                primary={title}
                secondary={subtitle}
                style={listItemTextStyle}
            />

            {arrowRightIcon && (
                <ArrowForwardIosOutlined
                    color="action"
                    fontSize="small"
                    style={{ height: 16, marginRight: 4 }}
                />
            )}

            {endIcon && endIcon}
        </ListItem>
    )
}

export default SPListItem
