import React from 'react'
import { ListItemText } from '@material-ui/core'

import { CartItemQuantitySelector, CartProductChoices } from '@components/cart/index'
import { ProgressBar } from '@components/common'
import { useCart } from '@context'
import { PriceType } from '@interfaces/order'
import { Product } from '@interfaces/restaurant'
import { formatPrice } from '@utils/helpers'
import classes from './Cart.module.scss'

interface Props {
    product: Product

    priceType?: PriceType
    showSubitems?: boolean
    showToppingName?: boolean
    showPrice?: boolean
    divider?: boolean
    showOptionsPrice?: boolean
    editableQuantity?: boolean
}

const CartProduct: React.FC<Props> = ({
    product,
    showSubitems = false,
    divider = true,
    showToppingName = false,
    showPrice = true,
    showOptionsPrice = false,
    editableQuantity = true,
    priceType = 'totalPrice',
}) => {
    const { getTotalPrice } = useCart()
    const variation = product.product_variations[0]

    if (!variation) {
        return <ProgressBar />
    }

    const variationName = variation.name ? `- ${variation.name}` : ''

    return (
        <div className={divider ? classes.container__divider : classes.container}>
            <div className={classes.item}>
                <CartItemQuantitySelector
                    product={product}
                    editable={editableQuantity}
                />

                <ListItemText
                    className={classes.cart__item}
                    primary={product.name + variationName}
                    secondary={
                        showPrice
                            ? formatPrice(getTotalPrice(product, priceType))
                            : ''
                    }
                />
            </div>

            <CartProductChoices
                variation={variation}
                visible={showSubitems}
                showToppingName={showToppingName}
                showOptionsPrice={showOptionsPrice}
            />

            {variation.special_requests && (
                <ListItemText
                    primary="Instructiuni speciale"
                    secondary={variation.special_requests}
                />
            )}
        </div>
    )
}

export default CartProduct
