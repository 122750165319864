import React from 'react'
import { Container, Grid } from '@material-ui/core'

import { SEO } from '@components/common'
import { SPCardSkeleton } from '@components/skeleton'

const UserSkeleton = () => {
    return (
        <Container className="container pv1">
            <SEO title="Se incarca" />
            <Grid container spacing={2}>
                <SPCardSkeleton height={110} md={6} />
                <SPCardSkeleton height={110} md={6} />
            </Grid>
            <br />
            <Grid container spacing={2}>
                <SPCardSkeleton height={110} md={6} />
            </Grid>
        </Container>
    )
}

export default UserSkeleton
