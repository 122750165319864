import React, { useState } from 'react'
import { IconButton, ListItem, ListItemText } from '@material-ui/core'
import { CreditCard, Delete, Favorite, FavoriteBorder } from '@material-ui/icons'
import type { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js'

import { ProgressBar } from '@components/common'

interface Props {
    favoriteCardId: string | null
    paymentMethod: StripePaymentMethod
    handleSetFavorite: (id: string) => Promise<void>
    handleDelete: (id: string) => Promise<void>
}

const UserPaymentMethodItem: React.FC<Props> = ({
    favoriteCardId,
    paymentMethod,
    handleDelete,
    handleSetFavorite,
}) => {
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [loadingFavorite, setLoadingFavorite] = useState(false)

    const onFavoriteClick = async () => {
        setLoadingFavorite(true)
        await handleSetFavorite(paymentMethod.id)
        setLoadingFavorite(false)
    }
    const onDeleteClick = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.stopPropagation()
        setLoadingDelete(true)
        await handleDelete(paymentMethod.id)
        setLoadingDelete(false)
    }
    return (
        <ListItem
            button
            disabled={loadingDelete || loadingFavorite}
            onClick={onFavoriteClick}
            className="separator"
        >
            <div className="p05">
                <CreditCard color="inherit" fontSize="small" />
            </div>

            <ListItemText
                className="capitalize"
                primary={`${paymentMethod.card.brand} •••• ${paymentMethod.card.last4}`}
                secondary={`${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
            />

            <IconButton
                aria-label="delete"
                disabled={loadingDelete}
                onClick={onDeleteClick}
            >
                {loadingDelete ? (
                    <ProgressBar color="primary" size="small" />
                ) : (
                    <Delete />
                )}
            </IconButton>

            <IconButton
                aria-label="set-favorite"
                disabled={loadingFavorite}
                onClick={onFavoriteClick}
            >
                {loadingFavorite ? (
                    <ProgressBar color="primary" size="small" />
                ) : favoriteCardId === paymentMethod.id ? (
                    <Favorite color="error" />
                ) : (
                    <FavoriteBorder color="action" />
                )}
            </IconButton>
        </ListItem>
    )
}

export default UserPaymentMethodItem
