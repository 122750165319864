import { FC } from 'react'
import { IconButton } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

interface Props {
    isExpanded: boolean
    toggle: (arg1: boolean) => void
    border?: boolean
    size?: 'medium' | 'small'
}

export const ExpandButton: FC<Props> = ({
    isExpanded,
    toggle,
    border = true,
    size = 'small',
}) => (
    <IconButton
        aria-label="expand"
        size={size}
        style={{
            border: border ? '1px solid lightgray' : null,
            borderRadius: 25,
        }}
        onClick={() => toggle(!isExpanded)}
    >
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
    </IconButton>
)

export default ExpandButton
