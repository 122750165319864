import React from 'react'
import { ListItemText } from '@material-ui/core'

import SPButton from '@components/common/SPButton'
import ProductImage from '@components/product/product/ProductImage'
import { Product } from '@interfaces/restaurant'
import { formatPrice } from '@utils/helpers'

interface Props {
    products: Product[]
    onAddToCart: (product: Product) => void
}
const FrequentlyBoughtTogether: React.FC<Props> = ({ products }) => {
    return (
        <div
            style={{
                backgroundColor: 'whitesmoke',
                padding: '0.5em',
                margin: '0 -1em',
            }}
        >
            <h2>Adauga si:</h2>
            {products.map((p) => (
                <div
                    key={p.id}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        padding: '0.5em',
                        marginBottom: '1em',
                    }}
                >
                    <ProductImage product={p} size="x-small" />
                    <div className="ml05" />
                    <ListItemText
                        primary={p.name}
                        secondary={formatPrice(p.product_variations[0].price)}
                    />

                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-start',
                        }}
                    >
                        <SPButton
                            text="Adauga in cos"
                            size="small"
                            onClick={() => {}}
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default FrequentlyBoughtTogether
