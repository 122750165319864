import { Container, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import SEO from '@components/common/SEO'
import SPCardSkeleton from './SPCardSkeleton'

const SkeletonOrderItem = () => (
    <Grid item xs={12}>
        <Skeleton variant="text" width={250} height={44} />
        <div className="mt1" />
        <SPCardSkeleton height={180} md={12} />
    </Grid>
)

const OrdersSkeleton = () => (
    <Container className="container pv1" maxWidth="sm">
        <SEO title="Se incarca" />

        <Grid container spacing={2}>
            <SkeletonOrderItem />
            <SkeletonOrderItem />
        </Grid>
    </Container>
)

export default OrdersSkeleton
