import { FC } from 'react'
import {
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
} from '@material-ui/core'

interface Props {
    title: string
    icon: React.ReactNode
    disabled?: boolean
    checked: boolean
    divider?: boolean
    onClick: (args?: any) => void
}

const SPRadioButton: FC<Props> = ({
    title,
    icon,
    disabled = false,
    checked = false,
    onClick,
    divider = true,
}) => {
    return (
        <ListItem
            button
            onClick={onClick}
            disabled={disabled}
            className={divider ? 'separator' : null}
        >
            <ListItemIcon aria-label="radio" style={{ marginLeft: -8 }}>
                <Radio checked={checked} />
            </ListItemIcon>

            <ListItemText primary={title} />
            <IconButton aria-label={title}>{icon}</IconButton>
        </ListItem>
    )
}

export default SPRadioButton
