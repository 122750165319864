import { useEffect, useState } from 'react'
import { QueryDocumentSnapshot } from 'firebase/firestore'

import { fetchUserOrderHistory } from '@api/admin'
import { Order } from '@interfaces/order'

const useOrderHistory = (uid?: string, limit = 10) => {
    const [orders, setOrders] = useState<Order[]>([])
    const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot>()

    const [loading, setLoading] = useState(true)
    const [paginating, setPaginating] = useState(false)

    const [loadedAll, setLoadedAll] = useState(false)

    const fetchMore = async () => {
        setPaginating(true)

        try {
            const [newOrders, last] = await fetchUserOrderHistory(
                uid,
                lastDoc,
                limit,
            )

            if (!last) {
                setLoadedAll(true)
            } else {
                setLastDoc(last)
                setOrders([...orders, ...newOrders])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
            setPaginating(false)
        }
    }

    useEffect(() => {
        fetchMore()
    }, [])

    return {
        loading,
        paginating,
        orders,
        loadedAll,
        fetchMore,
    }
}

export default useOrderHistory
