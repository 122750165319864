import { useState } from 'react'
import Link from 'next/link'
import {
    Avatar,
    Chip,
    ClickAwayListener,
    makeStyles,
    MenuItem,
    Tooltip,
} from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'

import { LogoutDialog } from '@components/header'
import { useUser } from '@context'

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 28,
        width: 28,
    },
    arrow: {
        '&:before': {
            border: '1px solid #E6E8ED',
        },
        color: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #E6E8ED',
        color: '#4A4A4A',
    },
}))

const HeaderUserIcon = () => {
    const {
        state: { loading, user },
        dispatch,
    } = useUser()

    const classes = useStyles()
    const [optionsMenuOpen, setOptionsMenuOpen] = useState(false)
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)

    const toggleOptionsMenu = () => setOptionsMenuOpen(!optionsMenuOpen)

    const toggleLogoutDialog = () => {
        setLogoutDialogOpen(!logoutDialogOpen)
        setOptionsMenuOpen(false)
    }

    if (loading) {
        return (
            <div>
                <Skeleton
                    variant="rect"
                    height={32}
                    width={130}
                    style={{ borderRadius: 50 }}
                />
            </div>
        )
    }

    const onAuthClick = () => {
        dispatch({
            type: 'AUTH_ERROR',
            payload: { authError: null },
        })
    }

    if (!user) {
        return (
            <Link href="/user">
                <a onClick={onAuthClick}>
                    <Chip label="Autentificare" clickable />
                </a>
            </Link>
        )
    }

    return (
        <>
            <ClickAwayListener onClickAway={() => setOptionsMenuOpen(false)}>
                <div>
                    <Tooltip
                        classes={{
                            arrow: classes.arrow,
                            tooltip: classes.tooltip,
                        }}
                        interactive
                        arrow
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={optionsMenuOpen}
                        onClose={() => setOptionsMenuOpen(false)}
                        title={
                            <>
                                <Link href="/user" passHref>
                                    <a>
                                        <MenuItem
                                            onClick={() => setOptionsMenuOpen(false)}
                                        >
                                            Profil
                                        </MenuItem>
                                    </a>
                                </Link>

                                <Link href="/orders" passHref>
                                    <a>
                                        <MenuItem
                                            divider
                                            onClick={() => setOptionsMenuOpen(false)}
                                        >
                                            Comenzile mele
                                        </MenuItem>
                                    </a>
                                </Link>

                                <MenuItem
                                    onClick={toggleLogoutDialog}
                                    style={{ color: 'black' }}
                                >
                                    Deconectare
                                </MenuItem>
                            </>
                        }
                    >
                        <Chip
                            variant="outlined"
                            label={user?.firstName?.slice(0, 10) ?? 'Contul meu'}
                            onClick={toggleOptionsMenu}
                            avatar={
                                !user?.photoURL && user?.firstName ? (
                                    <Avatar>{user.firstName.charAt(0) ?? ''}</Avatar>
                                ) : (
                                    <Avatar
                                        alt={user?.firstName ?? 'Contul meu'}
                                        src={user?.photoURL ?? ''}
                                        className={classes.avatar}
                                    />
                                )
                            }
                            deleteIcon={
                                optionsMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />
                            }
                            onDelete={toggleOptionsMenu}
                            style={{ padding: 0 }}
                        />
                    </Tooltip>
                </div>
            </ClickAwayListener>

            {logoutDialogOpen && (
                <LogoutDialog open={logoutDialogOpen} onClose={toggleLogoutDialog} />
            )}
        </>
    )
}

export default HeaderUserIcon
