import { Menu, Option, Product, Restaurant, Topping } from '@interfaces/restaurant'

export const initialAdminMenuState: Restaurant = {
    menus: [],
    toppings: [],
}

export type ProductSortIdPayload = {
    menuId: string
    destinationIdx: number
    sourceIdx: number
}

export type OptionSortIdPayload = {
    toppingId: string
    destinationIdx: number
    sourceIdx: number
}

type RestaurantReducerAction =
    | { type: 'POPULATE'; payload: { restaurant: Restaurant } }
    | { type: 'UPDATE_PRODUCT'; payload: { menuId: string; product: Product } }
    | { type: 'UPDATE_PRODUCT_SORT_ID'; payload: ProductSortIdPayload }
    | { type: 'UPDATE_OPTION_SORT_ID'; payload: OptionSortIdPayload }
    | { type: 'DELETE_PRODUCT'; payload: { menuId: string; productId: string } }
    | {
          type: 'EDIT_MENU_SORT_ID'
          payload: { destinationIdx: number; sourceIdx: number }
      }
    | {
          type: 'EDIT_TOPPING_CATEGORY_SORT_ID'
          payload: { destinationIdx: number; sourceIdx: number }
      }
    | { type: 'ADD_CATEGORY'; payload: { name: string } }
    | { type: 'EDIT_CATEGORY_NAME'; payload: { id: string; name: string } }
    | { type: 'DELETE_CATEGORY'; payload: { id: string } }
    | { type: 'EDIT_TOPPING'; payload: { topping: Topping } }
    | { type: 'DELETE_TOPPING'; payload: { id: string } }

// PRODUT
const updateProduct = (state: Restaurant, menuId: string, product: Product) => {
    const newState = { ...state }

    const menu = newState.menus.find((m) => m.id === menuId)
    const productIndex = menu.products.findIndex((p) => p.id === product.id)

    if (productIndex === -1) {
        menu.products.push(product)
    } else {
        menu.products[productIndex] = product
    }

    return newState
}

const deleteProduct = (state: Restaurant, menuId: string, productId: string) => {
    const newState = { ...state }

    const selectedMenu = newState.menus.find((menu) => menu.id === menuId)
    const selectedMenuIndex = newState.menus.findIndex((m) => m.id === menuId)
    newState.menus[selectedMenuIndex].products = selectedMenu.products.filter(
        (p) => p.id !== `${productId}`,
    )

    return newState
}

// CATEGORY
const addCategory = (state: Restaurant, name: string) => {
    const newState = { ...state }

    const newMenu: Menu = {
        id: '',
        name: name,
        products: [],
        sortId: 999,
    }

    newState.menus.push(newMenu)
    return newState
}

const editCategoryName = (state: Restaurant, id: string, name: string) => {
    const newState = { ...state }
    const menu = state.menus.find((menu) => menu.id === id)
    menu.name = name

    return newState
}

const deleteCategory = (state: Restaurant, id: string) => {
    const newState = { ...state }
    newState.menus = newState.menus.filter((m) => m.id !== id)
    return newState
}

// topping

const editTopping = (state: Restaurant, topping: Topping): Restaurant => {
    const newState = { ...state }
    const index = newState.toppings.findIndex((t) => t.id === topping.id)

    if (index === -1) {
        newState.toppings.push(topping)
    } else {
        newState.toppings[index] = topping
    }

    return newState
}

const deleteTopping = (state: Restaurant, id: string): Restaurant => {
    const newState = { ...state }
    newState.toppings = newState.toppings.filter((t) => t.id !== id)
    return newState
}

const editMenuSortId = (
    state: Restaurant,
    destinationIdx: number,
    sourceIdx: number,
): Restaurant => {
    const newState = { ...state }
    const result = newState.menus

    const [removed] = result.splice(sourceIdx, 1)
    result.splice(destinationIdx, 0, removed)

    result.forEach((menu, index) => {
        menu.sortId = index
    })

    newState.menus = result
    return newState
}

const editToppingCategorySortId = (
    state: Restaurant,
    destinationIdx: number,
    sourceIdx: number,
): Restaurant => {
    const newState = { ...state }
    const result = newState.toppings

    const [removed] = result.splice(sourceIdx, 1)
    result.splice(destinationIdx, 0, removed)

    result.forEach((topping, index) => {
        topping.sortId = index
    })

    newState.toppings = result
    return newState
}

const updateProductSortId = (
    state: Restaurant,
    payload: ProductSortIdPayload,
): Restaurant => {
    const newState = { ...state }
    const { menuId, destinationIdx, sourceIdx } = payload

    const menuCategoryIndex = newState.menus.findIndex((menu) => menu.id === menuId)

    const result: Product[] = newState.menus[menuCategoryIndex].products

    const [removed] = result.splice(sourceIdx, 1)
    result.splice(destinationIdx, 0, removed)

    result.forEach((product, index) => {
        product.sortId = index
    })
    newState.menus[menuCategoryIndex].products = result

    return newState
}

const updateOptionSortId = (
    state: Restaurant,
    payload: OptionSortIdPayload,
): Restaurant => {
    const newState = { ...state }
    const { toppingId, destinationIdx, sourceIdx } = payload

    const toppingCategoryIndex = newState.toppings.findIndex(
        (t) => t.id === toppingId,
    )
    const result: Option[] = newState.toppings[toppingCategoryIndex].options

    const [removed] = result.splice(sourceIdx, 1)
    result.splice(destinationIdx, 0, removed)

    result.forEach((option, index) => {
        option.sortId = index
    })

    newState.toppings[toppingCategoryIndex].options = result
    return newState
}

export const adminMenuReducer = (
    state: Restaurant,
    action: RestaurantReducerAction,
): Restaurant => {
    switch (action.type) {
        case 'POPULATE':
            return action.payload.restaurant

        case 'ADD_CATEGORY':
            return addCategory(state, action.payload.name)

        case 'EDIT_MENU_SORT_ID':
            return editMenuSortId(
                state,
                action.payload.destinationIdx,
                action.payload.sourceIdx,
            )

        case 'EDIT_TOPPING_CATEGORY_SORT_ID':
            return editToppingCategorySortId(
                state,
                action.payload.destinationIdx,
                action.payload.sourceIdx,
            )

        case 'DELETE_CATEGORY':
            return deleteCategory(state, action.payload.id)
        case 'EDIT_CATEGORY_NAME':
            return editCategoryName(state, action.payload.id, action.payload.name)

        case 'UPDATE_PRODUCT':
            return updateProduct(
                state,
                action.payload.menuId,
                action.payload.product,
            )
        case 'UPDATE_PRODUCT_SORT_ID':
            return updateProductSortId(state, action.payload)

        case 'UPDATE_OPTION_SORT_ID':
            return updateOptionSortId(state, action.payload)

        case 'DELETE_PRODUCT':
            return deleteProduct(
                state,
                action.payload.menuId,
                action.payload.productId,
            )

        case 'EDIT_TOPPING':
            return editTopping(state, action.payload.topping)
        case 'DELETE_TOPPING':
            return deleteTopping(state, action.payload.id)

        default:
            return state
    }
}
