import React, { useEffect, useState } from 'react'

import { SPButton, SPCard } from '@components/common'
import { SPCardSkeleton } from '@components/skeleton'
import { UserAddPaymentMethodDialog, UserPaymentMethodItem } from '@components/user'
import { UserProps } from '@context'

interface Props {
    userProps: UserProps
}

const UserPaymentMethods: React.FC<Props> = ({ userProps }) => {
    const [addCardDialogOpen, setAddDialogOpen] = useState(false)
    const toggleAddCardDialog = () => setAddDialogOpen(!addCardDialogOpen)

    const {
        state: { cardState, user },
        getSavedCards,
        setFavoriteCard,
        deleteCard,
    } = userProps

    useEffect(() => {
        getSavedCards()
    }, [])

    if (cardState.loading) {
        return <SPCardSkeleton />
    }

    return (
        <>
            <SPCard
                style={{ padding: 0, paddingBottom: 8 }}
                title={<h2 style={{ marginLeft: 16 }}>Cardurile mele</h2>}
                action={
                    <div className="p1">
                        <SPButton
                            text="Adauga"
                            variant="contained"
                            size="small"
                            color="default"
                            onClick={toggleAddCardDialog}
                        />
                    </div>
                }
            >
                {cardState.savedCards?.map((c) => (
                    <UserPaymentMethodItem
                        key={c.id}
                        favoriteCardId={user.favoriteCardId}
                        paymentMethod={c}
                        handleSetFavorite={() => setFavoriteCard(c.id)}
                        handleDelete={() => deleteCard(c.id)}
                    />
                ))}
            </SPCard>

            {addCardDialogOpen && (
                <UserAddPaymentMethodDialog
                    open={addCardDialogOpen}
                    onClose={toggleAddCardDialog}
                />
            )}
        </>
    )
}

export default UserPaymentMethods
