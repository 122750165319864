import React from 'react'
import { FormControl, NativeSelect, Typography } from '@material-ui/core'

import { analyticsRemoveFromCart } from '@api/analytics'
import { useCart } from '@context'
import { Product } from '@interfaces/restaurant'
import classes from './Cart.module.scss'

interface Props {
    product: Product
    editable: boolean
}

const CartItemQuantitySelector: React.FC<Props> = ({ product, editable = true }) => {
    const cart = useCart()
    const quantityList = Array.from(Array(101), (_, x) => x)

    const onQuantityChange =
        (product: Product) => (e: React.ChangeEvent<HTMLSelectElement>) => {
            e.preventDefault()

            const quantity = +e.target.value
            cart.changeQty(product, quantity)

            if (quantity === 0) {
                analyticsRemoveFromCart(product)
            }
        }

    return editable ? (
        <FormControl>
            <NativeSelect
                className={classes.quantity__selector__enabled}
                value={cart.getQty(product)}
                onChange={onQuantityChange(product)}
            >
                {quantityList.map((q) => (
                    <option value={q} key={q}>
                        {q === 0 ? 'Sterge' : q}
                    </option>
                ))}
            </NativeSelect>
        </FormControl>
    ) : (
        <Typography className={classes.quantity__selector__disabled}>
            {cart.getQty(product)} x
        </Typography>
    )
}

export default CartItemQuantitySelector
