import { getIdTokenResult, User as FirebaseUser } from 'firebase/auth'
import {
    arrayUnion,
    collection,
    doc,
    getDoc,
    getDocs,
    orderBy,
    query,
    setDoc,
    where,
} from 'firebase/firestore'

import { Info } from '@interfaces/info'
import { Order } from '@interfaces/order'
import { Menu, Product, Restaurant, Topping } from '@interfaces/restaurant'
import { Claims, User } from '@interfaces/user'
import { clientDb } from '@utils/clientFirebase'
import { CONFIG } from '@utils/config'

export const fetchUser = async (uid: string): Promise<User> => {
    try {
        const userSnapshot = await getDoc(doc(clientDb, 'users', uid))
        return userSnapshot.data() as User
    } catch (error) {
        throw error
    }
}

export const fetchToppings = async (): Promise<Topping[]> => {
    const snapshot = await getDocs(
        collection(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/toppings`),
    )
    return snapshot.docs.map((doc) => doc.data() as Topping)
}

export const fetchMenus = async (): Promise<Menu[]> => {
    const snapshot = await getDocs(
        query(
            collection(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/menus`),
            orderBy('sortId', 'asc'),
        ),
    )

    return snapshot.docs.map((doc) => doc.data() as Menu)
}

export const fetchProduct = async (id: string): Promise<Product | null> => {
    try {
        const snapshot = await getDoc(
            doc(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/products`, id),
        )

        if (!snapshot.exists) {
            return null
        }

        return snapshot.data() as Product
    } catch (error) {
        console.error('fetchProduct error', error)
        throw error
    }
}

export const fetchProductIds = async (): Promise<string[]> => {
    try {
        const snapshot = await getDoc(
            doc(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/product_ids`, 'ids'),
        )

        if (!snapshot.exists()) {
            const menus = await fetchMenus()
            const products = menus.map((m) => m.products).flat()
            const productIds = products.map((p) => p.id)
            await updateProductIdsCollection(productIds)

            return productIds
        } else {
            return snapshot.data().ids as string[]
        }
    } catch (error) {
        console.error('fetchProductIds error', error)
        throw error
    }
}

export const updateProductIdsCollection = async (
    productIds: string[],
): Promise<void> => {
    await setDoc(
        doc(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/product_ids`, 'ids'),
        { ids: productIds },
        { merge: true },
    )
}

export const fetchMenuCategory = async (menuId: string): Promise<Menu | null> => {
    try {
        const snapshot = await getDocs(
            query(
                collection(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/menus`),
                where('id', '==', menuId),
            ),
        )

        if (snapshot.docs.length === 0) {
            throw new Error('Menu not found')
        }

        const menus = snapshot.docs.map((doc) => doc.data()) as Menu[]

        return menus[0]
    } catch (error) {
        throw error
    }
}

export const fetchClientRestaurant = async (): Promise<Restaurant> => {
    try {
        const [menus, toppings] = await Promise.all([fetchMenus(), fetchToppings()])
        return { menus, toppings }
    } catch (error) {
        console.error(error)
        return { menus: [], toppings: {} } as Restaurant
    }
}

export const fetchInfoClient = async (): Promise<Info> => {
    try {
        const snap = await getDoc(doc(clientDb, 'restaurants', CONFIG.RESTAURANT_ID))
        return snap.data() as Info
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const fetchOrder = async (orderId: string): Promise<Order> => {
    try {
        const snapshot = await getDoc(
            doc(clientDb, `restaurants/${CONFIG.RESTAURANT_ID}/orders/${orderId}`),
        )
        const order = snapshot.data() as Order
        return order
    } catch (error) {
        throw error
    }
}

export const updateUserRestaurantIds = async (user: User) => {
    try {
        setDoc(
            doc(clientDb, 'users', user.uid),
            { restaurant_ids: arrayUnion(CONFIG.RESTAURANT_ID) },
            { merge: true },
        )
    } catch (error) {
        console.error(error)
    }
}

export const fetchClaims = async (firebaseUser: FirebaseUser): Promise<Claims> => {
    try {
        const tokenResult = await getIdTokenResult(firebaseUser, true)
        return tokenResult.claims.claims as Claims
    } catch (error) {
        console.error('fetchClaims', error)
        throw error
    }
}

export const fetchSaveUsersFavoriteCardId = async (uid: string, cardId: string) => {
    try {
        await setDoc(
            doc(clientDb, 'users', uid),
            { favoriteCardId: cardId },
            { merge: true },
        )
    } catch (error) {
        console.error(error)
        throw error
    }
}
