import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { analyticsBeginCheckout, analyticsViewCart } from '@api/analytics'
import { CartProduct, EmptyCart } from '@components/cart'
import { CheckoutButton } from '@components/checkout'
import { ProgressBar } from '@components/common'
import { useCart } from '@context'

const Cart = () => {
    const router = useRouter()
    const { cartState, open, setOpen, getItemCount, loading } = useCart()
    const { products, total } = cartState
    const [loadingNextStepBtn, setLoadingNextStepBtn] = useState(false)

    useEffect(() => {
        router.prefetch('/checkout')
        analyticsViewCart(cartState)
    }, [])

    if (products.length === 0) {
        return <EmptyCart />
    }

    const onCheckoutButtonClick = async () => {
        setLoadingNextStepBtn(true)
        await router.push('/checkout')
        setOpen(!open)
        setLoadingNextStepBtn(false)
        analyticsBeginCheckout(cartState)
    }

    if (loading) {
        return <ProgressBar />
    }

    return (
        <>
            {products.map((p, pIdx) => (
                <CartProduct key={p.id + pIdx} product={p} showSubitems />
            ))}

            <div className="cart__checkout__gap" />

            <CheckoutButton
                itemCount={getItemCount()}
                price={total}
                title="Pasul urmator"
                onClick={onCheckoutButtonClick}
                loading={loadingNextStepBtn}
            />
        </>
    )
}

export default Cart
